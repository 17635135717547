<div id="home-slider" class="carousel slide" data-ride="carousel" style='margin-bottom: 20px;'>
  <ol class="carousel-indicators">
    <li *ngFor='let item of items;index as i ' data-target="Noticias-s" (click)="cambiarSlider(i)"
      [ngClass]='(positionItem == i) ? "active-link" : ""'></li>
  </ol>
  <div class="carousel-inner">
    <!-- [@enterState]='item.state'-->
    <div class="carousel-item active " *ngFor='let item of items' [id]='item.id'>
      <a [href]="item.link">
        <div [ngClass]='(item.state== "actived")?"actived" : "inactive"'>
          <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="item.foto">
          <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 mob" [src]="item.fotoMovil">
          <div class="texto-s container" [ngClass]='(item.state== "actived")?"actived" : "inactive"'>
            <div class='container-header w-100'>
              <div class="carousel-cell-header ">
                <div class='content-header  box-text' *ngIf='item.title || item.button '>
                  <h1 class="bold blanco" style='margin-bottom:0px;'>{{item.title }}</h1>
                  <a *ngIf='item.button' style='border-style:none;' [href]="item.link" class="btn btn-primary"
                    [ngStyle]="{'background': item.button}">SABER MÁS</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>


    </div>
  </div>
</div>