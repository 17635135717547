<div [style.padding]="'30px 0px'">
  <div #sliderContainer class="slider-container" [style.margin-left]="(isDesktop) ? marginDesk :marginMob"
    [style.margin-right]="(isDesktop) ? marginDesk :marginMob">

    <!--Desk-->
    <div *ngIf="isDesktop" class="slider" [style.transform]="'translateX(' + (-100 * currentIndex) + '%)'">
      <div class="slider-images">
        <ng-container *ngFor="let image of images">
          <img [id]="image.id" [routerLink]="'/' +language + '/' + image?.redirect" *ngIf="image?.path" [src]=" apiUrl + image.path"
            alt="Image slider" class="slider-image" />
        </ng-container>
      </div>
    </div>

    <!--Mob-->
    <div *ngIf="!isDesktop" class="slider" [style.transform]="'translateX(' + (-100 * currentIndex) + 'vw)'">
      <div class="slider-images">
        <ng-container *ngFor="let image of images">
          <img  [id]="image.id" [routerLink]="'/' +language + '/' + image?.redirect" *ngIf="image?.pathMobile"
            [src]="apiUrl + image.pathMobile" alt="Image slider" class="slider-image img-mob" />
        </ng-container>
      </div>
    </div>

    <!-- Bullets-->
    <div *ngIf="bullets.length > 1" class="bullets">
      <ng-container *ngFor="let b of bullets; index as i">
        <span class="bullet" [class.active]="bulletIndex === (i)" (click)="setPosition(i)"></span>
      </ng-container>
    </div>
  </div>
</div>