import { Component, OnInit } from '@angular/core';
declare var hbspt: any;

@Component({
  selector: 'app-form-hubspot',
  templateUrl: './form-hubspot.component.html',
  styleUrls: ['./form-hubspot.component.scss']
})
export class FormHubspotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // Código del formulario de HubSpot
    hbspt.forms.create({
      region: "na1",
      portalId: "23647426",
      formId: "d745d73e-7630-47a2-95cf-d0c3e6f46132",
      target: '#hubspot-form-modal' 
    });
    
  }
  
}
