import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @ViewChild('animatedSection') animatedSection!: ElementRef<HTMLDivElement>;
  isInView = false;
  @Input() data: any[] = []
  @Input() ingreso: boolean = true
  dataTres: any[] = []
  noDestacadas: any[] = []
  destacadas: any[] = []
  language: any
  constructor(private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = (params['language']) ? params['language'] : 'es';
    });
  }

  ngOnInit(): void {

    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i].label
      this.dataTres.push(this.data[i])
      /* if(element ==  "UCA Internacional / EducationUSA" || element =='Escuela de Idiomas' || element == "Sistemas de Bibliotecas UCA" ){
        this.dataTres.push(this.data[i])
      } */

    }
    this.noDestacadas = this.dataTres.filter(d => d.estado && !d.destacada);
    this.destacadas = this.dataTres.filter(d => d.estado && d.destacada);


  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const element = this.animatedSection.nativeElement;
    const rect = element.getBoundingClientRect();
    const offset = 100;
    // Verifica si el elemento está dentro de la ventana de visualización
    const isVisible = rect.top < window.innerHeight - offset && rect.bottom > offset;

    // Activa la animación cuando el elemento está en vista
    if (isVisible && !this.isInView) {
      this.isInView = true;
    }

  }

  redirect(link) {
    if (link.includes('https://') || link.includes('http://')) {
      link = window.location.href = link;
    } else {
      link = "/" + this.language + '/' + link
    }
    //window.location.href = link
  }

}
