import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {
  noticia: any;
  spinner = false;
  language = "es"
  constructor(private aRouter: ActivatedRoute, private services: GeneralService, private redirectService: RedirectService) {

  }

  ngOnInit(): void {
    var id = this.aRouter.snapshot.params['id'];
    if (this.aRouter.snapshot.params['language']) {
      this.language = this.aRouter.snapshot.params['language'];
    }
    this.services.getNoticiasDetalle(id, this.aRouter.snapshot.params['type']).subscribe(data => {
      if (data.data) {
        this.noticia = data.data[0];
        if (document.getElementById('body')) {
          document.getElementById('body').innerHTML = (this.noticia.body);
          
        }
      } else {
        this.redirectService.toHome();
      }
      //console.log("VENUE", data.data[0])

    })
  }


}
