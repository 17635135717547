import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit {
  @Input() path: string;
  @Input() pathMob: string;
  @Input() title: string;
  @Input() itemCarrouselHeader: any[] = [];
  language:String;
  constructor(public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }

  ngOnInit(): void {

  }
  url(element: any) {
    let url = '';
    if (element.route) {
      if (element.externalLink) {
        url = element.route;

      } else {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if (element.externalLink) {
        url = element.systemTitle;

      } else {
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }
    }

    return url;
  }
}
