import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment.prod';
import { BuscadorCarrerasComponent } from '../../components/buscador-carreras/buscador-carreras.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/widgets/areas-de-conocimiento/areas-de-conocimiento.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/widgets/formas-de-contacto/formas-de-contacto-dos.component';
import { NuestrasSedesComponent } from 'src/app/shared/widgets/nuestras-sedes/nuestras-sedes.component';
import { PortadaComponent } from 'src/app/shared/widgets/portada/portada.component';
import { TextItemGeneralComponent } from 'src/app/shared/widgets/text-item-general/text-item-general.component';
import { SliderComponent } from 'src/app/shared/widgets/slider/slider.component';

@Component({
  selector: 'app-sedes-ingreso',
  templateUrl: './sedes-ingreso.component.html',
  styleUrls: ['./sedes-ingreso.component.scss']
})
export class SedesIngresoComponent implements OnInit {
  language = "es";
  sede = "";
  sedeFormater = "";
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }

  id = ''
  ngAfterViewInit() {
    const currentUrl = this.route.snapshot.url.join('/');
    this.id = currentUrl.replace(this.language + '/', '');
    
    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        // this.sede = res.data[0].region;
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, data1)
          });
        }
      });
    })
    this.newComponent(2, "Buscador", {})


  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private services: GeneralService, public route: ActivatedRoute) {
    //borrar
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
      this.sede = params['sede'];
      this.sedeFormater = this.sede.replace(/-/g, ' ').replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    });
    //borrar
  }

  ngOnInit(): void {
  }



  refBuscador: any;
  newComponent(index: number, type: string, info: any) {
    console.log("---" + type + "---")
    switch (type) {
      case 'HeaderSection':
        {
          let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
          let ref = (this.viewContainerRefA(index)).createComponent(factory);
          console.log(info.data[0].headerSection)
          let data = info.data[0].headerSection;
          info.data[0] = {
            cover: {
              "id": 77,
              "title": "Sede",
              "color_title": "#ffffff",
              "subtitle": data.title,
              "color_subtitle": "#ffffff",
              "check_svg": false,
              "main": null,
              "path": data.path,
              "pathMobile": data.pathMobile,
              "pathSuperpuesta": "./assets/img/banda-azul.png",
              "pathMobileSuperpuesta": "./assets/img/banda-azul-mob.png",
              "check_capa": true
            }
          }
          ref.instance.data = info.data[0];
          ref.instance.marginTitle = 40;
          ref.instance.heightDesk = "525px";
          ref.instance.heightMob = "370px";
          //ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
          //ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;
          ref.instance.layerPathMobSrc = info.data[0]?.cover?.pathMobileSuperpuesta;
          ref.instance.layerPathSrc = info.data[0]?.cover?.pathSuperpuesta;
        }
        break;
      case "PortadaSedes":
      case "PortadaAreas":
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        console.log(info.data[0]?.cover)

        ref.instance.data = info.data[0];
        ref.instance.marginTitle = 40;
        ref.instance.heightDesk = "200px";
        ref.instance.heightMob = "250px";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

      }
        break;
      case 'Buscador': {
        let refBuscador;

        let factory2 = this.componentFactoryResolver.resolveComponentFactory(BuscadorCarrerasComponent);
        refBuscador = (this.viewContainerRefA(index)).createComponent(factory2);
        info.data= {
          "title": null,
          "color_title": "#ffffff",
          "subtitle": "ENCONTR\u00c1 TU CARRERA EN NUESTRA SEDE ",
          "color_subtitle": "#ffffff",
          "background_color": "#003567",
          
        }
        this.refBuscador = refBuscador;
        this.refBuscador.instance.sede = this.sede;
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = 40;
        this.refBuscador.instance.padding = "30px 35.6px 30px 35.6px";

        //borrar
        this.refBuscador.instance.showPagination = false;
        this.refBuscador.instance.sede = this.sedeFormater;

        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;
      case "AreaPosgrado": {

        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        const applyStyles = (areas: any[]) => {
          areas?.forEach(e => {
            e.backgroundColor = "#414141";
            e.colorName = "white";
          });
        };
        applyStyles(info.data[0].areas_tematicas_not_check_school);
        applyStyles(info.data[0].areas_tematicas_check_school);
        ref.instance.data = info.data[0];

      }
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data;
        ref.instance.esCurso = false

      }
        break;
      case "Headquarters": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(NuestrasSedesComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0].headquarters;
        ref.instance.sedes = info.data[0].sedes;
      }
        break
      case "ImageSlider": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(SliderComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.instance.ingreso = false;
        ref.instance.marginDesk = info.data[0].image.margin_desktop;
        ref.instance.marginMob = info.data[0].image.margin_mobile;
        ref.instance.images = info.data[0].image[0];
      }
        break
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break




    }






  }
}
