import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard } from 'src/app/shared/models/registroComunidad';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-stats-general',
  templateUrl: './custom-stats-general.component.html',
  styleUrls: ['./custom-stats-general.component.scss']
})
export class CustomStatsGeneralComponent implements OnInit {
  @Input() data:any;
  ListaNumeros:RegistroCard[]=[];
  @Input() background='#003567';
  styleTitle='azul';
    constructor(private services:GeneralService) { 
  }

  ngOnInit(): void {
    if (this.background=='#ffffff') {
      this.styleTitle = "azul";
    }
    this.data.forEach(element => {
      let registro= new RegistroCard();
      registro.titulo=element.title;
      registro.img=environment.apiUrl+element.path;
      registro.numero=element.number;
      this.ListaNumeros.push(registro);
    });
  }


}
