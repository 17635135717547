import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RegistroSlider } from 'src/app/shared/models/RegistroSlider';
@Component({
  selector: 'app-slider-general',
  templateUrl: './slider-general.component.html',
  styleUrls: ['./slider-general.component.scss'],

})
export class SliderGeneralComponent implements OnInit {
  @Input() data: any[] = []
  items: RegistroSlider[] = [];
  selected: RegistroSlider;
  positionItem = 0;
  positionItemLast = 0;
  constructor(private router: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.router.params.forEach((params: Params) => {
      let lista = this.data;
      lista.forEach(element => {
        let registro = new RegistroSlider();
        registro.title = element.title;
        registro.foto = element.path;
        registro.fotoMovil = element.pathMobile;
        registro.id = element.id;
        registro.categoria = element.tag;
        registro.button = element.buttonColor;
        registro.state = 'inactive'
        if (element.linkExternal.includes('http')) {
          registro.link = element.linkExternal;
        } else {
          registro.link = '/' +  params['language']+ element.linkExternal;
        }
        this.items.push(registro);

      });
    });
    this.cambiarSlider(0)
    setInterval(() => {
      this.positionItem++;
      if (this.positionItem == (this.items.length)) {
        this.positionItem = 0;
      }
      this.cambiarSlider(this.positionItem);
    }, 10000);
  }

  cambiarSlider(i: number) {
    this.items.forEach(f => {
      f.state = 'inactive';
    })
    this.items[i].state = 'actived'
    this.positionItem = i;
  }
}
