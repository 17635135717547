<section class="facsec2" [style.background]="background">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 [ngClass]='styleTitle' style="text-align:center;">{{title}}
                </h2>
                <carousel class='desk' [arrows]='(cards.length > cant)? true: false' [cellsToShow]='cant' loop='true'
                    style='display:flex;min-height: 306px;'>
                    <div class="carousel-cell " *ngFor="let item of  cards;index as i ;">
                        <div id="card" class="caja-item">
                            <a class='pointer' [href]="item.systemTitle">
                                <img onerror="this.src='./assets/img/default.png'" class="" [src]="item.img">
                                <div *ngIf='item.categoria' class='categoria'>{{item.categoria}}</div>
                                <div class="desc box-text">
                                    <span>
                                        {{item.titulo}}
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>

                </carousel>

                <carousel class='mob' [arrows]='(cards.length > 1)? true: false' cellsToShow='1' loop='true'
                    style='display:flex;min-height: 580px;'>
                    <div class="carousel-cell " *ngFor="let item of  cards;index as i ;">
                        <div id="card" class="caja-item">
                            <a class='pointer' [href]="item.systemTitle">
                                <img onerror="this.src='./assets/img/default.png'" class="" [src]="item.img">
                                <div *ngIf='item.categoria' class='categoria mob'>{{item.categoria}}</div>
                                <div class="desc box-text">{{item.titulo}}</div>
                            </a>
                        </div>
                    </div>

                </carousel>
            </div>
        </div>
    </div>
</section>