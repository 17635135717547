import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor( private router: Router ,private routerA: ActivatedRoute,private service: GeneralService) {
    this.routerA.params.forEach((params: Params) => {
      this.service.redirect(params['redirect']).subscribe(res=>{

        // if(res.data){
        //   document.location.href=res.data[0].redirect.link;
        // }else{
        //   document.location.href="/es/home";
        // }
        if(res.data){
          if(res.data[0].redirect.manualLink){
            if(res.data[0].redirect.link.includes("uca.edu.ar")){
              document.location.href=res.data[0].redirect.link;
            }else{
              document.location.href=res.data[0].redirect.link;
            }
          }else{
            document.location.href=res.data[0].redirect.locale+res.data[0].redirect.link;
          }
          
        }else{
          router.navigate(['404']);
        }
      })

    });
  }

  ngOnInit(): void {
  }

}
