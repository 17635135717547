<section class="subheader  doble-m">
    <img onerror="this.src='./assets/img/default.png'" class=" img-desk img-title w-100 desk img-desk" src=".">
    <img onerror="this.src='./assets/img/default.png'" class=" img-mob img-title w-100 mob img-mob" src=".">

    <!--<img onerror="this.src='./assets/img/default.png'"  class=" img-desk img-title w-100 desk img-desk" [src]="datos.path">
    <img onerror="this.src='./assets/img/default.png'"  class=" img-mob img-title w-100 mob img-mob" [src]="datos.pathMob">-->
</section>
<section class="logsec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-buscador-cursos></app-buscador-cursos>
            </div>
        </div>
    </div>
</section>

<section class="logsec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-4  ">
                <div class="card-tematicas">
                    <h3>Temáticas</h3>

                    <ul class="mematicas">
                        <li *ngFor="let item of datos.areas_tematicas">
                            <span *ngIf="item.estado"> {{item.name}}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12 text-center mt-5">
                    <span class=" subtitle azul ">Contactate con nosotros</span>
                </div>
                <div class="col-12 text-center" *ngIf="datos.whatsapp">
                    <a [href]="datos.whatsapp" Target="_blank">
                        <img class="icon-wsp pointer" src="./assets/img/whatsapp.png" alt="">
                    </a>
                </div>
                <div class="col-12 text-center col-seguinos">
                    <span class=" subtitle azul ">Seguinos en redes</span>
                </div>
                <div class="col text-center">
                    <a [href]="datos.instagram" Target="_blank" *ngIf="datos.instagram">
                        <img class="icon-redes" src="./assets/img/instagram-logo-circle.png" alt="">
                    </a>
                    <a [href]="datos.facebook" Target="_blank" *ngIf="datos.facebook">
                        <img class="icon-redes" src="./assets/img/facebook.png" alt="">
                    </a>
                </div>
            </div>

            <div class="col-lg-8 ">

                <div class="card-border d-flex flex-sm-row flex-column justify-content-between">
                    <div class="d-flex flex-column">
                        <h1 class="title">Titulo</h1>
                        <h1 class="title">Subtitulo</h1>

                        <span class="label">
                            <img class='icon' id="icon-date" src="./assets/img/time-and-calendar.png" alt="">
                            Inicio :
                            <span class="value">28 FEB</span>
                        </span>
                        <span class="extra"> Espacio para informacion extra</span>
                    </div>
                    <div class="container-btn">
                        <span  [style.color]="'#51565c'" > Valor de Curso </span>
                        <span class="costo">$60.000</span>
                        <button class="btn btn-ver">
                            Ver curso
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>