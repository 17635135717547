import { NgModule } from '@angular/core';
import { SimplePageComponent } from './pages/simple-page/simple-page.component';
import { Routes, RouterModule } from '@angular/router';
import { IngresoComponent } from './pages/ingreso/ingreso.component';
import { PosgradosComponent } from './pages/posgrados/posgrados.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { AlleventsComponent } from './pages/allevents/allevents.component';
import { FacultadesInstitutosComponent } from './components/facultades-institutos/facultades-institutos.component';
import { GeneralDosComponent } from 'src/app/shared/components/general-dos/general-dos.component';
import { PosgradosGeneralUnoComponent } from './pages/posgrados-general-uno/posgrados-general-uno.component';
import { CarrerasDeGradoComponent } from './pages/carreras-de-grado/carreras-de-grado.component';
import { PosgradosGeneralDosComponent } from './pages/posgrados-general-dos/posgrados-general-dos.component';
import { FacultadesComponent } from './pages/facultades/facultades.component';
import { SlideGenericPageComponent } from './pages/slide-generic-page/slide-generic-page.component';
import { ArancelesComponent } from '../aranceles/pages/aranceles/aranceles.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { AutoridadesComponent } from './pages/autoridades/autoridades.component';
import { NotFountComponent } from './pages/not-fount/not-fount.component';
import { BusquedaAvanzadaComponent } from '../busqueda/pages/busqueda-avanzada/busqueda-avanzada.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { CursosListadoComponent } from './pages/cursos-listado/cursos-listado.component';
import { ResultadosCursosComponent } from './components/resultados-cursos/resultados-cursos.component';
import { PreUniversidadComponent } from './pages/pre-universidad/pre-universidad.component';
import { SedesIngresoComponent } from './pages/sedes-ingreso/sedes-ingreso.component';
import { DetalleCursoComponent } from 'src/app/shared/components/detalle-curso/detalle-curso.component'; 
import { SedesPosgradosComponent } from './pages/sedes-posgrados/sedes-posgrados.component';


const routes: Routes = [
  { path: '404', component: NotFountComponent },
  { path: ':redirect', component: RedirectComponent },
  { path: 'error', redirectTo: 'es/home', canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'notFound', redirectTo: 'es/home', canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: '', redirectTo: 'es/home', canActivate: [AuthGuard], canLoad: [AuthGuard], pathMatch: 'full' },
  { path: ':language/home', component: HomeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: '', redirectTo: ':language/home', canActivate: [AuthGuard], canLoad: [AuthGuard], pathMatch: 'full' },
  { path: ':language/materias-del-curso-de-ingreso', component: PreUniversidadComponent },
  { path: ':language/aranceles', component: ArancelesComponent },
  { path: ':language/aranceles-rosario', component: ArancelesComponent },
  { path: ':language/aranceles-mendoza', component: ArancelesComponent },
  { path: ':language/aranceles-parana', component: ArancelesComponent },
  { path: ':language/busqueda/:data', component: BusquedaAvanzadaComponent }, //por palabra clave

  { path: ':language/educacion-continua', component: CursosListadoComponent },
  { path: ':language/educacion-continua/:id', component: DetalleCursoComponent },

  { path: ':language/ingreso', component: IngresoComponent },
  { path: ':language/ingreso/:sede', component: SedesIngresoComponent },
  { path: ':language/posgrados', component: PosgradosComponent },
  { path: ':language/posgrados/elegi-tu-posgrado', component: PosgradosComponent },

  { path: ':language/posgrados/buenos-aires', component: SedesPosgradosComponent }, //borrar cuando sea dinamico
  { path: ':language/posgrados/mendoza', component: SedesPosgradosComponent }, //borrar cuando sea dinamico
  { path: ':language/posgrados/paraná', component: SedesPosgradosComponent }, //borrar cuando sea dinamico
  { path: ':language/posgrados/rosario', component: SedesPosgradosComponent }, //borrar cuando sea dinamico
  
  { path: ":language/posgrados/:id", component: GeneralDosComponent, },
  { path: ":language/facultades/:seccion/curso-acreditable/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/maestria/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/doctorado/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/postitulo/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/curso-de-posgrado/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/curso-online/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/especializacion/:id", component: PosgradosGeneralUnoComponent, },
  { path: ":language/facultades/:seccion/posgrado-online/:id", component: PosgradosGeneralUnoComponent, },
  { path: ':language/facultades-e-institutos', component: FacultadesInstitutosComponent },
  { path: ':language/todas-las-carreras-de-la-uca', component: FacultadesInstitutosComponent },

  { path: ":language/facultades/:venue/:type/:carrer", component: CarrerasDeGradoComponent, },
  { path: ':language/facultades/:venue/:type/:carrer/autoridades', component: AutoridadesComponent },
  { path: ':language/facultades/:venue/:type/:carrer/:id', component: PosgradosGeneralDosComponent },

  { path: ':language/cursos-de-educacion-continua/:venue/programa-ejecutivo/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/curso-intensivo/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/actividades/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/jornada/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/taller/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/curso-acreditable/:carrer', component: CursosComponent },

  { path: ':language/cursos-de-educacion-continua/:venue/curso-de-posgrado-online/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/diplomatura/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/maestria/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/doctorado/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/postitulo/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/curso-de-posgrado/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/curso/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/curso-online/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/especializacion/:carrer', component: CursosComponent },
  { path: ':language/cursos-de-educacion-continua/:venue/posgrado-online/:carrer', component: CursosComponent },
  { path: ':language/facultades/:venue/:type/:carrer/:generic', component: SlideGenericPageComponent },
  { path: ':language/facultades/:venue', component: FacultadesComponent },
  { path: ':language/calendario/:month/:year', component: AlleventsComponent },
  { path: ':language/calendario/:month/:year/:venueCalendar', component: AlleventsComponent },

  {
    path: ':language/:title/:nivel2/:nivel3/:nivel4/:nivel5/:nivel6/:nivel7', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },
  {
    path: ':language/:title/:nivel2/:nivel3/:nivel4/:nivel5/:nivel6', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },
  {
    path: ':language/:title/:nivel2/:nivel3/:nivel4/:nivel5', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },

  {
    path: ':language/:title/:nivel2/:nivel3/:nivel4', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },
  {
    path: ':language/:title/:nivel2/:nivel3', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },
  {
    path: ':language/:title/:nivel2', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },
  {
    path: ':language/:title', children: [
      { path: '**', component: SimplePageComponent }
    ]
  },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
