import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-formacion-continuia',
  templateUrl: './formacion-continuia.component.html',
  styleUrls: ['./formacion-continuia.component.scss']
})
export class FormacionContinuiaComponent implements OnInit {
  @Input() id:string;
  datos:any;
  constructor(private services:GeneralService) { 
 
  }

  ngOnInit(): void {
    this.services.getEntitylist(this.id,'posgrado',false).subscribe(data=>{
      this.datos=data.data[0].entityList[0]

    })
  }

}
