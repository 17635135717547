  <div class="row">
    <ng-container #componenteDinamico1></ng-container>
    <ng-container #componenteDinamico2></ng-container>
    <ng-container #componenteDinamico3></ng-container>
    <ng-container #componenteDinamico4></ng-container>
    <ng-container #componenteDinamico5></ng-container>
    <ng-container #componenteDinamico6></ng-container>
    <ng-container #componenteDinamico7></ng-container>
    <ng-container #componenteDinamico8></ng-container>
    <ng-container #componenteDinamico9></ng-container>
    <ng-container #componenteDinamico10></ng-container>
    <ng-container #componenteDinamico11></ng-container>
    <ng-container #componenteDinamico12></ng-container>
    <ng-container #componenteDinamico13></ng-container>
    <ng-container #componenteDinamico14></ng-container>
    <ng-container #componenteDinamico15></ng-container>
    <ng-container #componenteDinamico16></ng-container>
    <ng-container #componenteDinamico17></ng-container>
    <ng-container #componenteDinamico18></ng-container>
    <ng-container #componenteDinamico19></ng-container>
    <ng-container #componenteDinamico20></ng-container>
  </div>
