import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { FormContactoComponent } from '../form-contacto/form-contacto.component';
import { MatDialog } from '@angular/material/dialog';
import { IframeComponent } from 'src/app/shared/components/iframe/iframe.component';

@Component({
  selector: 'app-nuestras-sedes',
  templateUrl: './nuestras-sedes.component.html',
  styleUrls: ['./nuestras-sedes.component.scss']
})
export class NuestrasSedesComponent implements OnInit {

  datos: any;
  @Input() backgroundColor="#003a73";
  @Input() btnColor="#5a8abe";
  @Input() title="NUESTRAS SEDES";
  @Input() label="Conocé nuestras carreras"
  datos2: any;
  items: any[] = [];
  language: any;
  data: any;
  arrayRowsSize: any;
  arrayRows: any[] = [];
  titles = [
    'buenos-aires', 'mendoza', 'facultad-de-teresa-de-avila', 'campus-del-rosario'
  ]
  sedes = [
    {
      title: 'Buenos Aires',
      direccion: 'Av. Alicia Moreau de Justo 1300 (C1107AAZ), C.A.B.A ',
      direccion2: '(C1107AAZ)',
      direccion3: ' C.A.B.A',
      facebook: 'https://es-la.facebook.com/ucaingreso/',
      instagram: 'https://www.instagram.com/ingreso.uca/?hl=es',
      whatsapp: 'https://api.whatsapp.com/send?phone=5491130234624&app=facebook&entry_point=page_cta', img: 'Campus_Puerto_Madero.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy',
      redirec: 'buenos-aires',
      tel: '5491130234624'
    },
    {
      title: 'Mendoza',
      direccion: 'Uruguay 750 (M550AYH) Godoy Cruz, Mendoza',
      direccion2: '(M550AYH),',
      direccion3: 'Godoy Cruz, Mendoza',
      facebook: 'https://es-la.facebook.com/UCA-Ingreso-Mendoza-402324053882458/',
      instagram: 'https://www.instagram.com/ucaingresomendoza/?hl=es-la',
      whatsapp: '', img: 'Campus_Mendoza2.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Mendoza',
      redirec: 'mendoza',
      tel: '2612056477'
    },
    {
      title: 'Paraná',
      direccion: 'Buenos Aires 239 (E3100BQF) Paraná, Entre Ríos',
      direccion2: ' (E3100BQF) ',
      direccion3: ' Paraná, Entre Ríos',
      facebook: 'https://www.facebook.com/UCAIngresoParana/',
      instagram: 'https://www.instagram.com/ucaingresoparana/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543434735453&app=facebook&entry_point=page_cta', img: 'Campus_Parana2.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Paran%C3%A1',
      redirec: 'paraná',
      tel: '543434735453'
    },
    {
      title: 'Rosario',
      direccion: 'Av. Pellegrini 3314 (S2002QEO) Rosario ,Santa Fe',
      direccion2: '(S2002QEO) ',
      direccion3: 'Rosario , Santa Fe',
      facebook: 'https://www.facebook.com/ucaingresorosario/',
      instagram: 'https://www.instagram.com/ucaingresorosario/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543412662910&app=facebook&entry_point=page_cta', img: 'Campus_Rosario.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Rosario',
      redirec: 'rosario',
      tel: '543412662910'
    }
  ]
  constructor(public dialog: MatDialog, private services: GeneralService, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    var url = "/es/facultades-e-institutos";
    var indexL = url.indexOf(this.language) + this.language.length + 1;
    var id = url.slice(indexL, url.length)
    this.services.getSimplepage(id, this.language).subscribe(data => {
      this.datos = data.data[0];
    })

    for (let i = 0; i < this.titles.length; i++) {
      const element = this.titles[i];
      this.services.getSinglePage(element, this.language).subscribe(data => {

        if (data.data) {
          this.items.push(data.data[0]);
          // 
        } else {

        }

      });

    }

  }
  getFormattedAddress(address: string) {
    if (window.innerWidth <= 768) {
      return address.replace(/,/g, ',<br>');
    }
    return address; 
  }
  ngOnInit(): void {
  }
  windowOpen(url) {
    window.open(url)
  }
  redirecWhatsApp(tel: string) {
    const url = `https://api.whatsapp.com/send?phone=${tel}`;
    window.open(url, '_blank');
  }
  redirecGoogleMaps(direccion: string): string {
    const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedDireccion = encodeURIComponent(direccion);
    return `${baseUrl}${encodedDireccion}`;
  }
  redirec(link) {
    window.location.href = link
  }
  ngAfterViewInit() {
    if (this.datos && this.datos['type'] == 'landing') {

      this.data = this.datos['pages'];
      this.arrayRowsSize = this.datos['pagesLength'];
      for (let i = 1; i < this.arrayRowsSize + 1; i++) {
        if (this.datos['pages'][i][0]['status']) {
          this.arrayRows.push(i);
          this.loadPageAt(i, this.datos['pages'][i][0].entityType);
        }
      }
    }
  }
  async loadPageAt(index: number, entityType: string) {
    if (this.data[index].length > 1) {
      /* this.services.getEntities(this.data[index][0]['route'] + '&locale=' + this.language).subscribe(data1 => {
        console.log("Data1", data1)
        this.definirComponentes(index, this.data[index][0], 8, data1)
        this.services.getEntities(this.data[index][1]['route'] + '&locale=' + this.language).subscribe(data2 => {
          console.log("data2", data2)

          this.definirComponentes(index, this.data[index][1], 4, data2)
        });
      }); */
    } else {
      this.services.getEntities(this.data[index][0]['route'] + '&locale=' + this.language).subscribe(data1 => {
       // console.log("this.data[index][0]['route']", this.data[index][0]['route'])


/*         this.definirComponentes(index, this.data[index][0], 12, data1)
 */      });
    }


  }


  openModal(url:any) {
    this.dialog.open(IframeComponent, {
      width: '90%',
      height: '80%',
      data: { url }
    });
  }
}
