

    <section class="subheader doble-m" >
        <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path">
        <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="datos.pathMobile">
        <div class="texto-s container">
            <h1 class="azul">{{datos.title}}</h1>
        </div>
    </section>
    
    <section class="agsec1">
        <div class="container">
            <div class="row">
                <div *ngIf='evento' class="col-lg-12">
    
                    <h2 class="fecha">{{datos.startOn.date| date: 'h:mm a'}} - {{datos.endOn.date| date: 'h:mm a'}}</h2>
                </div>
                <div class="col-lg-8" >
                    <p *ngIf='evento'><span class="azul">Fecha de inicio:</span> {{datos.startOn.date| date: 'd/M/yy, h:mm a'}}</p>
                    <p *ngIf='evento'><span class="azul">Fecha de culminación:</span> {{datos.endOn.date| date: 'd/M/yy, h:mm a'}}</p>
                    <p *ngIf='evento'>{{datos.summary}}</p>
    
                    <div id='body'></div>
    
    
    
                </div>
                <div class="col-lg-4" *ngIf='datos.length > 0'>
                    <h2>Agenda</h2>
                    <div class="datos-lat desk">
                        <div *ngFor='let item of datos' class="datos-lat-item">
                            <a class='pointer' [href]="item.link">
                                <div class="fecha">{{item.date | date:'d/M/yy'}} <span>{{item.hours| date: 'h:mm a'}}</span>
                                </div>
    
                                <div class="desc">{{item.title}}</div>
                            </a>
                        </div>
                        <a    [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
                    </div>
                    <div class="agenda-lat mob">
                        <div id="agenda-s" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li *ngFor='let item of datos;index as i ' data-target="agenda-s"
                                    (click)="cambiarSlider(i)" [ngClass]='(item.id== noticiaActive)?"active" : ""'></li>
    
                            </ol>
                            <div class="carousel-inner">
                                <div *ngFor='let item of datos' class="carousel-item "
                                    [ngClass]='(item.id== noticiaActive)?"active" : ""'>
                                    <div class="agenda-lat-item">
                                        <a class='pointer' [href]="item.link">
    
                                            <div class="fecha">{{item.date | date:'d/M/yy'}}
                                                <span>{{item.hours| date: 'h:mm a'}}</span></div>
                                            <div class="desc">{{item.title}}</div>
                                        </a>
                                    </div>
    
    
    
                                </div>
    
                            </div>
                        </div>
                        <a    [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
