import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-areas-de-conocimiento',
  templateUrl: './areas-de-conocimiento.component.html',
  styleUrls: ['./areas-de-conocimiento.component.scss']
})
export class AreasDeConocimientoComponent implements OnInit {
  @Input() data: any;
  @Input() fondo: any;
  @Input() fondoMob: any;
  @Input() cursos: boolean = false;
  width = false;
  posgrados: any[] = [];
  dataCursos: any
  constructor(private dataService: DataService, private router: Router) {
   
  }

  ordenarArray(items: any[]): { arriba: any[], abajo: any[] } {
      let arriba;
      let abajo
      
      if(this.cursos){
        const typedItems = items as unknown as {
          areas_tematicas_not_check_school: any[];
          areas_tematicas_check_school: any[];
        };
        arriba = typedItems.areas_tematicas_not_check_school
        abajo = typedItems.areas_tematicas_check_school
  
      }
    return { arriba, abajo };
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")'); 
  }
  ngOnInit(): void {
    $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")');

    this.dataCursos = this.ordenarArray(this.data)
  }
 
}
