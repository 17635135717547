import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-video-mas-formulario',
  templateUrl: './video-mas-formulario.component.html',
  styleUrls: ['./video-mas-formulario.component.scss']
})
export class VideoMasFormularioComponent implements OnInit {

  @Input() entityId: number;
  @Input() linkCode: string;
  @Input()  idioma='es'
  formDatos:FormGroup;
  language: any;
  constructor(private route:ActivatedRoute,private _sanitizer: DomSanitizer,private form:FormBuilder, private services: GeneralService) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.formDatos = this.form.group({
      name: ['',Validators.required],
      email: ['', [Validators.required,Validators.email]],
      message: [''],
    });
   }

  ngOnInit(): void {
   
  }
  consultar() {
  
    let datos = new ConsultaRequest();
    datos.page=window.location.href.replace(environment.frontUrl+'/'+this.language+'/','')
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.subject = this.formDatos.get('message').value;
    datos.entityId = this.entityId;
    let atributos = ({career: carrera, name: nombre, phone: whatsapp, region: sede}) => ({  nombre, whatsapp, sede });

    let evento = {
      eventName: 'form_psico',
      email: datos.email,
      attributes: atributos(datos)
    };
    this.services.postEmblueForm(datos).subscribe(
      (data) => console.log({ message: 'data enviada', data: data}),
      error => console.log({ message: 'ocurrio un error', error: error })
    )
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )

  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?disablekb=1');
  }
}
