<section class="subheader doble-m">
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path">
    <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="datos.pathMobile">
    <div class="texto-s container">
        <h1 class="azul">{{datos.title}}</h1>
    </div>
</section>
<section class="agsec1">
    <div class='row'>
        <div class='col'>
            <p *ngIf="datos.simplePageTitle" _ngcontent-c3="" class="fs-16 c-principal breadcump">
                <span _ngcontent-c3="" id="breadcump">
                    <a [href]="language+'/' +datos.simplePageSystemTitle" class="c-principal">
                        <strong> {{datos.simplePageTitle}} > </strong>
                    </a>
                    {{datos.title}}
                </span>
            </p>
            <p *ngIf="datos.venueTitle" _ngcontent-c3="" class="fs-16 c-principal breadcump">
                <span _ngcontent-c3="" id="breadcump">
                    <a [href]="language+'/facultades/' +datos.venueSystemTitle" class="c-principal">
                        <strong> {{datos.venueTitle}} > </strong>
                    </a>
                    {{datos.title}}
                </span>
            </p>
        </div>
    </div>
</section>

<section class="agsec1 " style="padding-top:0px!important;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div id='body'></div>
            </div>
            <div class="col-lg-12">
                <p style='margin: 0px;' *ngIf='datos.publicationAt'>Publicado el {{datos.publicationAt.date | date:'d/M/yy'}}</p>
                <p style='margin: 0px;' *ngIf='datos.venueTitle'>Publicado por {{datos.venueTitle}}</p>
            </div>
        </div>
    </div>
</section>