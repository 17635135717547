import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ArancelesService } from 'src/app/core/services/aranceles/aranceles.service';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-aranceles',
  templateUrl: './aranceles.component.html',
  styleUrls: ['./aranceles.component.scss']
})
export class ArancelesComponent implements OnInit {

  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: any[] = [];
  resultados: any[] = [];
  buscador: FormGroup; pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  spinner = true;
  //filtro = false;
  language = "es"
  arancel: any;
  textFilter: string;
  carrerasSinFiltro: any;
  fecha: string;
  region = "buenos-aires"
  constructor(private router: Router, public route: ActivatedRoute, private arancelesService: ArancelesService, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url == "/" + this.language + "/aranceles-rosario") {
          this.region = "rosario"
        }

        if (item.url == "/" + this.language + "/aranceles-mendoza") {
          this.region = "mendoza"
        }

        if (item.url == "/" + this.language + "/aranceles-parana") {
          this.region = "parana"
        }
        this.arancelesService.getCarreras(this.region).subscribe(data => {
          this.resultados = [];
          this.carreras = data.data[0];
         
          // this.carreras.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });
          this.carreras.sort(function (a, b) {
            return (b.title - a.title)
          });
          let cant = Math.round(this.carreras.length / this.pageSize)
          for (let i = 0; i < cant; i++) {
            this.pages.push(i + 1);
          }
          this.inicio()

        })
        this.arancelesService.getCarreras(this.region).subscribe(data => {
          this.resultados = [];
          this.carreras = data.data[0];
          this.carrerasSinFiltro = data.data[0];
          this.carrerasSinFiltro.sort(function (a, b) {
            let orderA = (a.title) ? a.title : a.description
            let orderB= (b.title) ? b.title : b.description
            if (orderA > orderB) {
              return 1;
            }
            if (orderA < orderB) {
              return -1;
            }
            return 0;
          })
        })
      }
    })


  }


  ngOnInit(): void {
    let date = new Date();
    this.fecha = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
  }

  filtrar(event) {
    let text = this.stringEstandar(event.target.value);
    this.textFilter = text;
    this.cambiarPagina(1, text)
  }
  stringEstandar(string: String) {
    let estandar = string.toLocaleLowerCase();
    return estandar.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  filtrarSinPaginado(event) {
    let text = this.stringEstandar(event.target.value);
    this.textFilter = text;
    this.filtrarArray(text)
  }
  filtrarArray(text) {
    let preresultados = this.carreras.filter(f => {
      if (f.title) {
        return this.stringEstandar(f.title).startsWith(text);
      } else {
        return false; 
      }
    });
    this.carrerasSinFiltro = preresultados;
    this.carrerasSinFiltro.sort(function (a, b) {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  inicio() {
    this.spinner = true;
    this.arancelesService.getCarreras(this.region).subscribe(data => {
      this.resultados = data.data[0];
      this.carreras = data.data[0];
      this.carreras.sort(function (a, b) {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      this.resultados.sort(function (a, b) {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      this.cambiarPagina(1, null)

    })


  }
  cambiarPagina(page, text) {
    this.pages = [];
    if (text) {
      let preresultados = this.carreras.filter(f => {
        if (f.title) {
          console.log(f.title)
          return this.stringEstandar(f.title).startsWith(text);
        } else {
          return false; 
        }
      });
      this.resultados = preresultados.slice(((page - 1) * this.pageSize), (page) * this.pageSize);
      let cant = Math.round(preresultados.length / this.pageSize);
      for (let i = 0; i < cant; i++) {
        this.pages.push(i + 1);
      }
    } else {
      this.resultados = this.carreras.slice(((page - 1) * this.pageSize), (page) * this.pageSize);
      let cant = Math.round(this.carreras.length / this.pageSize);
      for (let i = 0; i < cant; i++) {
        this.pages.push(i + 1);
      }
    }
  
    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;
  }

  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }

  verArancel(item) {
    if (item.observation) {
      let date, month, year;
      date = new Date().getDate();
      month = new Date().getMonth() + 1;
      year = new Date().getFullYear();

      if (date < 10) {
        date = '0' + date;
      }

      if (month < 10) {
        month = '0' + month;
      }

      date = date
        .toString()
        .padStart(2, '0');

      month = month
        .toString()
        .padStart(2, '0');
      item.observation = item.observation.replaceAll("DATE-NOW", " " + `${date}/${month}/${year}` + " ")
    }
    this.arancel = item;
    var element = document.getElementById("card-arancel")
    element.scrollIntoView({ block: "center", behavior: "smooth" });
  }
}
