import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard2 } from '../../models/registroNoticia';
import { Carrera } from '../../models/observacion';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-card-overlay',
  templateUrl: './card-overlay.component.html',
  styleUrls: ['./card-overlay.component.scss']
})
export class CardOverlayComponent implements OnInit {
  @Input() carrera: Carrera
  @Input() borde: boolean = false
  language: any
  @Input() posgrado: boolean = false
  @Input() detalleCurso: boolean = false
  constructor(private aRouter: ActivatedRoute,private servicioGeneral:GeneralService) { 
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }
  redirect(c) {
    if (c.route) {
      window.location.href = this.language + c.route
    } else if (c.linkCareer) {
      window.location.href = this.language + c.linkCareer
    }

  }
  cortarTitulo(carrera: { titulo?: string; nombre?: string; title?: string }): string {
    let titulo = "";
  
    if (carrera.titulo) {
      titulo = carrera.titulo;
    } else if (carrera.nombre) {
      titulo = carrera.nombre;
    } else if (carrera.title) {
      titulo = carrera.title;
    }
  
    if (titulo) {
      titulo = titulo.length > 50 ? titulo.slice(0, 50) + "..." : titulo;
            return titulo;
    }
  
    return "";
  }
  
  formatearFecha(value: any): any {
    if (value.inicio && value.inicio.date) {
      const date = new Date(value.inicio.date);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];
      return `${day} de ${monthName}`;
    } else {
      return 'CONSULTAR'
    }

  }
  ngOnInit(): void {
    //console.log("Carrera", this.carrera)
  }
 

}
