<div class="container">
    <div class="row">
        <div class="col-lg-12" *ngIf='datos'>
            <h2>FORMACIÓN CONTINUA <br>Cursos Abiertos, Jornadas y Extensión</h2>            
          </div>
          <div class="col-lg-4" *ngFor='let item of datos.entity;index as i '>
              <a [href]="item.systemTitle">
                <img onerror="this.src='./assets/img/default.png'" [src]="'../../../../../assets/img/pos'+(i+1)+'.jpg'">
                <h5 class="azul">{{item.title}}</h5>
              </a>
            
          </div>
        
    </div>
</div>