import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-posgrados-general-dos',
  templateUrl: './posgrados-general-dos.component.html',
  styleUrls: ['./posgrados-general-dos.component.scss']
})

export class PosgradosGeneralDosComponent implements OnInit {
  datos: any;
  items: any[] = [];
  id: string;
  language: any;
  venueFinal: string;
  carrerFinal: string;
  venue: string;
  carrer: string;
  pagina: string;
  carrerTypes=["maestria","pregrado","profesorado","posgrado","ciclo-de-licenciatura","tecnicatura","carrera-de-grado","doctorado","postitulo","curso-de-posgrado","curso-online","especializacion","posgrado-online"];

  constructor(private services: GeneralService, private aRouter: ActivatedRoute, private router: Router) {
    this.language = this.aRouter.snapshot.params['language'];
    this.venue = this.aRouter.snapshot.params['venue'];
    this.venueFinal = this.language + '/facultades/' + this.venue;
    this.venue = this.prepararString(this.venue);

    let type = this.aRouter.snapshot.params['type'];
    this.carrer = this.aRouter.snapshot.params['carrer'];
    this.carrerFinal = this.venueFinal + '/' + type + '/' + this.carrer;
    this.carrer = this.prepararString(this.carrer);

    this.id = this.aRouter.snapshot.params['id'];
    this.pagina = this.prepararString(this.id);



    if(!this.carrerTypes.includes(type)){
      router.navigate(['404']);
    }
    if (this.id != 'plan-de-estudio' && this.id != 'perfil-del-titulo') {
      router.navigate(['404']);
    }
    if (this.id == 'plan-de-estudio') {
      this.services.getstudyplan(this.aRouter.snapshot.params['carrer']).subscribe(data => {
        if (data.data) {
          this.datos = data.data[0][0];
          this.items = data.data[0][0].studyPlanItems;
          if (this.datos.body && document.getElementById('body-p')) {
            document.getElementById('body-p').innerHTML = this.datos.body;
          }
        } else {
          router.navigate(['404']);
        }
      })
    }

    if (this.id == 'perfil-del-titulo') {
      this.services.getCarreraProfileTitle(this.aRouter.snapshot.params['carrer']).subscribe(data => {
        if (data.data) {
          this.datos = data.data[0];
          if (this.id == 'perfil-del-titulo' && document.getElementById('body')) {
            document.getElementById('body').innerHTML = this.datos.profileTitleScope;
          }
        } else {
          router.navigate(['404']);
        }
      })
    }

  }

  ngOnInit(): void {
  }


  prepararString(str) {
    var str = str.replace(/-/gi, " ");
    return str[0].toUpperCase() + str.slice(1);
  }

}
