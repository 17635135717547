<!--<img class="imgen-fondo" src="./assets/img/Fondo-cursos.png" alt="">-->
<div class="fondo-area " style="text-align: -webkit-center;   ">
    <div #animatedSection style="    justify-content: center;          ">
        <div id="areas" class="posg-cont-c ">
            <app-card-area-conocimiento [id]="'arriba'+i" class="area-card "
                *ngFor='let item of arriba; index as i' 
                [item]="item">
            </app-card-area-conocimiento>
        </div>
        <div class="linea" *ngIf="abajo && abajo.length>0"></div>

        <div id="areas" class="posg-cont-c ">
            <app-card-area-conocimiento [id]="'abajo'+i" class="area-card "
                *ngFor='let item of abajo; index as i' [item]="item">
            </app-card-area-conocimiento>

        </div>

        <!--<div id="areas" class="posg-cont-c ">
            <app-card-area-conocimiento [id]="'arriba'+i" class="area-card zoomOut"
                *ngFor='let item of arriba; index as i' 
                [item]="item">
            </app-card-area-conocimiento>
        </div>
        <div class="linea" *ngIf="abajo && abajo.length>0"></div>

        <div id="areas" class="posg-cont-c ">
            <app-card-area-conocimiento [id]="'abajo'+i" class="area-card zoomOut"
                *ngFor='let item of abajo; index as i' [item]="item">
            </app-card-area-conocimiento>

        </div>--->
    </div>
</div>

