<section class="facsec2" [style.background]="background">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 [class]='styleTitle'>{{title}}
        </h2>
      
        <div id="card" class="grid-cont desk " [ngClass]='(numberCards)? "grid-"+numberCards : "grid-4"'>
          <a [href]="item.systemTitle" *ngFor='let item of cardsDesk' >
            <div class="grid-item pointer"   >
              <div *ngIf='item.categoria && item.checkTag' class='categoria'>{{item.categoria}}</div>
              <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
                <p class='box-text'>{{item.title}}</p>
            </div>
          </a>
          <!-- <div *ngIf='cards.length>6 && cards.length != cardsDesk.length' class="cargar" (click)='addCards()'>
            <i class="fas fa-chevron-down"></i>
          </div>
          <div *ngIf='cargado && cards.length == cardsDesk.length' class="cargar" (click)='removeCards()'>
            <i class="fas fa-chevron-up"></i>
          </div>-->
        </div>
        <div id="hsec1-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
          <ol class="carousel-indicators">
            <li *ngFor='let item of cards;index as i ' data-target="Noticias-s" (click)="cambiarSlider(i)"
              [ngClass]='(item.id== noticiaActive)?"active" : ""'></li>

          </ol>
          <div class="carousel-inner" id="card" >
            <div class="carousel-item pointer"  *ngFor='let item of cards' class="carousel-item "
              [ngClass]='(item.id== noticiaActive)?"active" : ""'>
                <div class="grid-item">
                  <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
                  <a [href]="item.systemTitle">
                    <p class='box-text'>{{item.title}}</p>
                  </a>
                 
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>