import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-list-desplegable',
  templateUrl: './list-desplegable.component.html',
  styleUrls: ['./list-desplegable.component.scss']
})
export class ListDesplegableComponent implements OnInit {

  @Input() items: any;
  @Input() title: any;
  @Input() urlExtra = '';
  language: any;
  constructor(public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    // this.items.sort(function (a, b) {
    //   if (a.title > b.title) {
    //     return 1;
    //   }
    //   if (a.title < b.title) {
    //     return -1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // })
  }

  ngOnInit(): void {
    this.items.forEach(element => {
      //maestria-en-administracion-de-empresas--mba
      // /facultades/facultad-de-ciencias-economicas/maestria/maestria-en-administracion-de-empresas--mba

      //si existe un element.link que me trae todo la url excepto el idioma ejecuto la sentencia if
      //si no viene y viene element.systemTitle contruyo el link con los elementos que se pasan por parametro
      //ojo con que urlExtrapuede llegar a venir como undefined 
      if (element.link) {
        
        if (element.link.slice(0, 1) == '/') {
          element.systemTitle = this.language+ element.link;
        } else {
          element.systemTitle = this.language + '/' + element.link;
        }

      } else if (element.systemTitle) {

        if (element.systemTitle.slice(0, 1) == '/') {
          element.systemTitle = this.language + this.urlExtra + element.systemTitle;

        } else {
          element.systemTitle = this.language + '/' + this.urlExtra + element.systemTitle;

        }


      }

    });
  }
  abrirPanel(i) {
    if ($('#collapse' + i).hasClass('show')) {
      $('.show').addClass('show');
      $('#collapse' + i).removeClass('show');
    } else {
      $('.show').removeClass('show');
      $('#collapse' + i).addClass('show');
    }
  }

}
