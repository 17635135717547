<h2 class="azul">CURSOS PRÓXIMOS A INICIAR</h2>
<mat-tab-group animationDuration="2000ms" *ngIf="!educacionContinua">
   <!-- POSGRADOS -->
    <mat-tab label="Ver todo">
        <div class="resultados p3">
            <ul *ngIf='!spinner'>
                <li *ngFor='let item of resultadosTodos' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>

        <div class="paginacion">
            <div (click)='cambiarPaginaTodos(page,resultadosTodosFilter)' *ngFor="let page of  pagesTodos"
                class="number " [ngClass]='(numberActiveTodos == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosTodos.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf='spinner'>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Cursos de Posgrados">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosPosgrados' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaPosgrados(page,resultadosPosgradosFilter)' *ngFor="let page of  pagesPosgrados"
                class="number " [ngClass]='(numberActivePosgrados == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosPosgrados.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Doctorados">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosDoctorado' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaDoctorado(page,resultadosDoctoradoFilter)' *ngFor="let page of  pagesDoctorado"
                class="number " [ngClass]='(numberActiveDoctorado == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosDoctorado.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Maestría">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosMaestria' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaMaestria(page,resultadosMaestriaFilter)' *ngFor="let page of  pagesMaestria"
                class="number " [ngClass]='(numberActiveMaestria == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosMaestria.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    
    <mat-tab label="Especializacion">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosEspecializacion' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaEspecializacion(page,resultadosEspecializacionFilter)' *ngFor="let page of  pagesEspecializacion"
                class="number " [ngClass]='(numberActiveEspecializacion == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosEspecializacion.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    


</mat-tab-group>

<mat-tab-group animationDuration="2000ms" *ngIf="educacionContinua">
    <!-- EDUCACION CONTINUA -->
    <mat-tab label="Ver todo">
        <div class="resultados p3">
            <ul *ngIf='!spinner'>
                <li *ngFor='let item of resultadosTodos' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>

        <div class="paginacion">
            <div (click)='cambiarPaginaTodos(page,resultadosTodosFilter)' *ngFor="let page of  pagesTodos"
                class="number " [ngClass]='(numberActiveTodos == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosTodos.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf='spinner'>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Cursos de Posgrados">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosCursosPosgrado' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaCursosPosgrado(page,resultadosCursosPosgradoFilter)' *ngFor="let page of  pagesCursosPosgrado"
                class="number " [ngClass]='(numberActiveCursosPosgrado == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosCursosPosgrado.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Cursos">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosCursos' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaCursos(page,resultadosCursosFilter)' *ngFor="let page of  pagesCursos"
                class="number " [ngClass]='(numberActiveCursos == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosCursos.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Cursos Online">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosCursosOnline' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaCursosOnline(page,resultadosCursosOnlineFilter)' *ngFor="let page of  pagesCursosOnline"
                class="number " [ngClass]='(numberActiveCursosOnline == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosCursos.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    
    <mat-tab label="Cursos de Posgrado online">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosCursosPosgradoOnline' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaCursosPosgradoOnline(page,resultadosCursosPosgradoOnlineFilter)'
                *ngFor="let page of  pagesCursosPosgradoOnline" class="number "
                [ngClass]='(numberActiveCursosPosgradoOnline == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosCursosPosgradoOnline.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Diplomatura">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosDiplomatura' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaDiplomatura(page,resultadosDiplomaturaFilter)' *ngFor="let page of  pagesDiplomatura"
                class="number " [ngClass]='(numberActiveDiplomatura == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosDiplomatura.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Postítulos">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosPostitulo' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaPostitulo(page,resultadosPostituloFilter)' *ngFor="let page of  pagesPostitulo"
                class="number " [ngClass]='(numberActivePostitulo == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosPostitulo.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Posgrados online">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosPosgradosOnline' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaPosgradosOnline(page,resultadosPosgradosOnlineFilter)' *ngFor="let page of  pagesPosgradosOnline"
                class="number " [ngClass]='(numberActivePosgradosOnline == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosPosgradosOnline.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab>
    <!-- <mat-tab label="Cursos acreditables a Doctorados">
        <div class="resultados p3">
            <ul>
                <li *ngFor='let item of resultadosCursos' class='mt-3 fs-21'><a [href]='language + item.link'>
                        {{item.nombre}} </a></li>

            </ul>
        </div>
        <div class="paginacion">
            <div (click)='cambiarPaginaCursos(page,resultadosCursosFilter)' *ngFor="let page of  pagesCursos"
                class="number " [ngClass]='(numberActiveCursos == page)?"active":""'>{{page}}</div>
        </div>
        <div *ngIf=' resultadosCursos.length == 0' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </mat-tab> -->


</mat-tab-group>