<section class="agsec1">
    <div class="container">
        <div class='row'>
            <div class='col'>
                <section class="bussec1">
                    <h2>Materias del curso de ingreso</h2>
                   
                    <div style='background-color: #003567 !important;padding: 5%;'>
                        <div class="col-lg-12" id='buscador'>
                            <form class="form-group" id="busqueda"
                                style='margin-bottom:0px;justify-content: space-between;'>
                                <input type="text" placeholder="Buscar carrera..." (keyup)="filtrarSinPaginado($event)">
                            </form>
                        </div>
                    </div>
                    <div class="resultados card p-2" id="resultados">
                        <ul *ngIf='!spinner'>
                            <li class='mt-3 fs-21 carrera' *ngFor='let carrera of carrerasSinFiltro'
                                (click)="verinfo(carrera)">
                                <a>
                                    {{carrera.career_title}} </a>
                            </li>

                        </ul>
                        <!-- <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>-->
                        <div class="d-flex justify-content-center" *ngIf='spinner '>
                            <div class="spinner-grow text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <!-- <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
                            <div (click)='cambiarPagina(page,textFilter)' *ngFor="let page of  pages" class="number "
                                [ngClass]='(numberActive == page)?"active":""'>{{page}}</div>
                        </div> -->

                        <!--<div *ngIf=' resultados.length == 0 && !spinner && filtro' class='p-3 text-center'>-->
                        <div *ngIf='carrerasSinFiltro &&  carrerasSinFiltro.length == 0 && !spinner '
                            class='p-3 text-center'>
                            <div class="alert alert-primary text-center" role="alert">
                                No hay resultados
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="row" id="card-info">
            <div class="col" *ngIf="info">
                <table class="table table-light table-bordered text-sm-center">
                    <thead>
                        <tr>
                            <th colspan="2">{{career_title}}</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{info.commonSubject}}</td>
                            <td>{{info.specificSubject }}</td>
                        </tr>
                    </tbody>
                </table>

               
                <p class="c-principal fs-15" >
                    {{info.observation}}
                </p>

            </div>
        </div>
    </div>
</section>