<mat-dialog-content  (swipeleft)="changeImg(1)"
  (swiperight)="changeImg(-1)">

  <div class="ui grid">
    <div class="row">
      <div class="two wide column">
        <button fxHide fxShow.gt-sm *ngIf="gallery.length > 1 " mat-button (click)="changeImg(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
              </svg>       
        </button>
      </div>
      <div class="fourteen wide column text-center">
        <p>{{titulo}}</p>
        <img onerror="this.src='./assets/img/default.png'" class="" [src]="image.path"  />
        <p>{{image.position+1}} de {{gallery.length}}</p>
      </div>
      <div class="two wide column">
        <button fxHide fxShow.gt-sm *ngIf="gallery.length > 1  " mat-button (click)="changeImg(1)" style='right: 10px;'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
              </svg>
            </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
