<div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2 style="margin-bottom:10px;">Sumate a nuestra comunidad</h2>
        <div class="redes-sec">
          <a id="redes" href="" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/facebook2.jpg" /></a>
          <a id="redes" href="" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/youtube2.jpg" /></a>
          <a id="redes" href="" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/instagram2.jpg" /></a>
          <a id="redes" href="" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/linkedin2.jpg" /></a>
        </div>
      </div>          
    </div>
    <div class="comu-cont desk">
      <div class="comu-item" *ngFor="let item of ListaSeguinos">
        <img onerror="this.src='./assets/img/default.png'" [src]="item.img" />
        <div class="comu-desc">
          <div class="comu-tit"><img onerror="this.src='./assets/img/default.png'" [src]="item.redSocialIcon" /> Leer más </div>
          <p>{{item.titulo}}</p>
          <!--<p class="fecha">09 Mayo 2021</p>-->
        </div>
      </div>    
           
    </div>
    <div class="comu-cont mob">
      <div id="comu-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
        <ol class="carousel-indicators">
          <li  *ngFor='let item of ListaSeguinos;index as i '  data-target="Noticias-s" (click)="cambiarSlider(i)" [ngClass]='(item.id== Active)?"active" : ""' ></li>

        </ol>
        <div class="carousel-inner">
          {{Active}}
          <div class="carousel-item " *ngFor="let item of ListaSeguinos"  [ngClass]='(item.id== Active)?"active" : ""'>
            <div class="comu-item" >
              <img onerror="this.src='./assets/img/default.png'" [src]="item.img" />
              <div class="comu-desc">
                <div class="comu-tit"><img onerror="this.src='./assets/img/default.png'" [src]="item.redSocialIcon" /> Leer Más </div>
                <p>{{item.titulo}}</p>
                <!--<p class="fecha">09 Mayo 2021</p>-->
              </div>
            </div>    
        
        </div>
      </div>
      </div>
  </div>   
 </div>  