<section class="facsec2" id='container-video'>
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h2>Conocenos</h2>
                <p style="display: flex;">
                    <iframe width="700" height="400" [src]="getVideoIframe(linkCode)" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </p>
            </div>
    
        </div>
    </div>
</section>