<section *ngIf="datos" class="subheader sub-video doble-m">
    <img onerror="this.src='./assets/img/default.png'" class=" img-desk img-title w-100 desk img-desk"
        [src]="datos.path">
    <img onerror="this.src='./assets/img/default.png'" class=" img-mob img-title w-100 mob img-mob"
        [src]="datos.pathMobile">
    <!--OVC-bqQ8CVE-->
    <!-- <iframe *ngIf='link' allow="loop; autoplay; encrypted-media" allowfullscreen="" frameborder="0"
        [src]="getVideoIframe(link)">
    </iframe>-->
    <div class="texto-s container texto-s-center">
        <!--<a href="es/ingreso/elegi-tu-carrera" class="btn btn-primary">ELEGÍ TU CARRERA</a>-->
        <div class='container-carousel-header w-100'>
            <div *ngFor='let boton of botones' class="carousel-cell-header m-2">
                <div *ngIf='boton.estado' [style.background]="boton.background" class='content-carousel-header'>
                    <a [href]="boton.link">{{boton.label}}</a>
                </div>

            </div>

        </div>
    </div>
</section>
<section *ngIf="datos" class="facsec2 min">
    <div class="container">
        <div class="row container-info">
            <div class="col-3">
                <div class="card-tematicas">
                    <h3>Temáticas</h3>

                    <ul class="mematicas" *ngFor="let area of datos.tematicas">
                        <li *ngFor="let item of area.subcategorias">
                            <span class="link" [routerLink]="['/' + language + '/' + area.url]"
                                [queryParams]="{ categoria: item }"> {{ item }} </span>
                        </li>
                    </ul>
                </div>

                <div class="col-12 text-center mt-5">
                    <span class=" subtitle azul ">Contactate con nosotros</span>
                </div>
                <!-- <div class="col-12 text-center" *ngIf="datos.whatsapp">
                    <a [href]="datos.whatsapp" Target="_blank">
                        <img class="icon-wsp pointer" src="./assets/img/whatsapp.png" alt="">
                    </a>
                </div>
                <div class="col-12 text-center col-seguinos">
                    <span class=" subtitle azul ">Seguinos en redes</span>
                </div>
                <div class="col text-center">
                    <a [href]="datos.instagram" Target="_blank" *ngIf="datos.instagram">
                        <img class="icon-redes" src="./assets/img/instagram-logo-circle.png" alt="">
                    </a>
                    <a [href]="datos.facebook" Target="_blank" *ngIf="datos.facebook">
                        <img class="icon-redes" src="./assets/img/facebook.png" alt="">
                    </a>
                </div>-->
                <div class="col text-center">
                    <a target="_blank" [style.background-color]="datos.color" [style.border-color]="datos.color"
                        (click)="openModalConsulta()" class=" btn btn-primary green">
                        <img class='' [style.width]="'2.5em'" src="./assets/img/enviar.png">
                        Consultas
                    </a>

                </div>

            </div>
            <div class="col-9">
                <div class="row">
                    <div class="col">
                        <h1 class="title" [style.color]="datos.color" [style.font-size]="datos.size_title+'px'">
                            {{datos.title}}</h1>
                        <span [style.color]="'#485852'" [style.font-size]="'23px'">
                            {{datos.subtitle}}
                        </span><br>
                        <span class="subtitle">
                            {{datos.venue.title}} | Sede {{datos.region[0].title}}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8 col-info">
                        <div class="card-info" [style.background-color]="datos.color">
                            <div class="row row-info">
                                <div class="info col-6">
                                    <img class='icon' [style.width]="'3.5em'" src="./assets/img/calendar.png">
                                    <span>Inicio
                                        <br>
                                        {{datos.date_search_start }}
                                    </span>
                                </div>
                                <div class="info col-6">
                                    <img class='icon' [style.width]="'3.5em'"
                                        src="./assets/img/icono-finalizacion-png-blanco.png">
                                    <span>Finalización
                                        <br>
                                        {{datos.date_ending}}
                                    </span>
                                </div>

                            </div>
                            <div class="row row-info">
                                <div class="info col-6">
                                    <img class='icon' [style.width]="'3.5em'" src="./assets/img/reloj-circular.png">
                                    <span>Duración
                                        <br>
                                        {{datos.workload}}
                                    </span>
                                </div>

                                <div class="info col-6">
                                    <img class='icon' [style.width]="'3.5em'"
                                        src="./assets/img/icono-cursada-png-blanco.png">
                                    <span>Cursada
                                        <br>
                                        {{datos.coursed}}
                                    </span>
                                </div>
                            </div>

                            <div class="row row-info">
                                <div class="column col-6">
                                    <div class="info col-12">
                                        <img class='icon' [style.width]="'3.5em'"
                                            src="./assets/img/pantalla-de-portatil.png">
                                        <span>Modalidad
                                            <br>
                                            {{datos.modality}}
                                        </span>
                                    </div>
                                    <div class="info col-12" [style.margin-top]="'15px'">
                                        <img class='icon' [style.width]="'3.5em'" src="./assets/img/enviar.png">
                                        <span>Contacto
                                            <br>
                                            {{datos.mail_contact}}
                                        </span>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row col-12 text-center icon-row"  [style.margin]="'0px'" *ngIf="datos.whatsapp">
                                        <a [href]="datos.whatsapp" Target="_blank">
                                            <img class="icon-redes pointer" src="./assets/img/whatsapp-blanco2.png"
                                                alt="">
                                        </a>
                                        <span [style.margin]="'auto 3px'">Whatsapp</span>

                                    </div>

                                    <div class="row col-12  text-center " [style.margin]="'25px 0px 0px 0px'">

                                        <div class="column text-center">
                                            <div class="circle-icon">
                                                <a [href]="datos.facebook" [style.margin]="'5px'" Target="_blank"
                                                    *ngIf="datos.facebook">
                                                    <img class="icon-redes2" src="./assets/img/facebook-blanco.png"
                                                        alt="">
                                                </a>
                                            </div>
                                            <div class="circle-icon">
                                                <a [href]="datos.instagram" [style.margin]="'5px'" Target="_blank"
                                                    *ngIf="datos.instagram">
                                                    <img class="icon-redes2" src="./assets/img/instagram-blanco.png"
                                                        alt="">
                                                </a>

                                            </div>
                                        </div>
                                        <span [style.margin]="'10px 3px'">Nuestras Redes</span>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div class="col-4 col-info-2">
                        <div class="card-border">
                            <h3 *ngIf="datos.curso_gratuito" class="title" [style.color]="datos.color"> Curso
                                Gratuito
                            </h3>

                            <div *ngIf="!datos.curso_gratuito">
                                <h3 class="title" [style.color]="datos.color"> {{datos.titulo_valor_curso}}</h3>
                                <h3 class="subtitle">
                                    {{datos.arancel | currency:undefined:"$"}}
                                </h3>
                                <div class="vr" [style.border]="'solid 1px #48585229'"></div>
                                <div class="card cuotas" *ngFor="let cuota of cuotas">
                                    <div class="container-title">
                                        <span class="title" [style.color]="datos.color"> {{cuota.cant }} </span>
                                        <span *ngIf="cuota.cant > 1" [style.color]="datos.color" class="title">
                                            cuotas
                                        </span>
                                        <span *ngIf="cuota.cant == 1" [style.color]="datos.color" class="title">
                                            cuota
                                        </span>
                                        <span class="title" [style.color]="datos.color" *ngIf="!datos.interes">SIN
                                            INTERES</span>
                                    </div>
                                    <span *ngIf='cuota.valor' class="label">
                                        {{cuota.valor | currency:undefined:"$"}}
                                    </span>

                                </div>
                            </div>

                            <a *ngIf='datos.button_signUp_state && !datos.curso_gratuito'
                                [style.background-color]="datos.color" [style.border-color]="datos.color"
                                [href]='datos.button_signUp' target="_blank" class=" btn btn-primary ">
                                Inscribite y aboná
                            </a>
                            <a *ngIf='datos.button_signUp_state && datos.curso_gratuito'
                                [style.background-color]="datos.color" [style.border-color]="datos.color"
                                [href]='datos.button_signUp' target="_blank" class=" btn btn-primary ">
                                Inscribite
                            </a>

                        </div>
                    </div>


                    <div class="col-12 informacion-text mt-5" [innerHTML]="datos.general_data">
                    </div>
                    <div class="col-12 mt-4">
                        <mat-tab-group (selectedTabChange)="tabChanged($event)" class="tab3" animationDuration="2000ms">
                            <mat-tab *ngFor="let d of datos.studyPlanItems" [label]="d.title">
                                <div [innerHTML]="d.html" class="html-body"></div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>

                <div class="row mt-5 p-3">
                    <div class=" title-section w-100" *ngIf="datos.faqs">
                        <div class="flex">
                            <h2 class=" inline pf">Preguntas frecuentes</h2>
                            <img onerror="this.src='./assets/img/default.png'" (click)="open()"
                                class='icon pointer float-right inline' id="arrow"
                                src="./assets/img/flecha-hacia-abajo.png" alt="">
                        </div>
                    </div>
                    <div class="vr mb-3 w-100"></div>

                    <div id="html">
                        <div *ngFor="let p of datos.faqs">
                            <div class="preguntas" *ngIf="p.estado">
                                <span class="pregunta"> {{p.ask}}</span><br>
                                <span class="respuesta"> {{p.answer}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pl-3  pr-3  ba">
                    <a href="https://uca.edu.ar/es/posgrado/terminos-y-condiciones" [style.margin-top]="'3em'">
                        Terminos
                        y condiciones</a>
                    <a [href]="datos.regret" class="btn btn-arrepentimiento">Boton de Arrepentimiento</a>
                </div>
            </div>
        </div>
    </div>

</section>