import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params,  } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-pre-universidad',
  templateUrl: './pre-universidad.component.html',
  styleUrls: ['./pre-universidad.component.scss']
})
export class PreUniversidadComponent implements OnInit {

  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: any[] = [];
  resultados: any[] = [];
  pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  career_title=""
  spinner = true;
  language = "es"
  info: any;
  textFilter: string;
  carrerasSinFiltro: any;
  fecha: string;
  region = "buenos-aires"
  constructor(private router: Router,
    public route: ActivatedRoute,
    private servicesGeneral: GeneralService,
  ) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });


    this.servicesGeneral.preUniversityGetAll().subscribe(data => {
      console.log(data)
      this.resultados = [];
      this.carreras = data.data[0].pre_universities_list;
      this.carreras.sort(function (a, b) {
        return (b.career_title - a.career_title)
      });
      let cant = Math.round(this.carreras.length / this.pageSize)
      for (let i = 0; i < cant; i++) {
        this.pages.push(i + 1);
      }
      this.inicio()

    })
    this.servicesGeneral.preUniversityGetAll().subscribe(data => {
      console.log(data)
      this.resultados = [];
      this.carreras = data.data[0].pre_universities_list;
      this.carrerasSinFiltro = data.data[0].pre_universities_list;
      this.carrerasSinFiltro.sort(function (a, b) {
        if (a.career_title > b.career_title) {
          return 1;
        }
        if (a.career_title < b.career_title) {
          return -1;
        }
        return 0;
      })
    })



  }


  ngOnInit(): void {
    let date = new Date();
    this.fecha = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
  }

  filtrar(event) {
    let text = this.stringEstandar(event.target.value);
    this.textFilter = text;
    this.cambiarPagina(1, text)
  }
  stringEstandar(string: String) {
    let estandar = string.toLocaleLowerCase();
    return estandar.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  filtrarSinPaginado(event) {
    let text = this.stringEstandar(event.target.value);
    this.textFilter = text;
    this.filtrarArray(text)
  }
  filtrarArray(text) {
    let preresultados = this.carreras.filter(f => this.stringEstandar(f.career_title).startsWith(text));
    this.carrerasSinFiltro = preresultados;
    this.carrerasSinFiltro.sort(function (a, b) {
      if (a.career_title > b.career_title) {
        return 1;
      }
      if (a.career_title < b.career_title) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  inicio() {
    this.spinner = true;
    this.servicesGeneral.preUniversityGetAll().subscribe(data => {
      this.resultados = data.data[0].pre_universities_list;
      this.carreras = data.data[0].pre_universities_list;
      this.carreras.sort(function (a, b) {
        if (a.career_title > b.career_title) {
          return 1;
        }
        if (a.career_title < b.career_title) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      this.resultados.sort(function (a, b) {
        if (a.career_title > b.career_title) {
          return 1;
        }
        if (a.career_title < b.career_title) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      this.cambiarPagina(1, null)

    })


  }
  cambiarPagina(page, text) {
    this.pages = [];
    if (text) {
      let preresultados = this.carreras.filter(f => this.stringEstandar(f.career_title).startsWith(text));
      this.resultados = preresultados.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
      let cant = Math.round(preresultados.length / this.pageSize)
      for (let i = 0; i < cant; i++) {
        this.pages.push(i + 1);
      }
    } else {
      this.resultados = this.carreras.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
      let cant = Math.round(this.carreras.length / this.pageSize)
      for (let i = 0; i < cant; i++) {
        this.pages.push(i + 1);
      }
    }


    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }

  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }

  verinfo(item) {
    if (item.observation) {
      let date, month, year;
      date = new Date().getDate();
      month = new Date().getMonth() + 1;
      year = new Date().getFullYear();

      if (date < 10) {
        date = '0' + date;
      }

      if (month < 10) {
        month = '0' + month;
      }

      date = date
        .toString()
        .padStart(2, '0');

      month = month
        .toString()
        .padStart(2, '0');
      item.observation = item.observation.replaceAll("DATE-NOW", " " + `${date}/${month}/${year}` + " ")
    }
    this.career_title = item.career_title
    this.info = item.preUniversity;
    console.log(this.info)
    var element = document.getElementById("card-info")
    element.scrollIntoView({ block: "center", behavior: "smooth" });
  }
}
