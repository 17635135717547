import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventosComponent } from './components/eventos/eventos.component';
import { Routes, RouterModule } from '@angular/router';
import { EventosDosComponent } from './pages/eventos-dos/eventos-dos.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasGeneralComponent } from './pages/noticias-general/noticias-general.component';
import { EventosNoticiasComponent } from './pages/eventos-noticias/eventos-noticias.component';
import { FacultadesInstitutosComponent } from '../general/components/facultades-institutos/facultades-institutos.component';
const routes: Routes = [
  {path: ':language/eventos/:id', component: EventosComponent,},
  {path: ':language/eventos/:id', component: EventosDosComponent,},
  {path: ':language/noticias/:id', component: NoticiasGeneralComponent,},
  {path: ':language/noticias/:type/:id', component: NoticiasComponent,},
  {path: ':language/noticias/:type/:id/:idContinuacion', component: NoticiasComponent,},
  {path: ':language/eventos-noticias/:id', component: EventosNoticiasComponent,},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})], // <-- debugging purposes only
  exports: [RouterModule]
})
export class EventosRoutingModule { }
