export class Carrera{
    [x: string]: any;
    id:number;
    nombre:string;
    link:string;
    fecha?:Date;
    modalidad?:string;
    dateForOrder?:Date;
    facultad?: string;
    duracion?: string;
    horarios?: string;
    inicio?: string;
    nivel?: string;
    boton_inscribite?: string;
    sede?: { id: number; title: string }[];
    tag?: string;
    color?: string;
    img?: string
    constructor(id:number, nombre:string,link:string,fecha?: Date,modalidad?:string,dateForOrder?:Date, facultad?: string, duracion?: string, horarios?: string, inicio?: string, nivel?: string, boton_inscribite?: string,   sede?: { id: number; title: string }[], tag?: string, color?: string,     img?: string
    ) {
        this.id = id;
        this.nombre = nombre;
        this.link = link;
        this.fecha = fecha;
        this.modalidad = modalidad;
        this.dateForOrder = dateForOrder;
        this.facultad = facultad;
        this.duracion = duracion;
        this.horarios = horarios;
        this.inicio = inicio;
        this.nivel = nivel;
        this.boton_inscribite = boton_inscribite;
        this.sede = sede;
        this.tag = tag;
        this.color = color;
        this.img = img
    }
}