<div style="text-align: -webkit-center;">
    <div class="container-bienvenida" >
        <div class="container-izq" *ngIf="isDesktop && data?.path_video">
            <video #videoPlayer style="width: 100%;" controls (play)="onVideoPlay()" (pause)="onVideoPause()"
                (ended)="onVideoEnded()">
                <source [src]="apiUrl+ data?.path_video" type="video/mp4">
                Tu navegador no soporta el elemento de video.

            </video>
            <button id="playButton" class="play-container" (click)="togglePlayPause()" *ngIf="!isPlaying">
                <i class="fas fa-play"></i>
            </button>

        </div>
        <div class="container-izq" *ngIf="isDesktop && data?.path">
            <img [src]="data?.path" alt="">


        </div>
        <div class="container-izq" *ngIf="!isDesktop">
            <img   [src]="apiUrl+data?.path_mobile" alt="">
        </div>
        <div class="img-container" [style.background]="data?.color_background">
            <div>
                <h2 [style.color]="data?.color_title" class="quicksand-medium">{{data?.title}}</h2>
            </div>
            <div class="qr-container">
                <img *ngIf="isDesktop" class="qr-img" [src]="apiUrl+data?.qr" alt="">
                <div class="qr-text">
                    <p *ngIf="isDesktop"  [style.color]='data?.color_subtitle' class="subtitle quicksand-medium">{{data?.subtitle}}</p>
                    <p *ngIf="isDesktop"  [style.color]='data?.text_color'   class="text quicksand-regular">{{data?.text}}</p>
                    <p *ngIf="!isDesktop" class="quicksand-medium">{{data?.btnText}}</p>
                    <a id="boton-qr" [style.background]="data?.color_btn_label" [href]="data?.btnRedirect" class="zoomIn boton-inscribite">
                        <span class="nunito-regular" (click)="trackEvent()">
                            {{data?.btnLabel}}
                        </span>
                    </a>
                </div>

            </div>


        </div>
    </div>
</div>