import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard2 } from 'src/app/shared/models/registroNoticia';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sobre-nosotros-posgrados',
  templateUrl: './sobre-nosotros-posgrados.component.html',
  styleUrls: ['./sobre-nosotros-posgrados.component.scss']
})
export class SobreNosotrosPosgradosComponent implements OnInit {

  @Input() id:string;
  @Input() title:string;
  Noticias:RegistroCard2[]=[];
  noticiaActive:string;
  constructor(private servicioGeneral:GeneralService,private aRouter:ActivatedRoute) { 
  }

  ngOnInit(): void {
    var url = window.location.href;
    var hostname = environment.frontUrl + '/facultades/';
    var id = url.replace(hostname, '');
    this.servicioGeneral.getEntitylist(this.id,id,false).subscribe(data=>{
      let lista =data.data[0]['entityList'][0].entity;
      this.noticiaActive=lista[0].id;
      lista.forEach(element => {
         let registro=new RegistroCard2();
         registro.title=element.title;
         registro.foto=element.path;
         registro.id=element.id;
         registro.categoria=element.categoryTitle;
         registro.link=element.path;
         if(registro.categoria){
          registro.systemTitle='es/noticias/'+element.typeEntity.toLowerCase()+'/'+element.systemTitle;
         }else{          
           registro.systemTitle='/es/'+element.systemTitle;
         }
         this.Noticias.push(registro);

      });


    })
  }
  cambiarSlider(i:number){
    this.noticiaActive=this.Noticias[i].id;

  }

}
