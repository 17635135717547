import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-posgrados-lista',
  templateUrl: './posgrados-lista.component.html',
  styleUrls: ['./posgrados-lista.component.scss']
})
export class PosgradosListaComponent implements OnInit {
  @Input() id :string;
  constructor(private services:GeneralService) { }
  posgrados:any[]=[];
  ngOnInit(): void {
    this.services.getEntitylist(this.id,'posgrado').subscribe(data=>{
        this.posgrados= data.data[0].entityList[0].entity;
    })
  }

}
