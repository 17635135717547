<section class="facsec2">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 [class]='styleTitle' *ngIf='!title'>Agenda </h2>
        <h2 [class]='styleTitle' *ngIf='title'>{{title}}</h2>
        <div class="agenda-lat desk">
          <div *ngFor='let item of Agenda' class="agenda-lat-item">
            <a class='pointer' [href]="item.link">
              <div class="fecha">{{item.date | date:'d/M/yy'}} <span>{{item.hours| date: 'h:mm a'}}</span></div>

              <div class="desc">{{item.title}}</div>
            </a>
          </div>
          <a *ngIf='boton=="true"' [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
          <a *ngIf='boton=="true" && botonFacultades=="true"' [href]="language+'/calendario/'+month+'/'+year+'/'+titleSistem" class="btn btn-verde blanco">{{botonTitle}} </a>
        </div>
        <div class="agenda-lat mob">
          <div id="agenda-s" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
            <ol class="carousel-indicators">
              <li  *ngFor='let item of Agenda;index as i '  data-target="agenda-s" (click)="cambiarSlider(i)" [ngClass]='(item.id== noticiaActive)?"active" : ""' ></li>
            
            </ol>
            <div class="carousel-inner">
              <div  *ngFor='let item of Agenda' class="carousel-item " [ngClass]='(item.id== noticiaActive)?"active" : ""'>
                  <div class="agenda-lat-item">
                    <a  class='pointer' [href]="item.link"  > 
        
                    <div class="fecha">{{item.date | date:'d/M/yy'}}  <span>{{item.hours| date: 'h:mm a'}}</span></div>
                    <div class="desc">{{item.title}}</div>
                    </a>
                  </div>
              
        
               
              </div>
            </div>
          </div>
          <a *ngIf='boton == "true"' [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
        </div>
      </div>
    </div>
  </div>
</section>