<div class=" container card-title">
    <div class="float-left ">
        <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-university"
            src="./assets/img/editar.png" alt="">
    </div>
    <div class=" title-section w-100">
        <h2 class="azul">Plan de estudio </h2>
    </div>
    <div class="vr mb-3"></div>

    <div class="row" style="justify-content: center">
        <div class="mt-5 col-lg-5" style="min-height:260px">
            <span class="lebel-red">TÍTULO</span>
            <p>{{titlesPlanData.title}}</p>
            <div class="mini-vr mb-3"> </div>
            <span class="lebel-red" *ngIf="titlesPlanData.intermediateTitle">TÍTULO INTERMEDIO</span>
            <p>{{titlesPlanData.intermediateTitle}}</p>
            <div class="buttom-section">
                <span *ngFor="let button of buttonStudyPlan;index as i">
                    <a *ngIf="i!=0 && button.state" (click)="open(button)"
                        class=" btn btn-primary button-red mr-3 mt-1">{{button.title}}</a>
                </span>
            </div>
          
        </div>
            <div class="vertical"></div>
        <div class="mt-5 col-lg-5 ">
            <div *ngFor="let item of planData.studyPlanItems;index as i" class="pointer div-plan" (click)="openClosePanel(item,i)">
                <span class="number-plan">{{i+1}}</span><span class="lebel-plan">{{item.title | uppercase }}</span>
                <img onerror="this.src='./assets/img/default.png'" class='icon-arrow icon arrow-down'
                    [id]="'arrowdown-'+i" src="./assets/img/chevron.png" alt="" >

                <img onerror="this.src='./assets/img/default.png'" class='icon-arrow icon arrow-up' [id]="'arrowup-'+i"
                    src="./assets/img/chevron2.png" alt="">
                <div class="vr-black"></div>
                <div [id]="'id-'+i" class="content-plan"></div>
            </div>

            <span class="lebel-date" (click)="openDate()" class="pointer" *ngIf="this.buttonStudyPlan[0] && this.buttonStudyPlan[0].state">
                <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-date"
                    src="./assets/img/calendar2.png" alt="">
                Días y horarios de cursada
               <!-- <img onerror="this.src='./assets/img/default.png'" class='icon-arrow-2 icon arrow-down' id="arrowdowndate"
                src="./assets/img/chevron.png" alt="" (click)="openDate()">
    
            <img onerror="this.src='./assets/img/default.png'" class='icon-arrow-2 icon arrow-up' id="arrowupdate"
                src="./assets/img/chevron2.png" alt="" (click)="closeDate()">-->
            </span>
            <div id="date" class="content-plan"></div>
        </div>
    </div>
  
</div>