import { Component, Input, OnInit } from '@angular/core';
import { RegistroCard2 } from 'src/app/shared/models/registroNoticia';

@Component({
  selector: 'app-informacion-posgrados',
  templateUrl: './informacion-posgrados.component.html',
  styleUrls: ['./informacion-posgrados.component.scss']
})
export class InformacionPosgradosComponent implements OnInit {
  Noticias: RegistroCard2[] = [];
  noticiaActive='1';
  @Input() currentRoute:string;
  @Input() autoridades:any;
  constructor() {
  }

  ngOnInit(): void {
    let registro = new RegistroCard2();
    registro.title = 'Plan de estudio';
    registro.foto = '../../../assets/img/plan.jpg';
    registro.id = '1';
    registro.systemTitle =this.currentRoute + '/plan-de-estudio';
    this.Noticias.push(registro)

    let registro2 = new RegistroCard2();
    registro2.title = 'Perfil de Título y alcance profesional';
    registro2.foto = '../../../assets/img/perfil.jpg';
    registro2.id = '2';
    registro2.systemTitle = this.currentRoute +'/perfil-del-titulo';
    this.Noticias.push(registro2)

    if (this.autoridades) {
      let registro3 = new RegistroCard2();
      registro3.title = 'Autoridades';
      registro3.foto = '../../../assets/img/autoridades.jpg';
      registro3.id = '3';
      registro3.systemTitle =this.currentRoute + '/autoridades';
      this.Noticias.push(registro3)
    }
    

  }
  cambiarSlider(i: number) {
    this.noticiaActive = this.Noticias[i].id;

  }


}
