<div id="home-slider" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <!-- [@enterState]='item.state'-->
        <div class="carousel-item active " *ngFor='let item of items' [id]='item.id'>
            <div [ngClass]='(item.state== "actived")?"actived" : "inactive"'>
                <img onerror="this.src='./assets/img/default.png'" class="img-desk w-100 desk" [src]="item.foto">
                <img onerror="this.src='./assets/img/default.png'" class="img-mob w-100 mob" [src]="item.fotoMovil">
              
            </div>
        </div>
    </div>
</div>

<a *ngIf="datos.button_signUp_state" [href]="datos.button_signUp" (click)="trackEvent()" class="btn-flotante">Inscribite >></a>