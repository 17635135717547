<section class="facsec2  ">
    <div class="container">
        <div class="row">

            <div class="col-lg-12" id='contacto'>
                <h2>Dejanos tus datos para recibir más información </h2>

                <form [formGroup]="formDatos">
                    <div class="cont-3">
                        <input type="text" formControlName='name' placeholder="*Nombre">
                        <div *ngIf="formDatos.get('name').hasError('required') && formDatos.get('name').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>

                        <input type="text" formControlName='email' placeholder="*Email">
                        <div *ngIf="formDatos.get('email').hasError('email') && formDatos.get('email').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo debe ser un email valido </span>
                        </div>
                        <div *ngIf="formDatos.get('email').hasError('required') && formDatos.get('email').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>
                    </div>
                    <div class="cont-3">

                        <select formControlName='region'>
                            <option disabled="" hidden="" select="" value="">* Elige la sede</option>
                            <option value="Buenos Aires">Buenos Aires</option>
                            <option value="Mendoza">Mendoza</option>
                            <option value="Paraná">Paraná</option>
                            <option value="Rosario">Rosario</option>
                        </select>
                        <div *ngIf="formDatos.get('region').hasError('required') && formDatos.get('region').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>
                    </div>
                    <ng-container *ngIf="tel">
                        <div class="cont-3">
                            <input formControlName='phone' placeholder="Telefono/Whatsapp " />
                            <div *ngIf="formDatos.get('phone').hasError('pattern') && formDatos.get('phone').touched"
                                class="text-center">
                                <span class="mda-form-msg">Este campo debe ser un número de telefono valido </span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="cont-3">
                        <textarea formControlName='message' placeholder="Mensaje"></textarea>
                    </div>
                    <div class="cont-3">
                       <button (click)='consultar()' [disabled]='formDatos.invalid' type="submit" class="btn btn-primary">ENVIAR</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>