
<div class="nuestras-sedes" *ngIf="data">
  
  <div class="sedes-container" [style.background-color]="data.backgroundColor">
    <h1 class="sedes-titulo quicksand-regular" [style.color]="data.titleColor">{{data.title}}</h1>
  </div>

  <div class="sede-container">
    <div id="sede" class="sede-d "  *ngFor="let item of sedes;index as i "
    #animatedSection  [ngClass]="{'spaceInUp':  shouldAnimate(i), ' hidden-animation': !isInView}">
      <div class="sede">
        <div class="sede-overlay" [routerLink]="item.redirec">
          <img *ngIf="item.imgStatic" src="./assets/img/{{item.imgStatic}}" alt="" class="img">
          <img *ngIf="item.img" src="{{apiUrl}}{{item.img}}" alt="" class="img">
          <div class="overlay-text">
            <p class="overlay-t quicksand-regular">{{item.title}}</p>
            <p class=" overlay-t text text-small nunito-medium">{{data.label}}</p>
            <a [routerLink]="item.redirec" class="info zoomIn" [style.background-color]="data.btnColor">
              <p [style.margin-top]="'0px!important'" class="  overlay-t  nunito-medium text-small">MÁS INFO >></p>
            </a>
          </div>

        </div>

      </div>

      <div class="direc nunito-regular ">
        <a [href]="redirecGoogleMaps(item.direccion)" [innerHTML]="getFormattedAddress(item.direccion)" target="_blank"
          style="color: #003269;" class="nunito-regular"></a>
        <!-- <p>{{item.direccion}}</p> -->
        <!-- <p>{{item.direccion2}}</p>
          <p>{{item.direccion3}}</p> -->
        <div class="container-contactanos">
          <div class='iconos'>
            <a id="redes" *ngIf="item.title !='Rosario'" style='cursor:pointer;color: #007bff;'
              (click)='windowOpen(item.facebook)'>
              <img onerror="this.src='./assets/img/default.png'" class='icon-redes'
                src="../../../../../assets/img/fb2.png" alt="">
            </a>
            <a id="redes" style='cursor:pointer;color: #007bff;' (click)='windowOpen(item.instagram)'>
              <img onerror="this.src='./assets/img/default.png'" class='icon-redes'
                src="../../../../../assets/img/ig2.png" alt="">
            </a>
            <a id="redes" class="wsp-mob" style='cursor:pointer;color: #007bff;' (click)='windowOpen(item.whatsapp)'>
              <img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/whsatsapp-blanco.png"
                alt="">
            </a>


          </div>
          <button class="zoomIn btn-green nunito-regular " (click)='windowOpen(item.whatsapp)'> CONTACTANOS
            <img onerror="this.src='./assets/img/default.png'" class='icon'
              src="../../../../../assets/img/whsatsapp-blanco.png" alt="">
          </button>

        </div>
      </div>
    </div>
  </div>

</div>