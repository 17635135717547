
<a  id="boton-area" *ngIf="page=='cursos'" [href]="'/es/'+item.link" [attr.data-name]="item.name"
    class="item-custom" [style.border-color]="item.color">
    <div style="display: flex; align-items: center;
             " class="area">
        <img *ngIf="svgContent" [src]="svgContent" class="img-c" />

        <img *ngIf="!svgContent" [src]="createLink(item.file)" alt="" class="img-c">
        <span *ngIf="item.name" class=" quicksand-regular item-txt">
            {{item.name}}
        </span>
    </div>
</a>

<a id="boton-area"   *ngIf="page=='posgrado'" [href]="'/es/'+item.systemTitle" class="item-custom"
    style="border-color: #9B8D62; background-color: #414141;">
    <div style="display: flex; align-items: center;" class="area">
        <img *ngIf="item.file" [src]="createLink(item.file)" alt="" class="img-c" alt="">
        <img *ngIf="item.icono" [src]="createLink(item.icono)" alt="" class="img-c" alt="">
        <p *ngIf="item.title" class="title quicksand-regular item-txt" [style.color]="'white'">
            {{item.title}}
        </p>
        <!-- <p *ngIf="item.name" class="title">
                {{item.name}}
            </p> -->
    </div>
</a>