import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormHubspotComponent } from 'src/app/shared/components/form-hubspot/form-hubspot.component';
import { FormContactoComponent } from 'src/app/modules/general/components/form-contacto/form-contacto.component';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.scss']
})
export class CursosComponent implements OnInit {

  botones = []
  pathMob = ''
  path = ''
  language: any;
  venue: any;
  carrer: any;
  datos: any;
  cuotas = [];
  sedeDeInteres = '';
  constructor(private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private service: GeneralService,
    private router: Router,
    public dialog: MatDialog) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    })
    this.route.params.forEach((params: Params) => {
      this.venue = params['venue'];
    })

    this.route.params.forEach((params: Params) => {
      this.carrer = params['carrer'];
    });
    this.service.getCarrerByTitle(this.carrer, this.venue, this.language).subscribe(res => {
      this.datos = res.data[0]
      this.datos.studyPlanItems?.forEach(d => {
        d.html = this.getSafeHtml(d.body);
      })
      if (this.datos.form_hubspot) {
        this.agregarParametro(res.data[0])
      }
      const safeIframeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.datos.general_data);
      this.datos.general_data = safeIframeHtml;
      this.datos.cuotas.forEach(c => {
        let valorCuota = (this.datos.arancel) / c.cant_cuotas;
        this.cuotas.push({ cant: c.cant_cuotas, interes: c.interes, valor: valorCuota })
      })
    })

  }

  ngOnInit(): void {
  }
  ngAfterViewChecked(): void {
    this.tabChanged(null)
  }
  open() {
    var status = document.getElementById("html").style.visibility;
    if (status == "hidden" || status == "") {
      document.getElementById("html").style.visibility = "visible";
      document.getElementById("html").style.height = "auto";

    } else {
      document.getElementById("html").style.visibility = "hidden";
      document.getElementById("html").style.height = "0px";

    }

  }
  tabChanged($event) {

    const bodys = document.querySelectorAll<HTMLElement>(".mat-tab-body-wrapper");
    for (let i = 0; bodys.length > i; i++) {
      bodys[i].style.borderTop = "1px solid " + this.datos.color;
      bodys[i].style.borderLeft = "1px solid " + this.datos.color;
      bodys[i].style.borderRight = "1px solid " + this.datos.color;
      bodys[i].style.borderBottom = "1px solid " + this.datos.color;
      bodys[i].style.background = 'white';

    }

    const headerLabels = document.querySelectorAll<HTMLElement>(".mat-tab-label");
    for (let i = 0; headerLabels.length > i; i++) {
      headerLabels[i].style.color = "white";
      headerLabels[i].style.borderTop = "1px solid " + this.datos.color;
      headerLabels[i].style.borderLeft = "1px solid " + this.datos.color;
      headerLabels[i].style.borderRight = "1px solid " + this.datos.color;
      headerLabels[i].style.borderBottom = "1px solid " + this.datos.color;
      headerLabels[i].style.background = this.datos.color;
    }

    const headerLabel = document.querySelectorAll<HTMLElement>(".mat-tab-label-active")[0];
    if (headerLabel) {
      headerLabel.style.color = this.datos.color;
      headerLabel.style.borderTop = "1px solid " + this.datos.color;
      headerLabel.style.borderLeft = "1px solid " + this.datos.color;
      headerLabel.style.borderRight = "1px solid " + this.datos.color;
      headerLabel.style.borderBottom = "1px solid " + this.datos.color;
      headerLabel.style.background = 'white';
    }

  }
  openModalConsulta() {
    if (this.datos.form_hubspot) {
      this.dialog.open(FormHubspotComponent);
    } else {
      localStorage.setItem('curso', this.carrer)
      let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container' });
      let instance = dialogRef.componentInstance;
      this.venue = this.venue
      this.carrer = this.carrer
      const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }
  getSafeHtml(body) {
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }
  agregarParametro(data) {
    let regionFormateada = '';
    let region = '';
    let areaFormateada = '';
    let area = '';
    console.log(data)
    if (data.region) {
      region = (data.region[0].title)
      regionFormateada = this.quitarTildes(data.region[0].title.toLowerCase().replace(/\s+/g, '_'));
    }
    if (data.area) {
      area = data.area.name;
      areaFormateada = this.quitarTildes(data.area.name.toLowerCase().replace(/\s+/g, '_'));
    }
    const queryParams: { [key: string]: any } = {};
    queryParams[`sede_de_interes_posgrado`] = region;
    queryParams[`area_de_interes`] = area;
    queryParams[`carrera_de_posgrado__${areaFormateada}_`] = data.title;


    console.log(queryParams)
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  quitarTildes(texto: string) {
    const tildes = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      ü: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      Ü: 'U',
    };

    return texto.replace(/[áéíóúüÁÉÍÓÚÜ]/g, letra => tildes[letra]);
  }
}
