<h2>Agenda</h2>
<div class="agenda-lat desk">
  <div *ngFor='let item of Agenda' class="agenda-lat-item">
    <a  class='pointer' [href]="item.link"  > 
    <div class="fecha">{{item.date | date:'d/M/yy'}}  <span>{{item.hours| date: 'h:mm a'}}</span></div>
 
   <div class="desc">{{item.title}}</div>
  </a>
    </div>
  <a href="" class="btn btn-verde blanco">Ver agenda completa</a>
</div>
<div class="agenda-lat mob">
  <div id="agenda-s" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
    <ol class="carousel-indicators">
      <li  *ngFor='let item of Agenda;index as i '  data-target="agenda-s" (click)="cambiarSlider(i)" [ngClass]='(item.id== noticiaActive)?"active" : ""' ></li>
    
    </ol>
    <div class="carousel-inner">
      <div  *ngFor='let item of Agenda' class="carousel-item " [ngClass]='(item.id== noticiaActive)?"active" : ""'>
          <div class="agenda-lat-item">
            <a  class='pointer' [href]="item.link"  > 
       
            <div class="fecha">{{item.date | date:'d/M/yy'}}  <span>{{item.hours| date: 'h:mm a'}}</span></div>
            <div class="desc">{{item.title}}</div>
            </a>
          </div>
      

       
      </div>

    </div>
  </div>
  <a href="" class="btn btn-verde blanco">Ver agenda completa</a>
</div>
