import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard } from '../../models/registroComunidad';

@Component({
  selector: 'app-sumate-a-nuestra-comunidad',
  templateUrl: './sumate-a-nuestra-comunidad.component.html',
  styleUrls: ['./sumate-a-nuestra-comunidad.component.scss']
})
export class SumateANuestraComunidadComponent implements OnInit {

  linkFacebook:string;
  linkInstagram:string;
  linkYoutube:string;
  linnkIn:string;
  ListaSeguinos:RegistroCard[]=[];
  Active:number;
  constructor(private services:GeneralService) { 
    this.services.getSocialnetwork('1').subscribe(data=>{
       this.Active=1;
       let index=1;
       data.data[0].socialNetwork.socialNetworkItem.forEach(element => {

        let registro= new RegistroCard();
        registro.redSocialIcon='./assets/img/'+element.socialNetworkName+'3.jpg';
        registro.titulo=element.title;
        registro.img=element.path;
        registro.link=element.link;
        registro.id=index;
        this.ListaSeguinos.push(registro);
        index++;

     });
    })
  }

  ngOnInit(): void {
  }
  cambiarSlider(i:number){
    this.Active=this.ListaSeguinos[i].id;

  }

}
