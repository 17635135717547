import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AgendaService } from 'src/app/core/services/agenda/agenda.service';
import { RegistroAgenda } from 'src/app/shared/models/registroAgenda';

@Component({
  selector: 'app-custom-diary-general',
  templateUrl: './custom-diary-general.component.html',
  styleUrls: ['./custom-diary-general.component.scss']
})
export class CustomDiaryGeneralComponent implements OnInit {
  @Input() data: any;
  @Input() title: any;
  @Input() boton = 'true';
  @Input() botonFacultades = 'true';
  @Input() botonTitle = "Eventos relacionados";
  titleSistem: any;
  Agenda: RegistroAgenda[] = [];
  noticiaActive: string;
  language: any;
  month: any;
  year: any;
  @Input() background = '#003567';
  styleTitle = 'azul';
  constructor(public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleSistem = (params['venue'])? params['venue'] : (params['title']) ? params['title'] : "";
    });
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.noticiaActive = "1"
    var index=1;
    this.data.forEach(element => {
      let registro = new RegistroAgenda();
      if (element.event) {
        element = element.event;
        element.title = element.eventTitle
      }
      registro.title = element.title;
      registro.date = element.startOn.date;
      registro.hours = element.startOn.date;
      registro.id = index.toString();
      registro.link = this.language + '/eventos/' + element.systemTitle
      this.Agenda.push(registro);
      index++;
    });
  }

  cambiarSlider(i: number) {
    this.noticiaActive = this.Agenda[i].id;

  }

}
