import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplePageComponent } from './pages/simple-page/simple-page.component';
import { RouterModule } from '@angular/router';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { SlideGenericPageComponent } from './pages/slide-generic-page/slide-generic-page.component';
import { ContentGenericPageComponent } from './pages/content-generic-page/content-generic-page.component';
import { CarrouselGeneralComponent } from './components/carrousel-general/carrousel-general.component';
import { CardsGeneralComponent } from './components/cards-general/cards-general.component';
import { CarrouselImgComponent } from './components/carrousel-img/carrousel-img.component';
import { IngresoComponent } from './pages/ingreso/ingreso.component';
import { CardsGeneralDosComponent } from './components/cards-general-dos/cards-general-dos.component';
import { PosgradosComponent } from './pages/posgrados/posgrados.component';
import { ListaDeLinksComponent } from './components/lista-de-links/lista-de-links.component';
import { VideoComponent } from './components/video/video.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { GeneralSimpleComponent } from './pages/general-simple/general-simple.component';
import { GeneralGalleryComponent } from './pages/general-gallery/general-gallery.component';
import { GeneralLandingComponent } from './pages/general-landing/general-landing.component';
import { TextItemGeneralComponent } from './components/text-item-general/text-item-general.component';
import { CabeceraComponent } from './components/cabecera/cabecera.component';
import { HomeComponent } from './pages/home/home.component';
import { SliderGeneralComponent } from './components/slider-general/slider-general.component';
import { CustomDiaryGeneralComponent } from './components/custom-diary-general/custom-diary-general.component';
import { FormContactoComponent } from './components/form-contacto/form-contacto.component';
import { CustomStatsGeneralComponent } from './components/custom-stats-general/custom-stats-general.component';
import { AlleventsComponent } from './pages/allevents/allevents.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarCheckComponent } from './components/calendar-check/calendar-check.component';
import { PostComponent } from './components/post/post.component';
import { PrimeraFotoDestacadaComponent } from './components/primera-foto-destacada/primera-foto-destacada.component';
import { FacultadesInstitutosComponent } from './components/facultades-institutos/facultades-institutos.component';
import { PosgradosGeneralUnoComponent } from './pages/posgrados-general-uno/posgrados-general-uno.component';
import { InformacionPosgradosComponent } from './components/informacion-posgrados/informacion-posgrados.component';
import { VideoMasFormularioComponent } from './components/video-mas-formulario/video-mas-formulario.component';
import { CarrerasDeGradoComponent } from './pages/carreras-de-grado/carreras-de-grado.component';
import { PosgradosGeneralDosComponent } from './pages/posgrados-general-dos/posgrados-general-dos.component';
import { VideoMasFormularioDosComponent } from './components/video-mas-formulario-dos/video-mas-formulario-dos.component';
import { FacultadesComponent } from './pages/facultades/facultades.component';
import { ListDesplegableComponent } from './components/list-desplegable/list-desplegable.component';
import { HeaderSectionComponent } from './components/header-section/header-section.component';
import { TodasLasCarrerasComponent } from './components/todas-las-carreras/todas-las-carreras.component';
import { IconsComponent } from './components/icons/icons.component';
import { FormasDeContactoComponent } from './components/formas-de-contacto/formas-de-contacto.component';
import { CursosProximosComponent } from './components/cursos-proximos/cursos-proximos.component';
import { CursosDeExtensionComponent } from './components/cursos-de-extension/cursos-de-extension.component';
/* import { AreasDeConocimientoComponent } from '../../shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
 */import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderLandingComponent } from './components/header-landing/header-landing.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { AutoridadesComponent } from './pages/autoridades/autoridades.component';
import { NotFountComponent } from './pages/not-fount/not-fount.component';
import { PorqueEstudiarEnUcaComponent } from './components/porque-estudiar-en-uca/porque-estudiar-en-uca.component';
import { PlanDeEstudioComponent } from './components/plan-de-estudio/plan-de-estudio.component';
import { PerfilProfesionalComponent } from './components/perfil-profesional/perfil-profesional.component';
import { SliderPosgradosComponent } from './components/slider-posgrados/slider-posgrados.component';
import { InfoPosgradoCardComponent } from './components/info-posgrado-card/info-posgrado-card.component';
import { InfoColumnPosgradoComponent } from './components/info-column-posgrado/info-column-posgrado.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { CursosListadoComponent } from './pages/cursos-listado/cursos-listado.component';
import { BuscadorCursosComponent } from './components/buscador-cursos/buscador-cursos.component';
import { ResultadosCursosComponent } from './components/resultados-cursos/resultados-cursos.component';
import { PreUniversidadComponent } from './pages/pre-universidad/pre-universidad.component';
import { NuestrasSedesComponent } from './components/nuestras-sedes/nuestras-sedes.component';
import { ItemsIngresoComponent } from './components/items-ingreso/items-ingreso.component';
import { BienvenidaComponent } from './components/bienvenida/bienvenida.component';
import { PosgradosModule } from '../posgrados/posgrados.module';
import { CardsPosgradosComponent } from './components/cards-posgrados/cards-posgrados.component';
import { SedesIngresoComponent } from './pages/sedes-ingreso/sedes-ingreso.component';
import { SedesPosgradosComponent } from './pages/sedes-posgrados/sedes-posgrados.component';
import { BuscadorPosgradosComponent } from './components/buscador-posgrados/buscador-posgrados.component';
import { BuscadorCarrerasComponent } from './components/buscador-carreras/buscador-carreras.component';

const ENTRYCOMPONENTS = [
  CarrouselGeneralComponent,
  CardsGeneralComponent,
  CardsGeneralDosComponent,
  CarrouselGeneralComponent,
  CarrouselImgComponent,
  ListaDeLinksComponent,
  ContactoComponent,
  TextItemGeneralComponent,
  CabeceraComponent,
  SliderGeneralComponent,
  CustomDiaryGeneralComponent,
  FormContactoComponent,
  CustomStatsGeneralComponent,
  PostComponent,
  PrimeraFotoDestacadaComponent,
  VideoMasFormularioComponent,
  CarrerasDeGradoComponent,
  HeaderSectionComponent,
  GeneralSimpleComponent,
  GeneralGalleryComponent,
  GeneralLandingComponent,
  HeaderLandingComponent,
  ListDesplegableComponent

];
@NgModule({
  declarations: [
    ENTRYCOMPONENTS,
    SimplePageComponent,
    SlideGenericPageComponent,
    ContentGenericPageComponent,
    CarrouselImgComponent,
    IngresoComponent,
    BuscadorCarrerasComponent,
    BuscadorPosgradosComponent,
    PosgradosComponent,
    VideoComponent,
    ContactoComponent,
    GeneralSimpleComponent,
    GeneralGalleryComponent,
    GeneralLandingComponent,
    HomeComponent,
    AlleventsComponent,
    CalendarComponent,
    CalendarCheckComponent,
    FacultadesInstitutosComponent,
    PosgradosGeneralUnoComponent,
    InformacionPosgradosComponent,
    PosgradosGeneralDosComponent,
    VideoMasFormularioDosComponent,
    FacultadesComponent,
    ListDesplegableComponent,
    TodasLasCarrerasComponent,
    IconsComponent,
    FormasDeContactoComponent,
    CursosProximosComponent,
    CursosDeExtensionComponent,
    RedirectComponent,
    AutoridadesComponent,
    NotFountComponent,
    PorqueEstudiarEnUcaComponent,
    PlanDeEstudioComponent,
    PerfilProfesionalComponent,
    SliderPosgradosComponent,
    InfoPosgradoCardComponent,
    InfoColumnPosgradoComponent,
    CursosComponent,
    CursosListadoComponent,
    BuscadorCursosComponent,
    ResultadosCursosComponent,
    PreUniversidadComponent,
    NuestrasSedesComponent,
    ItemsIngresoComponent,
    BienvenidaComponent,
    CardsPosgradosComponent,
    SedesIngresoComponent,
    SedesPosgradosComponent,
    
  ],
  imports: [BrowserAnimationsModule,IvyCarouselModule, CommonModule, SharedModule, RouterModule, AngularMaterialModule,],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GeneralModule { }
