import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';
import { RegistroAgenda } from 'src/app/shared/models/registroAgenda';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eventos-noticias',
  templateUrl: './eventos-noticias.component.html',
  styleUrls: ['./eventos-noticias.component.scss']
})
export class EventosNoticiasComponent implements OnInit {


  noticiaActive:string;
  datos:any;
  evento=false;
  noticia=false;
  language: any;
  month:any;
  year:any;
  constructor(private aRouter:ActivatedRoute,private services:GeneralService,private redirectService:RedirectService) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.month=new Date().getMonth()+1;
    this.year=new Date().getFullYear();
    var url = window.location.href;
    var hostname = environment.frontUrl + '/eventos-noticias/';
    var id = url.replace(hostname, '');  
    this.services.getAgendaDetalle(id).subscribe(data=>{
          if(data.data){
            this.datos=data.data[0];
            this.evento=true;
            document.getElementById('body').innerHTML=this.datos.body;
            this.noticiaActive=this.datos.otherEvent[0].id;
            this.evento=true;
            this.datos.otherEvent.forEach(element => {
               let registro=new RegistroAgenda();
               registro.title=element.title;
               registro.date=element.startOn.date;
               registro.hours=element.startOn.date;
               registro.id=element.id;
               registro.link='/eventos/'+element.systemTitle
               this.datos.push(registro);
     
              });
          }else{
            this.redirectService.toHome();
          }
        
      }) 

      this.services.getNoticiasDetalle(this.aRouter.snapshot.params['id'],'post').subscribe(data=>{
        if(data.data){
          this.datos=data.data[0];
          this.noticia=true;
          document.getElementById('body').innerHTML=this.datos.body;

        }
       
    }) 
   } 

  ngOnInit(): void {
  }
  cambiarSlider(i:number){
    this.noticiaActive=this.datos[i].id;

  }
}
