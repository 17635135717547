import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-facultades-institutos',
  templateUrl: './facultades-institutos.component.html',
  styleUrls: ['./facultades-institutos.component.scss']
})
export class FacultadesInstitutosComponent implements OnInit {
  datos: any;
  items: any[] = [];
  language: any;
  constructor(private services: GeneralService, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    var url = location.href;
    let languageVar="/" + this.language + "/";
    var indexL = url.indexOf(languageVar) + languageVar.length ;
    var id = url.slice(indexL, url.length)
    this.services.getSimplepage(id, this.language).subscribe(data => {
      this.datos = data.data[0];
      this.items = this.datos.links;
    })
  }

  ngOnInit(): void {
  }
  abrirPanel(i) {
    if ($('#collapse' + i).hasClass('show')) {
      $('.show').addClass('show');
      $('#collapse' + i).removeClass('show');
    } else {
      $('.show').removeClass('show');
      $('#collapse' + i).addClass('show');
    }


  }
}
