<section class="subheader">
    <img onerror="this.src='./assets/img/default.png'" class="w-100" src="../../../../../assets/img/posgrados.jpg" >
    <div class="texto-s container texto-s-center">
      <h1 class="azul">Posgrados</h1>
      <a href="/posgrados/elegi-tu-posgrado" class="btn btn-primary">ELEGÍ TU POSGRADO</a>
    </div>      
  </section>
  
  <section class="possec1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <app-posgrados-lista *ngIf='idListaPosgrados' [id]='idListaPosgrados'></app-posgrados-lista>
        </div>
      </div>
    </div>
  </section>

  <section class="possec2">
         <app-formacion-continuia *ngIf='idFormacionContinua' [id]='idFormacionContinua'></app-formacion-continuia>
    
  </section>
  <section class="possec3" >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
         <app-buscador-posgrados id='elegi-tu-posgrado'></app-buscador-posgrados>
        </div>
      </div>
    </div>
  </section>


  <section class="possec4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <app-cards-general-posgrados preURL='es/noticias' title='Novedades' *ngIf='idNovedades' [id]='idNovedades'></app-cards-general-posgrados>
        </div>
      </div>
    </div>
  </section>
