<div style="text-align: -webkit-center;">
  <div class="buscador-container">

    <h1 class="titulo-buscador quicksand-medium" *ngIf="data.title" [style.color]="data.color_title">{{data.title}}</h1>
    <h5 class="subtitulo-buscador quicksand-medium" *ngIf="data.subtitle" [style.color]="data.color_subtitle">
      {{data.subtitle}} </h5>

    <form  [formGroup]="buscador" class="form-group" id="busqueda"
      style='margin-bottom:0px;justify-content: space-between;'>

      <div style="display: flex;
            flex-wrap: wrap; width: 100%; justify-content: space-between;" class="container-responsive">

        <div class="input-container">
          <input #filtroInput autofocus type="text" formControlName='palabras' (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar() : null"
            class="form-control-c nunito-medium " placeholder="Palabras clave" style="border-radius: 0px !important;">
          <img class="buscar" src="../../../../../assets/img/buscar-gris.png" alt="">
        </div>

        <div class="input-container">
          <app-custom-select [options]="categorias" placeholder="Elegí la categoría" placeholderAll="Todas las categorías"
            (selectionChange)="updateFilter('categoria', $event)" [ingreso]="true" [curso]="true">
          </app-custom-select>
        </div>

        <div class="input-container">
          <app-custom-select [options]="niveles" placeholder="Elegí el nivel" placeholderAll="Todos los niveles"
            (selectionChange)="updateFilter('nivel', $event)" [ingreso]="true" [curso]="true">
          </app-custom-select>
        </div>


        <div *ngIf="mostrarSede" class="input-container">
          <app-custom-select [options]="regiones" placeholder="Elegí tu sede"  placeholderAll="Todas las sedes" (selectionChange)="updateFilter('sede', $event)"
            [ingreso]="true" [curso]="true">
          </app-custom-select>
        </div>



        <div class="input-container">
          <app-custom-select [options]="modalidades" placeholder="Modalidad" placeholderAll="Todas las modalidades"
            (selectionChange)="updateFilter('modalidad', $event)" [ingreso]="true" [curso]="true">
          </app-custom-select>
        </div>


      </div>



    </form>

  </div>

  <div class="resultados card" id="resultados">
    <div class="nunito-medium redirect"> <a [routerLink]="'/' + language +'/educacion-continua'" class="bc">
        {{titulo}} | </a>
      <span [style.margin-left]="'5px'" class="nunito-bold">{{area.name}}</span>
    </div>
    <ul *ngIf='!spinner' style="    padding-inline-start: 0px !important;">
      <div class="container-resultados-cursos">
        <div *ngFor="let carrera of resultados">
          <app-card-overlay2 [carrera]="carrera" 
           ></app-card-overlay2>
        </div>

      </div>

    </ul>
    <div class="d-flex justify-content-center spinner" *ngIf='spinner '>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="paginacion " *ngIf='!spinner && resultados.length > 0 && visiblePages2.length>1'>
      <ng-container *ngFor="let page of visiblePages2">
        <div class="number" [ngClass]='(numberActive == page) ? "active" : ""' *ngIf="page !== '...'"
          (click)='cambiarPagina(page)'>
          {{ page }}
        </div>
        <div class="dots" *ngIf="page === '...'">
          {{ page }}
        </div>
      </ng-container>
    </div>

    <div *ngIf=' resultados.length == 0 && !spinner' >
        <app-areas-recomendadas [infoAreaRecomendada]="infoAreaRecomendada"></app-areas-recomendadas>
    </div>
  </div>
</div>