import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../../core/authentication/auth.service';
import { RedirectService } from '../../../../core/services/redirect/redirect.service';
import { Menu, MenuItem } from '../../../../shared/models/menu';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  public menu: Menu = new Menu();
  public username: string;
  public loaded = false;

  constructor(private router: Router, private authService: AuthService, private redirectService: RedirectService) {
  }

  ngOnInit() {

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url.includes("/home")) {
          var script1 = document.createElement('script');
          var script2 = document.createElement('script');
          script1.type = 'text/javascript';
          script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZHK4MF826R';
          script2.textContent = "window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-ZHK4MF826R');"
          document.head.appendChild(script1);
          document.head.appendChild(script2);
        } else {
          var script1 = document.createElement('script');
          var script2 = document.createElement('script');
          script1.type = 'text/javascript';
          script1.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-5B8MMPV';
          script2.textContent = "(function (w, d, s, l, i) {" +
            "  w[l] = w[l] || []; w[l].push({" +
            "      'gtm.start':" +
            "        new Date().getTime(), event: 'gtm.js'" +
            "  }); var f = d.getElementsByTagName(s)[0]," +
            "     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =" +
            "         'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);" +
            "  })(window, document, 'script', 'dataLayer', 'GTM-5B8MMPV');"
          document.head.appendChild(script1);
          document.head.appendChild(script2);
        }

      }
    });
  }

  public isAuthenticated() {
    return this.authService.isLoggedIn();
  }

  public logout() {
    // TODO:descomentar cuando este el back
    /*this.authService.logout().subscribe(success => {
      if (success) {
        localStorage.clear();
        this.loaded = false;
        this.menu.items = [];*/
    this.redirectService.toLogin();
    /*}
  });*/
  }



  ngOnDestroy() {
    this.loaded = false;
    this.menu.items = [];
  }
}
