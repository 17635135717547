<form [formGroup]="formDatos">
  <div class="cont-3">
    <input type="text" formControlName='name' placeholder="*Nombre">
    <div *ngIf="formDatos.get('name').hasError('required') && formDatos.get('name').touched" class="text-center">
      <span class="mda-form-msg">Este campo es requerido </span>
    </div>
    <input type="text" formControlName='email' placeholder="*Email">
    <div *ngIf="formDatos.get('email').hasError('email') && formDatos.get('email').touched" class="text-center">
      <span class="mda-form-msg">Este campo debe ser un email valido </span>
    </div>
    <div *ngIf="formDatos.get('email').hasError('required') && formDatos.get('email').touched" class="text-center">
      <span class="mda-form-msg">Este campo es requerido </span>
    </div>
  </div>
  <div class="cont-3">

    <select formControlName='region'>
      <option disabled="" hidden="" select="" value="">* Elige la sede</option>
      <option value="Buenos Aires">Buenos Aires</option>
      <option value="Mendoza">Mendoza</option>
      <option value="Paraná">Paraná</option>
      <option value="Rosario">Rosario</option>
    </select>
    <div *ngIf="formDatos.get('region').hasError('required') && formDatos.get('region').touched" class="text-center">
      <span class="mda-form-msg">Este campo es requerido </span>
    </div>
  </div>

  <div class="cont-3">
    <textarea formControlName='message' placeholder="Mensaje"></textarea>
  </div>
  <div class="cont-3">
    <button (click)='consultar()' [disabled]='formDatos.invalid' type="submit" class="btn btn-primary">ENVIAR</button>
  </div>
</form>