import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-areas-recomendadas',
  templateUrl: './areas-recomendadas.component.html',
  styleUrls: ['./areas-recomendadas.component.scss']
})
export class AreasRecomendadasComponent implements OnInit {
  @Input() infoAreaRecomendada;

  constructor() { }

  ngOnInit(): void {
  }
  formatTitle(title: string): string[] {
    return title.split('.').map(part => part.trim()).filter(part => part.length > 0).map(part => part + '.');
  }
}
