<div>

</div>
<div class="container" [ngClass]="{ 'fondo-gris': !ingreso }">
    <div class="item-container">
        <ng-container *ngFor="let item of destacadas">
            <div [id]="'destacado-'+item.id" class="item" *ngIf="item.destacada && item.estado">
                <div>
                    <img [src]="item.path" onerror="this.src='./assets/img/none.png'" class='img-icon '
                        alt="icono beneficio">
                </div>
                <div class="text-container">
                    <h2 class="quicksand-regular">{{item.label}}</h2>
                </div>
                <div class="btn-container">
                    <button class="more-info-btn nunito-regular "><a [href]="item.link">MÁS INFO</a></button>
                </div>

            </div>

        </ng-container>
    </div>

</div>
<div class="container-f" *ngIf="ingreso">
    <div class="subcontainer-f">
        <div *ngFor="let item of noDestacadas">
            <div class="item-f" *ngIf="!item.destacada && item.estado" (click)="redirect(item.link)">
                <div>
                    <img [src]="item.path" onerror="this.src='./assets/img/none.png'" class='img-icon-f '
                        alt="icono beneficio">
                </div>
                <div class="text-container-f quicksand-regular">
                    {{item.label}}
                </div>

            </div>

        </div>
    </div>
</div>