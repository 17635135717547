import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';


import { BuscadorPosgradosComponent } from 'src/app/modules/posgrados/components/buscador-posgrados/buscador-posgrados.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/widgets/areas-de-conocimiento/areas-de-conocimiento.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/widgets/formas-de-contacto/formas-de-contacto-dos.component';
import { NuestrasSedesComponent } from 'src/app/shared/widgets/nuestras-sedes/nuestras-sedes.component';
import { PortadaComponent } from 'src/app/shared/widgets/portada/portada.component';
import { TextItemGeneralComponent } from 'src/app/shared/widgets/text-item-general/text-item-general.component';
import { SliderComponent } from 'src/app/shared/widgets/slider/slider.component';
import { BuscadorPosgradosTematicoComponent } from 'src/app/shared/components/buscador-posgrados-tematico/buscador-posgrados-tematico.component';

@Component({
  selector: 'app-posgrados',
  templateUrl: './posgrados.component.html',
  styleUrls: ['./posgrados.component.scss']
})
export class PosgradosComponent implements OnInit {
  language: string;

  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  id: any;
  id_component: string;
  apiUrl: any;



  constructor(private componentFactoryResolver: ComponentFactoryResolver, public activatedRoute: ActivatedRoute, public router: Router, public meta: Meta,
    public route: ActivatedRoute,
    public services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.language = (this.activatedRoute.snapshot.params['language']) ? this.activatedRoute.snapshot.params['language'] : 'es';
    this.id_component = this.activatedRoute.snapshot.params['id'];
    let url = window.location.href;
    if (url.includes('#')) {
      let l = (this.activatedRoute.snapshot.params['language']) ? "/" + this.activatedRoute.snapshot.params['language'] : 'es';
      let index = url.indexOf("#")
      let palabra = url.slice((index + 1), url.length)
      window.location.href = "/" + l + "/busqueda/posgrados#" + palabra;
    }

  }
  sede: any;
  area: any;
  ngAfterViewInit() {
    let relativeUrl = this.router.url.split('?')[0]; // Elimina los parámetros de la consulta
    const language = this.activatedRoute.snapshot.params['language'];
    if (language) {
      relativeUrl = relativeUrl.replace(`/${language}/`, '/').replace(`/${language}`, '/');
    }
    this.id = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;

    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      this.area = res.data[0].area_posgrado;
      this.sede = res.data[0].region;
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, data1)
          });
        }
      });
    })



  }
  ngOnInit(): void {
  }
  SortArray(x, y) {
    if (x.title < y.title) { return -1; }
    if (x.title > y.title) { return 1; }
    return 0;
  }


  refBuscador;
  newComponent(index: number, type: string, info: any) {
    //console.log("----" + type + "----")
    //console.log(info)
    switch (type) {
      case "PortadaSedes": {
        // Configuración para "PortadaSedes"
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0];
        ref.instance.area = this.area;
        ref.instance.marginTitle = 40;
        ref.instance.heightDesk = "525px";
        ref.instance.heightMob = "370px";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

        break;
      }

      case "PortadaAreas": {
        // Configuración para "PortadaAreas"
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0];
        ref.instance.area = this.area;
        ref.instance.marginTitle = 75; // Valor ajustado para áreas
        ref.instance.heightDesk = "200px";
        ref.instance.heightMob = "250px";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

        break;
      }

      case "Portada": {
        // Configuración para "Portada" (por defecto)
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0];
        ref.instance.area = this.area;
        ref.instance.marginTitle = 75;
        ref.instance.heightDesk = "";
        ref.instance.heightMob = "";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

        break;
      }

      /*case 'Buscador': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = 110;
        this.refBuscador.instance.padding = "30px 38.6px 30px 38.6px";
        this.refBuscador.changeDetectorRef.detectChanges();
      }
        break;*/
      case 'Buscador': {
        let refBuscador;
        if (this.area) {
          let factory1 = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosTematicoComponent);
          refBuscador = (this.viewContainerRefA(index)).createComponent(factory1);
        } else {
          let factory2 = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosComponent);
          refBuscador = (this.viewContainerRefA(index)).createComponent(factory2);
        }

        this.refBuscador = refBuscador;
        this.refBuscador.instance.area = this.area;
        this.refBuscador.instance.sede = this.sede;
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = (this.sede) ? "23%" : (this.area) ? "70%" : "52%";
        this.refBuscador.instance.padding = "30px 35.6px 30px 35.6px";

        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;
      case "AreaPosgrado": {

        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        const applyStyles = (areas: any[]) => {
          areas?.forEach(e => {
            e.backgroundColor = "#414141";
            e.colorName = "white";
          });
        };
        applyStyles(info.data[0].areas_tematicas_not_check_school);
        applyStyles(info.data[0].areas_tematicas_check_school);
        ref.instance.data = info.data[0];

      }
        break;
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data;
        ref.instance.esCurso = false
        ref.instance.color = 'gris'

      }
        break;
      case "Headquarters": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(NuestrasSedesComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0].headquarters;
        ref.instance.sedes = info.data[0].sedes;
      }
        break
      case "ImageSlider": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(SliderComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.instance.ingreso = false;
        ref.instance.marginDesk = info.data[0].image.margin_desktop;
        ref.instance.marginMob = info.data[0].image.margin_mobile;
        ref.instance.images = info.data[0].image[0];
      }
        break
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break

    }






  }


}
