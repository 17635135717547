<section class="subheader " >
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="path">
    <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="pathMob">
    <div class="texto-s container">
        <h1 class="azul">{{title}}</h1>
        <carousel class='desk' *ngIf='itemCarrouselHeader.length>0' style='height: 130px;' cellsToShow='3' loop='true'>
            <div class="carousel-cell " *ngFor="let item of  itemCarrouselHeader;">
                <div class='container-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </carousel>

        <carousel class='mob' *ngIf='itemCarrouselHeader.length>0' style='height: 130px;' cellsToShow='3' loop='true'>
            <div class="carousel-cell " *ngFor="let item of  itemCarrouselHeader;">
                <div class='container-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </carousel>
    </div>
</section>

<ng-container #componenteDinamico2></ng-container>
<ng-container #componenteDinamico3></ng-container>
<ng-container #componenteDinamico4></ng-container>
<ng-container #componenteDinamico5></ng-container>
<ng-container #componenteDinamico6></ng-container>
<ng-container #componenteDinamico7></ng-container>
<ng-container #componenteDinamico8></ng-container>
<ng-container #componenteDinamico9></ng-container>
<ng-container #componenteDinamico10></ng-container>
<ng-container #componenteDinamico11></ng-container>
<ng-container #componenteDinamico12></ng-container>


