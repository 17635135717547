import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-slide-generic-page',
  templateUrl: './slide-generic-page.component.html',
  styleUrls: ['./slide-generic-page.component.scss']
})
export class SlideGenericPageComponent implements OnInit {
  @Input() entity: any = null;
  @Input() article: any = null;
  @Input() breadcump: string = null;
  @Input() title: string = null;
  @Input() isAllEvents: boolean = false;
  @Input() countFamily: number = 1;
  @Input() pathMob: any;
  @Input() path: any;
  @Input() singlepage: any;
  mobile = false;
  slideEvents;
  moveSinglePageStauts: boolean = false;
  breadcumpHtmlOriginal: string;
  language: string;
  month: string;
  year: number;
  linkVenue: string = "";
  itemCarrouselHeader: any[];
  itemsInfo: any[];
  links: any[];
  constructor(public route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    })
    this.route.params.forEach((params: Params) => {
      this.month = params['month'];
    })

    this.route.params.forEach((params: Params) => {
      this.year = params['year'];
    });


    if (this.article) {
      this.title = this.article["article"]["title"];
      this.breadcump = this.article["publication"]["title"] + " > " + this.title;
    }


    if (this.entity) {
      if (this.entity["studyPlanItems"]) {
        let entity_width = 100 / this.entity["studyPlanItems"].length;
        let entity_id_first = this.entity["studyPlanItems"][0]["id"];

        setTimeout(function () {

          $(".item-slide-size").css("width", entity_width + "%");
          $(".item-description").css("display", "none");
          $("#item-description" + entity_id_first).fadeIn(750);

        }, 1);
      }


    }

    if (this.singlepage.type == 'simple') {
      if (document.getElementById('body')) {
        document.getElementById('body').innerHTML = this.singlepage.body;
      }


      if (this.singlepage.parent || this.singlepage.headerSection) {
        if (this.singlepage.parent) {
          this.itemsInfo = this.singlepage.parent[0].children;
          this.itemCarrouselHeader = this.singlepage.children.slice(0,6);

        }
        if (this.singlepage.headerSection) {
          this.itemsInfo = this.singlepage.headerSection.headerSection.headerSectionItem;
          this.itemCarrouselHeader = this.singlepage.children.slice(0,6);

        }
      } else {
        this.itemsInfo = this.singlepage.children;

      }


    }

    if (this.singlepage.type == "links") {
      this.links = this.singlepage.links;

      if (this.singlepage.parent) {
        this.itemsInfo = this.singlepage.parent[0].children;
        this.itemCarrouselHeader = this.singlepage.children.slice(0,6);
      } else {
        this.itemsInfo = this.singlepage.children;

      }

    }
    
    if (this.isAllEvents) {

      this.route.params.forEach((params: Params) => {
        if (params["venueCalendar"]) {
          this.linkVenue = "/" + params["venueCalendar"]
        }
      })

      setTimeout(() => {
        $("#mes-" + this.month).addClass("actived");
        let monthIndex = this.getMonthIndex(this.month);
      }, 1);


    }



  }

  url(element: any) {
    let url = '';
    if (element.route) {
      if(element.externalLink){
        url = element.route;

      }else{
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if(element.externalLink){
        url = element.systemTitle;

      }else{
      if (element.systemTitle[0] == '/') {
        url = element.systemTitle = element.systemTitle.replace('/', '')
      }
      url = '/' + this.language + '/' + url;
    }
    }

    return url;
  }
  getMonthIndex(month) {
    switch (month) {
      case "01": return 0;
      case "02": return 1;
      case "03": return 2;
      case "04": return 3;
      case "05": return 4;
      case "06": return 5;
      case "07": return 6;
      case "08": return 7;
      case "09": return 8;
      case "10": return 9;
      case "11": return 10;
      case "12": return 11;
    }

  }


}
