<div style="text-align: -webkit-center;">
    <div class="container-bienvenida">
        <div class="container-izq" *ngIf="isDesktop">
            <video  #videoPlayer style="width: 100%;" controls (play)="onVideoPlay()" (pause)="onVideoPause()" (ended)="onVideoEnded()">
                <source
                    [src]="apiUrl+'/public/filters/careerPhoto/public/videoQR2.mp4'"
                    type="video/mp4">
                Tu navegador no soporta el elemento de video.
               
            </video>
            <button id="playButton" class="play-container"(click)="togglePlayPause()" *ngIf="!isPlaying">
                <i class="fas fa-play"></i>
            </button>
            
        </div>
        <div class="container-izq" *ngIf="!isDesktop">
            <img src="../../../../../assets/img/chica.png" alt="">
        </div>
        <div class="img-container">
            <div>
                <h2 class="quicksand-medium">¡Bienvenido al comienzo de tu emocionante viaje universitario!</h2>
            </div>
            <div class="qr-container">
                <img *ngIf="isDesktop" class="qr-img" src=".././../../../../assets/img/nuestras sedes y qr.png" alt="">
                <div class="qr-text">
                    <p *ngIf="isDesktop" class="subtitle quicksand-medium" >Escaneá el código QR, inscribite y comenzá la aventura</p>
                    <p *ngIf="isDesktop" class="text quicksand-regular">Para inscribirte es necesario que seas ingresante regular del
                        último año del secundario o hayas terminado de cursar tus estudios secundarios, adeudes o no
                        materias</p>
                    <p *ngIf="!isDesktop" class="quicksand-medium">Hacé click, inscribite y comenzá la aventura</p>
                    <a id="boton-qr" href="https://inscripcion.uca.edu.ar" class="boton-inscribite">
                        <span class="nunito-regular">
                            INSCRIBITE
                        </span>
                    </a>
                </div>
    
            </div>
    
    
        </div>
    </div>
</div>
