import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-dos',
  templateUrl: './general-dos.component.html',
  styleUrls: ['./general-dos.component.scss']
})
export class GeneralDosComponent implements OnInit {
  //general con lista 
  datos: any;
  itemsParent: any[] = [];
  links: any[];
  titleList: string;
  styleColor = '#1e3e6f';
  language: any;
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  valor: string;
  count: number;
  spinner = true;
  type = "ingresa";
  carrer: string;
  venue: string;
  tel: Boolean = false;
  curso: any;
  dialog: any;
  area: any = null;
  nivelPrioridad = ['Doctorado', 'Maestría', 'Especialización', 'Curso de Posgrado', 'Curso Acreditable'];
  titulos = [
    { singular: 'Doctorado', plural: "Doctorados" },
    { singular: 'Maestría', plural: "Maestrías" },
    { singular: 'Especialización', plural: "Especializaciones" },
    { singular: 'Curso de Posgrado', plural: "Cursos de Posgrado" },
    { singular: 'Curso Acreditable', plural: "Cursos Acreditables" },
  ];
  isMob: boolean

  dataContacto = {
    "id": 1,
    "title": "Contactate con nosotros",
    "color_title": "#003a73",
    "subtitle": "o dejanos tus datos para recibir más información",
    "color_subtitle": "#ffffff",
    "background_color": "#c1b283",
    "link_whatsapp": "https://api.whatsapp.com/send?phone=5491130182402&app=facebook&entry_point=page_cta",
    "link_inst": "https://www.instagram.com/posgradosuca",
    "link_face": "https://www.facebook.com/UCAPosgrados",
    "mail_destination": "educacion.continua@gmail.com"
  }

  dataPortada: any =
    {
      "cover": {
        "id": 3,
        "main": true,

      },

    }
  constructor(private aRouter: ActivatedRoute, private services: GeneralService, public route: ActivatedRoute) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });


    var url = window.location.href;
    var id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    var gclid = id.indexOf('?')
    var busqueda = (gclid > 0) ? id.slice(0, gclid) : id;


    this.checkScreenWidth()

    this.services.getSimplepage(busqueda, this.language).subscribe(data => {
      if (data.data[0]) {
        this.datos = data.data[0];

        this.dataPortada.cover.path = this.datos.path;
        this.dataPortada.cover.pathMobile = this.datos.pathMobile;
        this.dataPortada.cover.title = "Carreras de Posgrado";
        this.dataPortada.cover.subtitle = this.datos.title;
        this.dataPortada.cover.color_title = "white";
        this.dataPortada.cover.color_subtitle = "white";
        this.dataPortada.cover.check_capa = true;


        this.area = { name: this.datos.title /*, file: this.datos.icono?.path*/ }
        if (this.datos.parent && this.datos.parent[0].children) {
          this.datos.parent[0].children.forEach(element => {
            this.itemsParent.push(element)
          });
        }
        if (this.datos.children) {
          this.datos.children.forEach(element => {
            this.itemsParent.push(element)
          });
        }

        if (this.datos.headerSection) {
          this.datos.headerSection.headerSection.headerSectionItem.forEach(element => {
            this.itemsParent.push(element)
          });
        }
        if (this.datos.links) {
          this.styleColor = this.datos.links[0].styleColor;
          this.titleList = this.datos.links[0].title;
          //this.links = this.datos.links
        }

      }

    })



  }

  private checkScreenWidth() {
    const evaluateScreenWidth = () => {
      if (window.innerWidth <= 768) {
        this.isMob = true;
      } else {
        this.isMob = false;
      }
    };
    evaluateScreenWidth();
    window.addEventListener('resize', evaluateScreenWidth);
  }
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  redirect(c) {
    if (c.route) {
      window.location.href = this.language + c.route
    } else if (c.linkCareer) {
      window.location.href = this.language + c.linkCareer
    }

  }



  windowOpen(url) {
    window.open(url)
  }







  getInicio(mes: number, año: number): string {
    return this.meses[mes] + ' ' + año;
  }


  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'consultar'
    }

  }
  ngOnInit(): void {
  }
  url(element: any) {
    let url = '';
    let boolExternalLink: any = element.externalLink;
    if (!boolExternalLink) {
      if (element.route) {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/es/' + url;
      }
      if (element.systemTitle) {
        url = element.systemTitle
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/es/' + url;
      }
    } else {
      url = element.route;
    }

    return url;
  }

}
