<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">

                    Lo sentimos, ha ocurrido un error, ¡la página solicitada no se encuentra! </div>
                <div class="error-actions">
                    <a href="/" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Home </a>
                </div>
            </div>
        </div>
    </div>
</div>