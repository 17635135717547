import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroAgenda } from 'src/app/shared/models/registroAgenda';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agenda-general-posgrados',
  templateUrl: './agenda-general-posgrados.component.html',
  styleUrls: ['./agenda-general-posgrados.component.scss']
})
export class AgendaGeneralPosgradosComponent implements OnInit {

  @Input() id:string;
  Agenda:RegistroAgenda[]=[];
  noticiaActive:string;
  @Input() data:any;
  constructor(private services:GeneralService,private aRouter:ActivatedRoute) { 
  }

  ngOnInit(): void {
    
      this.noticiaActive='1';
      var index=1;
      this.data.forEach(element => {
         let registro=new RegistroAgenda();
         registro.title=element.title;
         registro.date=element.startOn.date;
         registro.hours=element.startOn.date;
         registro.id=index.toString();
         registro.link='es/eventos/'+element.systemTitle
         this.Agenda.push(registro);
         index++;

      });


  
  }

  cambiarSlider(i:number){
    this.noticiaActive=this.Agenda[i].id;

  }
}
