import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-posgrados',
  templateUrl: './posgrados.component.html',
  styleUrls: ['./posgrados.component.scss']
})
export class PosgradosComponent implements OnInit {
  formGroup:FormGroup;
  idListaPosgrados:string;
  idFormacionContinua:string;
  idNovedades:string;
  id_component:string;

  constructor( private router:ActivatedRoute,private form:FormBuilder,private services : GeneralService) { 
    this.services.getRoutes('posgrado',false).subscribe(r=>{
      this.idListaPosgrados=r.data[0][2][0].entityId;
      this.idFormacionContinua=r.data[0][3][0].entityId;
      this.idNovedades=r.data[0][5][0].entityId;
    }) 
    this.id_component=this.router.snapshot.params['id'];

    this.formGroup = this.form.group({
      busqueda:['', [Validators.required]],
      pais:['', [Validators.required]],
      area:['', [Validators.required]],
      tipo:['', [Validators.required]],
     
    });
  }

  ngOnInit(): void {
    if(this.id_component){
      var element=document.getElementById(this.id_component)
      setTimeout(function(){
        element.scrollIntoView({block: "start", behavior: "smooth"});
      }, 500); 
    }
  }

}
