<br>
<h1 class="azul">Autoridades</h1>
<section class="subheader doble-m">
    <div class="container">
        <div class='row'>
            <div class='col'>
                <p _ngcontent-c3="" class="fs-16 c-principal breadcump">
                    <span _ngcontent-c3="" id="breadcump">
                        <a [href]="language+'/facultades/'+venue" class="c-principal">
                            <strong> {{venueTitulo}} &gt; </strong>
                        </a>
                        <!-- <a [href]="language+'/facultades/'+venue+'/'+type" class="c-principal">
                            <strong> {{typeTitulo}} &gt; </strong>
                        </a> -->
                        <a [href]="language+'/facultades/'+venue+'/'+type+'/'+carrer" class="c-principal">
                            <strong> {{carreraTitulo}} &gt; </strong>
                        </a>
                        <a>Autoridades</a>
                    </span>
                </p>
            </div>
        </div>
    </div>
    <!-- <h2>
        <strong>
            <span style="font-size:16px;">
                <a [href]="language+'/facultades/'+venue+'/'+type+'/'+carrer" class="c-principal">
                    {{carreraTitulo}} 
                </a>
            </span>
        </strong>
    </h2> -->
  <div class="autoridades" id='body'>


  </div>
  <!--
  <ng-container *ngfor="let item of datos">
     <div class="row ">
      <div class="mt-2 pt-3 pl-3 pr-3 ml-3">
        <img onerror="src='./assets/img/default.png'" src="item.path">
      </div>
      <div class="col-md-8 pt-3 pl-3 pr-3" style="color: #1e3e6f">
        <p>
          <b>
            {{item.name}}
          </b>
        </p>
        <p>
          <span>
            {{item.place}}
          </span>
        </p>
        <p>
          {{item.venue}}
        </p>
        <div>
          <p style="line-height: 16px;font-size:12px">
            Email:
            {{item.email}}
          </p>
        </div>
      </div>
    </div> 
  </ng-container>
  -->
</section>
