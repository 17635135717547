import { Component, Input, OnInit } from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-allevents',
  templateUrl: './allevents.component.html',
  styleUrls: ['./allevents.component.scss']
})
export class AlleventsComponent implements OnInit {

  @Input() type: number = 1;
  @Input() entity: any = null;
  @Input() article: any = null;
  @Input() breadcump: string = null;
  @Input() title: string = null;
  @Input() isAllEvents: boolean = true;
  @Input() countFamily: number = 1;
  lastYear:number;
  nextYear:number;
  style: string;
  mobile = false;

  slideEvents;

  moveSinglePageStauts: boolean = false;
  breadcumpHtmlOriginal: string;
  language: string;
  month: number;
  year: number;
  linkVenue: string = "";
  venue: any;
  events: any[];
  lastEvents: any[];
  
  constructor(private services: GeneralService, public route: ActivatedRoute, private titleH: Title, public meta: Meta) {

  }

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    })
    this.route.params.forEach((params: Params) => {
      this.month = params['month'];
      var wd = 220;
      var wm = 730;
      var ad = ((this.month - 1) * wd);
      var am = ((this.month - 1) * wm);
      $("#mes-d" + this.month).addClass("actived");
      $("#mes-m" + this.month).addClass("actived");
      $(' .mob > .carousel-container >  .carousel-cells').css('transform', 'translateX(-' + am.toString() + 'px)')
      $(' .desk > .carousel-container >  .carousel-cells').css('transform', 'translateX(-' + ad.toString() + 'px)')
    })

    this.route.params.forEach((params: Params) => {
      this.year = params['year'];
      this.lastYear = Number(params['year']) - 1;
      this.nextYear = Number(params['year']) + 1;
      this.titleH.setTitle("UCA - Calendario " + this.year);
      if (params['venueCalendar']) {
        this.venue = params['venueCalendar'];
      }
    });


    if (this.isAllEvents) {

      this.route.params.forEach((params: Params) => {
        if (params["venueCalendar"]) {
          this.linkVenue = "/" + params["venueCalendar"]
        }
      })


    }

    this.services.getEventsByMonth(this.language, this.month, this.year, this.venue).subscribe(data => {
      this.events = data.data[0]['eventArray'];
      this.lastEvents = data.data[0]['lastEvent'];
      if (this.venue != "") {
        $("#slide-text").text("Eventos - " + data.data[0]['venueTitle']);
      }
      for (let i in this.events) {
        let date = new Date(this.events[i]["startOn"]["date"]);
        let month = date.getMonth() + 1;
        this.events[i]["startOnDate"] = date.getFullYear() + "-" + month + "-" + date.getDate();
      }

    });
  }
  ngAfterViewInit() {
  $('.carousel-cells').css('width', '0px')

  }
  getMonthIndex(month) {
    switch (month) {
      case "01": return 0;
      case "02": return 1;
      case "03": return 2;
      case "04": return 3;
      case "05": return 4;
      case "06": return 5;
      case "07": return 6;
      case "08": return 7;
      case "09": return 8;
      case "10": return 9;
      case "11": return 10;
      case "12": return 11;
    }

  }





}
