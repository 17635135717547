<section class="facsec2" [style.background]="background">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <h2 class='azul'>{{title}}
        </h2>
      </div>
      <div class="col-lg-4 text-center">
        <img onerror="this.src='./assets/img/default.png'" class="text-center " [src]="path">

      </div>
    
      <div class="col-lg-8 border-right desk">
       
        <div id="hsec1-slider" class="carousel slide  grid-cont t-dots" data-ride="carousel">
          <ol class="carousel-indicators">
            <li *ngFor='let item of cards;index as i ' data-target="Noticias-s" (click)="cambiarSlider(i)"
              [ngClass]='(item.id== noticiaActive)?"active" : ""'></li>

          </ol>
          <div class="carousel-inner">
            <div class="carousel-item " *ngFor='let item of cards' class="carousel-item "
              [ngClass]='(item.id== noticiaActive)?"active" : ""'>
              <a class='pointer' [href]="item.systemTitle">

                <div class="grid-item">
                  <img onerror="this.src='./assets/img/default.png'" class='img-carrousel' [src]="item.foto">
                  <p>{{item.title}}</p>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-8 border-bottom mob">
       
        <div id="hsec1-slider" class="carousel slide  grid-cont t-dots" data-ride="carousel">
          <ol class="carousel-indicators">
            <li *ngFor='let item of cards;index as i ' data-target="Noticias-s" (click)="cambiarSlider(i)"
              [ngClass]='(item.id== noticiaActive)?"active" : ""'></li>

          </ol>
          <div class="carousel-inner">
            <div class="carousel-item " *ngFor='let item of cards' class="carousel-item "
              [ngClass]='(item.id== noticiaActive)?"active" : ""'>
              <a class='pointer' [href]="item.systemTitle">

                <div class="grid-item">
                  <img onerror="this.src='./assets/img/default.png'" class='img-carrousel' [src]="item.foto">
                  <p>{{item.title}}</p>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-4">
          <div class='row'>
            <div class="col-6"  *ngFor='let item of cabeceraButtons'>
              <div [style.background]='item.colorButton'
              class="card">
              <a class='pointer' [href]="item.linkButton">
                <p>{{item.titleButton}}</p>
              </a>
            </div>
            </div>
          </div>
         

      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mt-4">
               <a class='azul' [href]="'/es/'+noticesLink"><h5>{{noticesLinkTitle}}</h5></a>    
      </div>
    
     
    </div>
  </div>
</section>