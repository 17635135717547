import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import { GeneralService } from 'src/app/core/services/general/general.service';
import Swal from 'sweetalert2'
import { CabeceraComponent } from 'src/app/modules/general/components/cabecera/cabecera.component';
import { CardsGeneralDosComponent } from 'src/app/modules/general/components/cards-general-dos/cards-general-dos.component';
import { CardsGeneralComponent } from 'src/app/modules/general/components/cards-general/cards-general.component';
import { CarrouselGeneralComponent } from 'src/app/modules/general/components/carrousel-general/carrousel-general.component';
import { ListaDeLinksComponent } from 'src/app/modules/general/components/lista-de-links/lista-de-links.component';
import { ContactoComponent } from 'src/app/shared/components/contacto/contacto.component';
import { SliderGeneralComponent } from '../../components/slider-general/slider-general.component';
import { CustomDiaryGeneralComponent } from '../../components/custom-diary-general/custom-diary-general.component';
import { FormContactoComponent } from '../../components/form-contacto/form-contacto.component';
import { CustomStatsGeneralComponent } from '../../components/custom-stats-general/custom-stats-general.component';
import { PrimeraFotoDestacadaComponent } from '../../components/primera-foto-destacada/primera-foto-destacada.component';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  formDatos: FormGroup;
  mostrarLista = true;
  mostrarCars = false;
  titleLink: string;
  language: any;
  viewContainerRefs: any[] = []
  data: any;
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  arrayRowsSize: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private form: FormBuilder, private router: ActivatedRoute, private services: GeneralService) {

    this.formDatos = this.form.group({
      name: [''],
      email: ['', Validators.email],
      region: [''],
      message: [''],
    });

    this.router.url.subscribe((segments: UrlSegment[]) => {
      this.router.params.forEach((params: Params) => {
        this.language = params['language'];
        this.titleLink = params['title'];
      });
    });
    this.services.getHomeEntities(this.language).subscribe(home => {
      this.data = home.data[0]
      this.arrayRowsSize = this.data['pagesLength']
      for (let i = 1; i < this.arrayRowsSize + 1; i++) {
        if (this.data[i][0]['status']) {
          this.loadPageAt(i);
        }
      }
    })


  }


  ngOnInit(): void {
  }
  consultar() {
    let datos = new ConsultaRequest();
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.message = this.formDatos.get('message').value;
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )

  }


  async loadPageAt(index: number) {
    if (this.data[index].length > 1) {
      this.services.getHomeEntity(this.data[index][0]['route'], this.language).subscribe(data1 => {
        this.definirComponentes(index, this.data[index][0], 8, data1)
        this.services.getHomeEntity(this.data[index][1]['route'], this.language).subscribe(data2 => {
          this.definirComponentes(index, this.data[index][1], 4, data2)
        });
      });
    } else {
      this.services.getHomeEntity(this.data[index][0]['route'], this.language).subscribe(data1 => {
        this.definirComponentes(index, this.data[index][0], 12, data1)
      });
    }

  }
  definirComponentes(index: number, element: any, width: number, data1: any) {
    let info = data1.data[0];
    switch (element.entityType) {
      case "Slider":
        this.newComponent(index, 'Slider', info, '', width)

        break;
      case "CustomSocialNetwork":
        this.newComponent(index, 'Cards', info, '', width)

        break;
      case "CustomStats":
        this.newComponent(index, 'CustomStats', info, '', width)

        break;
      case "CustomDiary":

        this.newComponent(index, "CustomDiary", info, '', width)

        break;
      case "CustomForm":
        this.newComponent(index, "CustomForm", info, '', width)

        break;

      case 'EntityList':
         if (info.entityList) {
          info.entityList.forEach(element => {
            let type = element.typeDisplay;
            var background = element.background;
            this.newComponent(index, type, info, background, width)
          });
        }


        break;
    }


  }
  newComponent(index: number, type: string, info: any, background: string, width: any) {
     switch (type) {

      case 'Primera foto destacada': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info['entityList'][0].entity;
        // ref.instance.title = info['entityList'][0].title;
        ref.instance.title = "Noticias UCA";
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Slider': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(SliderGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.slider[0].sliderItem;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Simple': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (width == 8) {
          ref.instance.numberCards = 3;
        }
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.instance.background = background;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Simple con formulario': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.instance.width = width;
        ref.instance.background = background;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Carrousel':
        if (info.entityList[0].entity) {
          let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
          let ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.width = width;
          ref.changeDetectorRef.detectChanges();
        }
        break;
      case 'Cards':
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.socialNetwork.socialNetworkItem;
        ref.instance.title = info.socialNetwork.title;
        ref.changeDetectorRef.detectChanges();
        break;
      case 'Lista de links':
        if (info.entityList[0].entity) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.width = width;
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();
        }
        break;
      case 'Video + Formulario (en ingles)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(ContactoComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'CustomForm': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'CustomDiary': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (index == 2) {
          width = 4;
        }
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.CustomDiary;
        ref.instance.width = width;
        ref.instance.botonFacultades="false"
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'CustomStats': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.CustomStats;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Cabecera': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.title = info.entityList[0].title;
        ref.instance.slider = info.entityList[0].entity;
        ref.instance.background = info.entityList[0].background;
        ref.instance.path = info.entityList[0].path;
        ref.instance.width = width;
        ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
        ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
        ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
        ref.changeDetectorRef.detectChanges();

      }
        break;


    }






  }

  viewContainerRefA(index: number): any {
    switch (index) {
      case 1:
        return this.viewContainerRef1;
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;
      case 11:
        return this.viewContainerRef11;
      case 12:
        return this.viewContainerRef12;
      default:
        // 
        break;
    }
  }
  url(element: any) {
    let url = '';
    if (element.route) {
      if(element.externalLink){
        url = element.route;

      }else{
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if(element.externalLink){
        url = element.systemTitle;

      }else{
      if (element.systemTitle[0] == '/') {
        url = element.systemTitle = element.systemTitle.replace('/', '')
      }
      url = '/' + this.language + '/' + url;
    }
    }

    return url;
  }
}
