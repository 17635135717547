<div class='p-3 text-center' style="display: flex; flex-direction: column; align-items: center;">
    <div class="d-flex flex-column justify-content-center no-res-container">
        <ng-container *ngFor="let part of formatTitle(infoAreaRecomendada.title)">
            <p class="no-res quicksand-regular" [style.color]="infoAreaRecomendada.color_title">{{ part }}</p>
        </ng-container>
    </div>


    <div class="blue-box ">
        <p class="white-text" [style.color]="infoAreaRecomendada.color_title_second">
            {{infoAreaRecomendada.title_second}}</p>

        <div class="posg-cont-c">
            <app-card-area-conocimiento [item]="item" [page]="'cursos'"
                *ngFor='let item of  infoAreaRecomendada.areaList;'></app-card-area-conocimiento>
        </div>
    </div>
</div>