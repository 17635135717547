export class RegistroCard2{
    title: string;
    foto: string;
    id:string;
    categoria:string;
    link:string;
    systemTitle:string;
    summary:string;
    hipervinculo?:string;
    checkTag: boolean
}