import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-de-links',
  templateUrl: './lista-de-links.component.html',
  styleUrls: ['./lista-de-links.component.scss']
})
export class ListaDeLinksComponent implements OnInit {

  @Input() data: any;
  width = false;
  posgrados: any[] = [];

  constructor() { }
  ngOnInit(): void {
    if (this.data) {
      this.posgrados = this.data;

    }

  }

}
