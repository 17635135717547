import { Component, ViewChild, ElementRef, OnInit, HostListener, Input } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-buscador-carreras',
  templateUrl: './buscador-carreras.component.html',
  styleUrls: ['./buscador-carreras.component.scss']
})
export class BuscadorCarrerasComponent implements OnInit {
  @Input() sede;
  @Input() showPagination = true;
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: any[] = [];
  carreras: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = 1;
  pageSize = 10;
  spinner = true;
  filtro = false;
  language = "es"
  filaExpandida: any = null;
  carrerasFiltradas$: Observable<string[]>;
  modalidades: Carrera[] = [];
  carreraSeleccionada: any
  @ViewChild('swipeContainer') swipeContainer2: ElementRef;
  @ViewChild('busqueda') busqueda: ElementRef;
  isDesktop: boolean;
  visiblePages: number = 5;
  totalPaginas: number;
  @HostListener('window:resize', ['$event'])
  searchTerms = new Subject<string>();
  private searchSubscription: Subscription;
  visiblePages2: (number | string)[] = [];

  onResize(event) {
    this.checkDeviceType();
    this.actualizarPaginasVisibles();
  }
  constructor(private el: ElementRef, public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      filtro: ['',],
      modalidad: ['']
    });
    this.servicesGeneral.getCarreras(null, null, null, null, this.destacadas).subscribe(data => {
      data.data[0].areaIngreso.forEach(area => {
        area.title = this.eliminarPrefijoFacultad(area.title);
        this.areas.push(area)
      })
      this.regiones = data.data[0].region;
      this.niveles = data.data[0].careerTypeIngreso;
      this.modalidades = data.data[0].modalidad;
      let sede = this.regiones.find(d => d.title == this.sede)
      if (sede) {
        this.servicesGeneral.getFacultyByLocation(sede.title).subscribe(res => {
          this.areas = [];
          res.data[0].venue.forEach(area => {
            area.title = this.eliminarPrefijoFacultad(area.title);
            this.areas.push(area)
          })
        })
        this.buscador.controls['sede'].setValue(sede.id)
      };
      this.filtrar(false);

    })


  }
  ngOnInit(): void {
    this.checkDeviceType();

    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      switchMap(() => {
        this.spinner = true;
        var filtro = this.buscador.get('filtro')!.value;
        var sede = this.buscador.get('sede')!.value;
        var nivel = this.buscador.get('nivel')!.value;
        var area = this.buscador.get('area')!.value;
        var modalidad = this.buscador.get('modalidad')!.value;
        this.destacadas = sede == '' && nivel == '' && area == '' && filtro == '' && modalidad == '';

        return this.servicesGeneral.getCarreras(sede, nivel, area, filtro, this.destacadas);
      })
    ).subscribe(data => {
      this.resultados = [];
      this.carreras = [];
      if (data.data[0].career) {
        data.data[0].career.forEach(element => {
          this.resultados.push(element);
        });
      }
      this.cambiarPagina(1, true, true);
      //this.spinner = false; 
    });

  }
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
  onSearch(): void {
    this.searchTerms.next();
  }
  checkDeviceType() {
    this.isDesktop = window.innerWidth >= 768;
  }
  obtenerFechaInicio(fecha) {
    let inicio = this.getInicio(new Date(fecha.inicio.date).getMonth(), new Date(fecha.inicio.date).getFullYear());
    return inicio
  }

  actualizarPaginasVisibles() {
    if (this.isDesktop) {
      this.pages = Array.from({ length: this.totalPaginas }, (_, i) => i + 1);
    } else {
      const start = Math.max(this.numberActive - Math.floor(this.visiblePages / 2), 1);
      const end = Math.min(start + this.visiblePages - 1, this.totalPaginas);
      this.pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  }
  seleccionarCarrera(item: any) {
    this.buscador.get('filtro').setValue(item.nombre);
    this.carreraSeleccionada = item
    this.carreras = [];
  }
  destacadas = true;
  filtrar(event: any) {
    this.onSearch();
  }
  /* filtrar(event) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.resultados = [];
    this.carreras = [];
    this.spinner = true;
    var filtro = this.buscador.get('filtro').value;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area').value;
    var modalidad = this.buscador.get('modalidad').value;
    this.destacadas = sede == '' && nivel == '' && area == '' && filtro == '' && modalidad == '';
    let scrollear = true
  
    if (filtro=='' && sede == '' && nivel == '' && area == '' && modalidad == '') {
      scrollear = false
    }
    if (this.sede && filtro==''  && nivel == '' && area == '' && modalidad == '') {
      scrollear = false
    }
    
    this.servicesGeneral.getCarreras(sede, nivel, area, filtro, this.destacadas).subscribe(data => {
     
      if (data.data[0].career) {
        data.data[0].career.forEach(element => {
          this.resultados.push(element);
        });
      }
      this.cambiarPagina(1, true, scrollear)
    })


  } */
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }
  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar(null);
  }
  updateVisiblePages(): void {
    const totalPages = this.pages.length;
    const currentPage = this.numberActive;
    let startPage = 1;
    let endPage = Math.min(10, totalPages);
    if (totalPages > 10) {
      if (currentPage > 6) {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - 9;
        }
      }
    }
    this.visiblePages2 = [];
    for (let i = startPage; i <= endPage; i++) {
      this.visiblePages2.push(i);
    }

    if (startPage > 1) {
      if (startPage > 2) {
        this.visiblePages2.unshift('...');
      }
      this.visiblePages2.unshift(1);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.visiblePages2.push('...');
      }
      this.visiblePages2.push(totalPages);
    }
  }

  cambiarPagina(page, first, scrollear) {

    this.pages = [];
    const modalidad = this.buscador.get('modalidad').value;
    const resultadoFiltrado = this.filtros(modalidad);
    this.carreras = (this.showPagination) ? resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize) : resultadoFiltrado;
    let cant = resultadoFiltrado.length / this.pageSize;
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    page = (page == '...') ? (this.numberActive) : page

    this.totalPaginas = Math.ceil(resultadoFiltrado.length / this.pageSize);
    this.numberActive = page;
    this.updateVisiblePages();
    /* this.actualizarPaginasVisibles(); */
    this.spinner = false;
    if (!first && scrollear || !this.isDesktop && scrollear) {
      this.busqueda.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.resultados;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }
    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  verInfo(carrera: any) {
    if (this.filaExpandida === carrera) {
      this.filaExpandida = null;
    } else {
      this.filaExpandida = carrera;
    }
  }

}


