import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { FormHubspotComponent } from 'src/app/shared/components/form-hubspot/form-hubspot.component';
import { ModalGeneralComponent } from 'src/app/shared/components/modal-general/modal-general.component';
declare var hbspt: any;

@Component({
  selector: 'app-carreras-de-grado',
  templateUrl: './carreras-de-grado.component.html',
  styleUrls: ['./carreras-de-grado.component.scss']
})
export class CarrerasDeGradoComponent implements OnInit {

  datos: any;
  agenda: any;
  items: any[] = [];
  region: any[] = [];
  htmlPlan: string;
  htmlEstudiar: string;
  htmlPerfil: string;
  seccion: any;
  titleLink: any;
  language: any;
  carrer: string;
  venue: string;
  carrertype: string;
  currentRoute: string;
  fare: any[];
  entity: any[];
  formLink: string;
  mySubscription: any;
  linkCode: string;
  numberLettersTitle: number;
  classLettersTitle: string;
  iframe: any;
  carreraTrue: Boolean = true;
  planData: any;
  titlesPlanData: any;
  sedeDeInteres='';


  ngAfterViewInit() {
    // Código del formulario de HubSpot
    hbspt.forms.create({
      region: "na1",
      portalId: "23647426",
      formId: "b2e6899b-83b4-4a49-92ff-92360afdd10d",
      target: '#hubspot-form' // El elemento donde se mostrará el formulario
    });
  }
  carrerTypes = ["maestria", "profesorado", "carrera-corta", "pregrado", "posgrado", "ciclo-de-licenciatura", "tecnicatura", "carrera-de-grado", "doctorado", "postitulo", "curso-de-posgrado", "curso-online", "especializacion", "posgrado-online"];
  constructor(private _sanitizer: DomSanitizer, public dialog: MatDialog, private aRouter: ActivatedRoute, public route: ActivatedRoute, public carrerService: GeneralService, private router: Router, public title: Title, public meta: Meta,) {
    this.route.params.forEach((params: Params) => {
      this.carrer = params['carrer'];
    });
    this.route.params.forEach((params: Params) => {
      this.venue = params['venue'];
    });
    let careerText = (this.carrer.charAt(0).toUpperCase() + this.carrer.slice(1)).replace(/-/g, " ");
    let venueText = (this.venue.charAt(0).toUpperCase() + this.venue.slice(1)).replace(/-/g, " ");

    this.title.setTitle('UCA - ' + careerText + ' (' + venueText + ')');
    this.meta.addTag({ property: 'og:title', content: 'UCA - ' + careerText + ' (' + venueText + ')' }, true);
    this.meta.addTag({ property: 'og:description', content: '' }, true);
    this.route.params.forEach((params: Params) => {
      this.carrertype = params['type'];
      if (!this.carrerTypes.includes(this.carrertype)) {
        router.navigate(['404']);
      }
    });
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

    this.currentRoute = `/${this.language}/facultades/${this.venue}/${this.carrertype}/${this.carrer}`;


    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];

    });
    this.seccion = this.aRouter.snapshot.params['seccion']
    this.carrerService.getCarrerByTitle(this.carrer, this.venue, this.language).subscribe(data => {
      if ((data.data[0][0] == "career_disabled")) {
        router.navigate(['404']);
      } else
        if (data.data) {
          this.sedeDeInteres= this.route.snapshot.queryParamMap.get('sede_de_interes');
          this.agregarParametro(data.data[0].region, data.data[0].title)
          this.titlesPlanData = data.data[0].studyPlan[0]
          this.datos = data.data[0];

          var i = 0;
          let auxNumberLettersTitle = this.datos.title;
          this.numberLettersTitle = auxNumberLettersTitle.length;
          if (this.numberLettersTitle < 41) {
            this.classLettersTitle = "texto-s";
          } else {
            this.classLettersTitle = "texto-s paddingTituloLargo";
          }
          this.datos.region.forEach(element => {
            i++;
            element.link = this.language + element.link;
            if (this.datos.region[i]) {
              element.title = element.title + ' | ';
            }
            this.region.push(element)
          });
          this.htmlEstudiar = this.datos.whyStudy;
          this.agenda = this.datos.event
          this.linkCode = this.datos.linkCode;
          this.iframe = this.getVideoIframe(this.linkCode);


        } else {
          router.navigate(['404']);
        }

    })

    this.carrerService.getCarreraProfileTitle(this.carrer).subscribe(profile => {
      if (profile.data[0]) {
        this.htmlPerfil = profile.data[0]["profileTitleScope"];
      }
    })

    this.carrerService.getstudyplan(this.carrer).subscribe(plan => {
      if (plan.data[0][0] && plan.data[0][0].studyPlanItems[0] && plan.data[0][0].studyPlanItems[0].body !== '') {
        this.planData = plan.data[0][0];
      }
    })
  }
  agregarParametro(sedes, carrera) {
    const queryParams: { [key: string]: any } = {
      sede_de_interes: (this.sedeDeInteres)? this.sedeDeInteres: sedes[0].title,
    };
    sedes.forEach(s => {
      let sedeFormateada = this.quitarTildes(s.title.toLowerCase().replace(/\s+/g, '_'));
      let dynamicPropertyName;
      let plural = (sedeFormateada == 'rosario') ? '' : 's';
      dynamicPropertyName = 'carrera' + plural + '_de_grado__' + sedeFormateada + '_';
      queryParams[dynamicPropertyName] = carrera;
    });
    console.log(queryParams)
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  quitarTildes(texto: string) {
    const tildes = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      ü: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      Ü: 'U',
    };

    return texto.replace(/[áéíóúüÁÉÍÓÚÜ]/g, letra => tildes[letra]);
  }

  ngOnInit(): void {

  }
  ngAfterViewChecked(): void {
    var cardTitle = document.getElementById("card-title");
    if (cardTitle) {
      var sectionPrimary = document.getElementById("section-primary ");
      let height = cardTitle.offsetHeight;
      if (window.innerWidth > 550) {
        sectionPrimary.style.marginTop = (Number(height) * 0.50) + "px"
      } else {
        sectionPrimary.style.marginTop = (Number(height) * 0.25) + "px"
      }
    }
    if (this.datos) {
      if (this.datos.size_title) {
        document.getElementById("title-font").style.fontSize = this.datos.size_title + "px";
        document.getElementById("title-font-hidden").style.fontSize = this.datos.size_title + "px";
      }

      if (window.innerWidth < 640) {
        let font = document.getElementById("title-font-hidden").style.fontSize;
        document.getElementById("title-font").style.fontSize = (Number(font.replace("px", "")) * 0.50) + "px";
      } else {
        let font = document.getElementById("title-font-hidden").style.fontSize;
        document.getElementById("title-font").style.fontSize = Number(font.replace("px", "")) + "px";
      }
    }

  }
  openModal(id: string) {
    if (id == 'htmlEstudiar') {
      this.dialog.open(ModalGeneralComponent, { panelClass: 'custom-dialog-container', data: { html: this.htmlEstudiar, title: '¿Por qué estudiar en la UCA?' } });
    }
  }
  getVideoIframe(url) {
    if (url) {
      var video, results;

      if (url === null) {
        return '';
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video = (results === null) ? url : results[1];

      return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?disablekb=0&rel=0');
    }
  }

}
