import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { EventosComponent } from './components/eventos/eventos.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { EventosDosComponent } from './pages/eventos-dos/eventos-dos.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasGeneralComponent } from './pages/noticias-general/noticias-general.component';
import { EventosNoticiasComponent } from './pages/eventos-noticias/eventos-noticias.component';
@NgModule({
  declarations:[NoticiasGeneralComponent,NoticiasComponent,EventosComponent, EventosDosComponent, EventosNoticiasComponent],
  imports:[ CommonModule,SharedModule,RouterModule,AngularMaterialModule, ],
  exports:[],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ],
})
export class EventosModule { }
