

<div class="custom-select-wrapper" *ngIf="color=='gris'">
  <div class="custom-select-i" (click)="toggleDropdown()">
    <div [id]="id" class="custom-select-trigger nunito-medium trigger-i"
      [ngClass]="{'black-text': selectedOption?.title || selectedOption}">
      {{ selectedOption?.title || selectedOption || placeholder }}
      <!-- <span class="arrow"><i class="fas fa-chevron-down" style="margin-right: 0px !important"></i></span> -->
      <span class="arrow" *ngIf="colorArrow=='azul'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/flecha-select-azul.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='gris'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/Flechita-gris.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='dorada'"><img style="margin-right: 0px !important"
            src="../../../../assets/img/Flechita-dorada.png" alt=""></span>
    </div>
    <div class="custom-options " *ngIf="dropdownOpen">
      <span *ngIf=" selectedOption  && selectedOption!='' && placeholder != selectedOption"
        class="custom-option nunito-regular" [class.selected]="placeholder === selectedOption"
        (click)="selectOption('')" (mouseover)="hoverOption = placeholder" [class.hover]="placeholder === hoverOption">
        {{ placeholderAll ? placeholderAll : placeholder }}
      </span> 
      <span [id]="id" class="custom-option nunito-regular" *ngFor="let item of options"
        [class.selected]="item === selectedOption" (click)="selectOption(item)" (mouseover)="hoverOption = item"
        [class.hover]="item === hoverOption">
        {{ item.title || item }}
      </span>
    </div>
  </div>
</div>

<div class="custom-select-wrapper" *ngIf="color=='blue'">

  <div class="custom-select-i" (click)="toggleDropdown()" [style.height]="height? height : ''">
    <div [id]="id" class="custom-select-trigger trigger-i nunito-medium"
      [ngClass]="{'blue-text': selectedOption?.title || selectedOption}" [ngClass]="{'italic': italic}">
      {{ selectedOption?.title || selectedOption || placeholder }}
      <!-- <span class="arrow"><i class="fas fa-chevron-down" style="margin-right: 0px !important"></i></span> -->
      <span class="arrow" *ngIf="colorArrow=='azul'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/flecha-select-azul.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='gris'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/Flechita-gris.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='dorada'"><img style="margin-right: 0px !important"
            src="../../../../assets/img/Flechita-dorada.png" alt=""></span>
 
    </div>
    <div class="custom-options options-i nunito-regular" *ngIf="dropdownOpen">
      <span *ngIf="optionPlaceholder && selectedOption  && selectedOption!='' && placeholder != selectedOption"
        class="custom-option-i nunito-regular" [class.selected]="placeholder === selectedOption"
        (click)="selectOption('')" (mouseover)="hoverOption = placeholder" [class.hover]="placeholder === hoverOption">
        {{ placeholderAll ? placeholderAll : placeholder }}
      </span>
      <span [id]="id" class="custom-option-i nunito-regular" *ngFor="let item of options"
        [class.selected]=" (item === selectedOption) || (selectedOption && item.title && item.title === selectedOption.title)  || (item.title && item.title === selectedOption)" (click)="selectOption(item)" (mouseover)="hoverOption = item"
        [class.hover]="item === hoverOption">
        {{ item.title || item }} 
      </span>
    </div>
  </div>
</div>