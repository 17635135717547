<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2  style="text-align:center;">Carreras relacionadas
            </h2>
            <carousel class='desk' cellsToShow='4' loop='true' style='display:flex;min-height: 306px;'>
                <div class="carousel-cell " *ngFor="let item of  cards;index as i ;">
                    <div class="caja-item">
                        <a class='pointer' [href]="item.systemTitle">

                            <img onerror="this.src='./assets/img/default.png'" class="" [src]="item.img">
                            <div class="desc">{{item.titulo}}</div>
                        </a>
                    </div>
                </div>

            </carousel>

            <carousel class='mob' cellsToShow='1' loop='true' style='display:flex;min-height: 580px;'>
                <div class="carousel-cell " *ngFor="let item of  cards;index as i ;">
                    <div class="caja-item">
                        <a class='pointer' [href]="item.systemTitle">

                            <img onerror="this.src='./assets/img/default.png'" class="" [src]="item.img">
                            <div class="desc">{{item.titulo}}</div>
                        </a>
                    </div>
                </div>

            </carousel>
        </div>
    </div>
</div>