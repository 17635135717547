import { Component, ViewChild, ElementRef, OnInit, HostListener, Input } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-buscador-carreras',
  templateUrl: './buscador-carreras.component.html',
  styleUrls: ['./buscador-carreras.component.scss']
})
export class BuscadorCarrerasComponent implements OnInit {
  @ViewChild('swipeContainer') swipeContainer2: ElementRef;
  @ViewChild('busqueda') busqueda: ElementRef;
  @HostListener('window:resize', ['$event'])

  @Input() sede;
  @Input() showPagination = true;
  @Input() data;
  @Input() padding;
  @Input() paddingMob;
  @Input() translateY;

  carrerasFiltradas$: Observable<string[]>;
  private searchSubscription: Subscription;
  searchTerms = new Subject<string>();

  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: any[] = [];
  carreras: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = 1;
  pageSize = 10;
  spinner = true;
  filtro = false;
  language = "es"
  filaExpandida: any = null;

  modalidades: Carrera[] = [];
  carreraSeleccionada: any

  isDesktop: boolean;
  visiblePages: number = 5;
  totalPaginas: number;
  visiblePages2: (number | string)[] = [];
  filtersUrl: any = {};

  onResize(event: Event): void {
    this.checkDeviceType()
    this.actualizarPaginasVisibles();
  }

  constructor(private el: ElementRef, public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = (params['language']) ? params['language'] : 'es';
    });

    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      filtro: ['',],
      modalidad: ['']
    });
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const nivelParam = params.get('nivel');
    const sedeParam = params.get('sede');
    const areaParam = params.get('facultad');
    const keywordParam = params.get('keyword');

    this.servicesGeneral.getCarreras(null, null, null, null, this.destacadas).subscribe(data => {
      data.data[0].areaIngreso.forEach(area => {
        area.title = this.eliminarPrefijoFacultad(area.title);
        this.areas.push(area)
      })
      this.niveles = data.data[0].careerTypeIngreso;
      this.regiones = data.data[0].region;
      this.modalidades = data.data[0].modalidad;

      this.filtersUrl.nivel = this.niveles.find(d => d == nivelParam)
      if(!sedeParam){
        this.filtersUrl.sede = this.regiones.find(d => d.title ==  this.sede)
      }else{
        this.filtersUrl.sede = this.regiones.find(d => d.title == sedeParam )
      }
      console.log(this.filtersUrl)
      this.filtersUrl.area = this.areas.find(d => d.title == areaParam)
      this.filtersUrl.keyword=keywordParam;

      if (this.filtersUrl.keyword) this.buscador.controls['filtro'].setValue(this.filtersUrl.keyword)
      if (this.filtersUrl.nivel) this.buscador.controls['nivel'].setValue(this.filtersUrl.nivel)
      if (this.filtersUrl.sede) this.buscador.controls['sede'].setValue(this.filtersUrl.sede?.id)
      if (this.filtersUrl.area) this.buscador.controls['area'].setValue(this.filtersUrl.area?.id)

      this.onSearch();

    })


    /*this.servicesGeneral.getCarreras(null, null, null, null, this.destacadas).subscribe(data => {
      data.data[0].areaIngreso.forEach(area => {
        area.title = this.eliminarPrefijoFacultad(area.title);
        this.areas.push(area)
      })
      this.regiones = data.data[0].region;
      this.niveles = data.data[0].careerTypeIngreso;
      this.modalidades = data.data[0].modalidad;
      
 
            let sede = this.regiones.find(d => d.title == this.sede)
 
       if (sede) {
         this.servicesGeneral.getFacultyByLocation(sede.title).subscribe(res => {
           this.areas = [];
           res.data[0].venue.forEach(area => {
             area.title = this.eliminarPrefijoFacultad(area.title);
             this.areas.push(area)
           })
         })
         this.buscador.controls['sede'].setValue(sede.id)
       };
       
      this.filtrar(false);

    })*/


  }

  @ViewChild('filtroInput') filtroInput!: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filtroInput.nativeElement.focus({ preventScroll: true });
    }, 100);

    this.checkDeviceType();
  }
  ngOnInit(): void {
    let scrollear = true
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(300),
      switchMap(() => {
        this.spinner = true;
        var filtro = this.buscador.get('filtro')!.value;
        var sede = this.buscador.get('sede')!.value;
        var nivel = this.buscador.get('nivel')!.value;
        var area = this.buscador.get('area')!.value;
        var modalidad = this.buscador.get('modalidad')!.value;
        this.destacadas = sede == '' && nivel == '' && area == '' && filtro == '' && modalidad == '';

        if (sede == '' && nivel == '' && area == '' && modalidad == '' || this.sede != '') {
          scrollear = false
        }
        return this.servicesGeneral.getCarreras(sede, nivel, area, filtro, this.destacadas);
      })
    ).subscribe(data => {
      this.resultados = [];
      this.carreras = [];
      if (data.data[0].career) {
        data.data[0].career.forEach(element => {
          this.resultados.push(element);
        });
      }
      this.cambiarPagina(1, true, scrollear);
      //this.spinner = false; 
    });

  }
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
  onSearch(): void {
    this.searchTerms.next();
  }
  obtenerFechaInicio(fecha) {
    let inicio = this.getInicio(new Date(fecha.inicio.date).getMonth(), new Date(fecha.inicio.date).getFullYear());
    return inicio
  }

  actualizarPaginasVisibles() {
    if (this.isDesktop) {
      this.pages = Array.from({ length: this.totalPaginas }, (_, i) => i + 1);
    } else {
      const start = Math.max(this.numberActive - Math.floor(this.visiblePages / 2), 1);
      const end = Math.min(start + this.visiblePages - 1, this.totalPaginas);
      this.pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  }
  seleccionarCarrera(item: any) {
    this.buscador.get('filtro').setValue(item.nombre);
    this.carreraSeleccionada = item
    this.carreras = [];
  }
  destacadas = true;


  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }
  updateFilter(field: string, value: any) {
 
    this.buscador.get(field).setValue(value);
    this.onSearch();
  }
  updateVisiblePages(): void {
    const totalPages = this.pages.length;
    const currentPage = this.numberActive;
    let startPage = 1;
    let endPage = Math.min(10, totalPages);
    if (totalPages > 10) {
      if (currentPage > 6) {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - 9;
        }
      }
    }
    this.visiblePages2 = [];
    for (let i = startPage; i <= endPage; i++) {
      this.visiblePages2.push(i);
    }

    if (startPage > 1) {
      if (startPage > 2) {
        this.visiblePages2.unshift('...');
      }
      this.visiblePages2.unshift(1);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.visiblePages2.push('...');
      }
      this.visiblePages2.push(totalPages);
    }
  }

  cambiarPagina(page, first, scrollear) {
    this.checkDeviceType();

    this.pages = [];
    const modalidad = this.buscador.get('modalidad').value;
    const resultadoFiltrado = this.filtros(modalidad);
    this.carreras = (this.showPagination) ? resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize) : resultadoFiltrado;
    let cant = resultadoFiltrado.length / this.pageSize;
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    page = (page == '...') ? (this.numberActive) : page

    this.totalPaginas = Math.ceil(resultadoFiltrado.length / this.pageSize);
    this.numberActive = page;
    this.updateVisiblePages();
    /* this.actualizarPaginasVisibles(); */
    this.spinner = false;
    if (!first && scrollear || !this.isDesktop && scrollear) {
      this.busqueda.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.resultados;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }
    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  verInfo(carrera: any) {
    if (this.filaExpandida === carrera) {
      this.filaExpandida = null;
    } else {
      this.filaExpandida = carrera;
    }
  }


  checkDeviceType() {
    this.isDesktop = window.innerWidth >= 768;
    if (!this.isDesktop) {
      this.pageSize = 10
    }

    if (this.isDesktop) {
      let height = $(".buscador-container").outerHeight();
      if(this.sede){
        $(".breadcump").css("margin-top", "-" + this.getMarginTop(height));
      }else{
        $(".resultados").css("margin-top", "-" + this.getMarginTop(height));
      }
      $(".buscador-container").css("padding", this.padding);
      $(".buscador-container").css("transform", "translateY(-" + this.translateY + ")");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "5px" : "-90px");
    } else {
      $(".buscador-container").css("padding", this.paddingMob + "px");
      $(".buscador-container").css("transform", "translateY(-" + 0 + "px)");
      $(".resultados").css("margin-top", (this.resultados.length == 0) ? "0px" : "20px");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "0px" : "-90px");

    }
    this.spaceBetweenElements();


  }
  getMarginTop(height: any) {
    let marginTopResultados = '0px'
    if (this.translateY.includes("%")) {
      let porcentaje = parseFloat(this.translateY) / 100;
      marginTopResultados = (height * porcentaje) - 15 + "px";
    }
    return marginTopResultados;
  }

  spaceBetweenElements() {

    if (this.carreras.length == 0) {
      $(".fondo-area").css("margin-top", "0px");
      $(".fondo-area").css("padding-top", "0px");
      $(".resultados-principal").css("margin-top", "-140px");
      $(".resultados ul").css("padding", "0px!important");

    } else {
      let altura = $('.buscador-container').outerHeight();
      $(".fondo-area").css("margin-top", "-160px");
      $(".fondo-area").css("padding-top", "180px");
      $(".resultados ul").css("padding", "14px 0px 0px 0px");

    }
  }

  trackEvent() {
    (window as any).lintrk('track', { conversion_id: 21666209 });
  }
}


