import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-uno',
  templateUrl: './general-uno.component.html',
  styleUrls: ['./general-uno.component.scss']
})
export class GeneralUnoComponent implements OnInit {
  //general solo titulo y body
  datos: any;
  itemsChildren: any[] = [];
  carousel = false;
  archivo: any;
  constructor(private aRouter: ActivatedRoute, private services: GeneralService) {
    var url = window.location.href;
    if(url.includes('/es/')){
      var hostname = environment.frontUrl + '/es/';

    }else{
      var hostname = environment.frontUrl + '/es/';
    }
    var id = url.replace(hostname, '');
    this.services.getSimplepage(id).subscribe(data => {
      this.datos = data.data[0];
      if (this.datos.body) {
        document.getElementById('body').innerHTML = this.datos.body;
      }
      if (this.datos.attachItems) {
        this.archivo = this.datos.attachItems[0];
      }
      if (this.datos.children) {
        this.datos.children.forEach(element => {
          
          this.itemsChildren.push(element)
        });
      }
    
      if (this.datos.parent[0].children) {
        this.datos.parent[0].children.forEach(element => {
          this.itemsChildren.push(element)
        });
      }

      if (this.datos.headerSection) {
        this.datos.headerSection.headerSection.headerSectionItem.forEach(element => {
          this.itemsChildren.push(element)
        });
      }
    })
  }

  ngOnInit(): void {
  }
  url(element:any){
    let url='';
    if(element.route){
      url=element.route
      if(element.route[0]=='/'){
        url=element.route=element.route.replace('/','')
      }
      url='/es/'+url;
    }
    if(element.systemTitle){
      url=element.systemTitle
      if(element.systemTitle[0]=='/'){
        url=element.systemTitle=element.systemTitle.replace('/','')
      }
      url='/es/'+url;
    }
  
  return url;
  }

}
