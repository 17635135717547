import { Component, Input, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  @Input() images: any[] = [];
  @Input() marginDesk: string = "0px";
  @Input() marginMob: string = "0px";
  apiUrl = environment.apiUrl;
  isDesktop: boolean;
  language: any;
  intervalId: any = null;
  intervalIdClear: any = null;
  currentIndex: number = 0;
  imagesOriginal: any[] = [];
  bullets: any[] = [];
  bulletIndex = 0;

  @ViewChild('sliderContainer', { static: true }) sliderContainer!: ElementRef;
  isVisible = false;

  constructor(private aRouter: ActivatedRoute) {
    this.language = (this.aRouter.snapshot.params['language']) ? this.aRouter.snapshot.params['language'] : 'es';
    this.isDesktop = window.innerWidth > 768;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768;
  }
  @HostListener('window:scroll', [])
  onScroll(): void {
    this.checkVisibility(); 
  }


  ngOnInit(): void {
    this.imagesOriginal = this.images;
    this.bullets = [...this.images];
    this.checkVisibility(); // Verifica visibilidad al inicio
    this.startClear()
  }




  nextSlider() {
    this.images.push(this.imagesOriginal[this.currentIndex % this.imagesOriginal.length]);

    this.currentIndex++;

    if (this.currentIndex >= this.imagesOriginal.length) {
      this.currentIndex = 0;
    }

    this.bulletIndex = (this.bulletIndex == (this.bullets.length - 1)) ? 0 : this.bulletIndex + 1;   
  }

  setPosition(index: number): void {
    let diff = index - this.bulletIndex;

    if (diff === 0) return;

    let steps = Math.abs(diff);

    clearInterval(this.intervalId);
    this.intervalId = null;
    let stepCount = 0;
    let interval = setInterval(() => {
      if (stepCount >= steps) {
        clearInterval(interval);
        this.startSlider();
        return;
      }

      this.nextSlider();
      stepCount++;
    }, 50);
  }


  checkVisibility(): void {
    if (this.sliderContainer) {
      const rect = this.sliderContainer.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      this.isVisible = rect.top < windowHeight && rect.bottom > 0;

      if (this.isVisible) {
        this.startSlider(); // Solo inicia el slider si no está en ejecución
      } else {
        if (this.intervalId) {
          clearInterval(this.intervalId); // Detiene el intervalo si el componente no está visible
          this.intervalId = null; // Asegúrate de que el intervalo se haya detenido
        }
      }
    }
  }


  startSlider(): void {
    if (this.intervalId) return;
    this.intervalId = setInterval(() => {
      this.nextSlider()
    }, 5000);
  }

  startClear(): void {
    if (this.intervalIdClear) return;
    this.intervalIdClear = setInterval(() => {
      if(this.images.length > (this.bullets.length * 5)){
        this.images = [...this.bullets];
        this.imagesOriginal = [...this.bullets];
        this.currentIndex = 0;
        this.bulletIndex = 0;
      }
    }, 300000);
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdClear);
  }
}
