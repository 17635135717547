import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';

@Component({
  selector: 'app-noticias-general',
  templateUrl: './noticias-general.component.html',
  styleUrls: ['./noticias-general.component.scss']
})
export class NoticiasGeneralComponent implements OnInit {
  datos: any;
  language: any;
  nameNews: any;
  constructor(private aRouter: ActivatedRoute, private services: GeneralService, private redirectService: RedirectService) {
    this.services.getNoticiasDetalle(this.aRouter.snapshot.params['id'], 'post').subscribe(data => {
      if (data.data) {
        this.datos = data.data[0];
        document.getElementById('body').innerHTML = this.datos.body;
        this.aRouter.params.forEach((params: Params) => {
          this.language = params["language"];
          this.nameNews = params["id"];
        })
      } else {
        this.redirectService.toHome();
      }
      console.log("NOTICIA", data.data[0])

    })
  }

  ngOnInit(): void {
  }

}
