import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultados-cursos',
  templateUrl: './resultados-cursos.component.html',
  styleUrls: ['./resultados-cursos.component.scss']
})
export class ResultadosCursosComponent implements OnInit {
  datos:any={};
  constructor() { 
    this.datos.areas_tematicas=[]
    this.datos.areas_tematicas.push({name:"Area 1",estado:true})
    this.datos.areas_tematicas.push({name:"Area 2",estado:true})
    this.datos.areas_tematicas.push({name:"Area 3",estado:true})
    this.datos.areas_tematicas.push({name:"Area 4",estado:true})
    this.datos.areas_tematicas.push({name:"Area 5",estado:true})
    this.datos.areas_tematicas.push({name:"Area 6",estado:true})
    this.datos.areas_tematicas.push({name:"Area 7",estado:true})

    this.datos.whatsapp='...'
    this.datos.instagram='...'
    this.datos.facebook='...'

  }

  ngOnInit(): void {
  }

}
