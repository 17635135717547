<ng-container *ngIf="events">

    <!-- ................................................................................................... -->
    <!-- .........................................ENERO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '01'">
  
      <div class="overflow-mobile">
        <table id="month-1" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-4"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-5"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-6"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-7"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-12"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-13"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-14"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-19"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-20"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-21"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-26"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-27"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-28"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-30"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-1-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................FEBRERO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '02'">
  
      <div class="overflow-mobile">
        <table id="month-2" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-2"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-3"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-4"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-9"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-10"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-11"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-16"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-17"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-18"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-23"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-24"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-25"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-28"></app-calendar-check>
            </td>
            <td *ngIf="isLeap">
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-2-29"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................MARZO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '03'">
  
      <div class="overflow-mobile">
        <table id="month-3" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-2"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-3"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-4"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-9"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-10"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-11"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-16"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-17"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-18"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-23"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-24"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-25"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-28"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-30"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-3-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................ABRIL..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '04'">
  
      <div class="overflow-mobile">
        <table id="month-4" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="bold pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-1"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-4"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-6"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-7"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-8"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-13"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-14"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-15"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-20"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-21"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-22"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-27"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-28"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-29"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-4-30"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
    <!-- ................................................................................................... -->
    <!-- .........................................MAYO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '05'">
  
      <div class="overflow-mobile">
        <table id="month-5" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-3"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-4"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-5"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-6"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-11"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-12"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-13"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-18"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-19"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-20"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-25"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-26"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-27"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-28"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-30"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-5-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................JUNIO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '06'">
  
      <div class="overflow-mobile">
        <table id="month-6" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-1"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-2"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-3"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-4"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-8"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-9"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-10"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-15"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-16"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-17"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-22"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-23"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-24"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-28"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-29"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-6-30"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
    <!-- ................................................................................................... -->
    <!-- .........................................JULIO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '07'">
  
      <div class="overflow-mobile">
        <table id="month-7" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="bold pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-1"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-4"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-6"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">07</p>
              <app-calendar-check [events]="events" check="{{year}}-7-7"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-8"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-13"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-14"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-15"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-20"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-21"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-22"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-27"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-28"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-29"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-30"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-7-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................AGOSTO..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '08'">
  
      <div class="overflow-mobile">
        <table id="month-8" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-3"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-4"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-5"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-10"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-11"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-12"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-17"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-18"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-19"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-24"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-25"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-26"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-28"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-30"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-8-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
    <!-- ................................................................................................... -->
    <!-- .........................................SEPTIEMBRE..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '09'">
  
      <div class="overflow-mobile">
        <table id="month-9" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="bold pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-1"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-2"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-4"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-7"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-8"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-9"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-14"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-15"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-16"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-21"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-22"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-23"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-28"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-29"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-9-30"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................OCTUBRE..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '10'">
  
      <div class="overflow-mobile">
        <table id="month-10" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-2"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-4"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-5"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-6"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-7"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-9"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-12"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-13"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-14"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-16"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-19"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-20"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-21"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-23"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-26"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-27"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-28"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-30"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-10-31"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................NOVIEMBRE..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '11'">
  
      <div class="overflow-mobile">
        <table id="month-11" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-1"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-2"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-3"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-4"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-7"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-8"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-9"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-10"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-11"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-14"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-15"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-16"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-17"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-18"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-21"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-22"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-23"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-24"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-25"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-28"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-29"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-11-30"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
  
    </ng-container>-->
  
  
    <!-- ................................................................................................... -->
    <!-- .........................................DICIEMBRE..................................................... -->
    <!-- ................................................................................................... -->
  
    <!--<ng-container *ngIf="month == '12'">
  
      <div class="overflow-mobile">
        <table id="month-12" class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let item of daysBefore">
              <p *ngIf="item != 6" class="pl-2"></p>
              <p *ngIf="item == 6" class="bold pl-2"></p>
            </td>
            <td>
              <p class="bold pl-2">01</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-1"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">02</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-2"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">03</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-3"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">04</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-4"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">05</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-5"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">06</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-6"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">07</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-7"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">08</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-8"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">09</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-9"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">10</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-10"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">11</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-11"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">12</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-12"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">13</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-13"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">14</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-14"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">15</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-15"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">16</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-16"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">17</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-17"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">18</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-18"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">19</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-19"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">20</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-20"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">21</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-21"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">22</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-22"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">23</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-23"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td>
              <p class="pl-2">24</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-24"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">25</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-25"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">26</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-26"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">27</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-27"></app-calendar-check>
            </td>
            <td>
              <p class="pl-2">28</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-28"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">29</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-29"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">30</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-30"></app-calendar-check>
            </td>
            <td>
              <p class="bold pl-2">31</p>
              <div class="day-content"></div>
              <app-calendar-check [events]="events" check="{{year}}-12-30"></app-calendar-check>
            </td>
            <td *ngFor="let item of daysAfter">
              <p *ngIf="item == daysAfter || item == (daysAfter - 1)" class="bold pl-2"></p>
              <p *ngIf="item != daysAfter && item != (daysAfter - 1)" class="pl-2"></p>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>-->
  
    <ng-container>
      <div class="overflow">
        <table class="w-100 calendar-month">
          <tr class="encabezado c-principal" style="border-bottom:2px solid #1e3e6f">
            <td>LUNES</td>
            <td>MARTES</td>
            <td>MIÉRCOLES</td>
            <td>JUEVES</td>
            <td>VIERNES</td>
            <td class="bold">SÁBADO</td>
            <td class="bold">DOMINGO</td>
          </tr>
  
          <tr class="days">
            <td *ngFor="let day of week1; let i = index" [attr.data-index]="i">
              <p *ngIf="day == 0 && i != 6 " class="pl-2"></p>
              <p *ngIf="day == 0 && i == 6" class="bold pl-2"></p>
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td *ngFor="let day of week2; let i = index" [attr.data-index]="i">
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td *ngFor="let day of week3; let i = index" [attr.data-index]="i">
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
          <tr class="days">
            <td *ngFor="let day of week4; let i = index" [attr.data-index]="i">
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
          <tr class="days" *ngIf="week5">
            <td *ngFor="let day of week5; let i = index" [attr.data-index]="i">
              <p *ngIf="day == 0 && i != 6 " class="pl-2"></p>
              <p *ngIf="day == 0 && i == 6" class="bold pl-2"></p>
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
          <tr class="days" *ngIf="week6">
            <td *ngFor="let day of week6; let i = index" [attr.data-index]="i">
              <p *ngIf="day == 0 && i != 6 " class="pl-2"></p>
              <p *ngIf="day == 0 && i == 6" class="bold pl-2"></p>
              <p *ngIf="day != 0 && (i == 5 || i == 6)" class="bold pl-2">{{day}}</p>
              <p *ngIf="day != 0 && i != 5 && i != 6" class="pl-2">{{day}}</p>
              <div *ngIf="day != 0" class="day-content"></div>
              <app-calendar-check *ngIf="day != 0" [events]="events" check="{{year}}-{{monthNumber}}-{{day}}"></app-calendar-check>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
  </ng-container>
  