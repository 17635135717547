
<div mat-dialog-content >
    <div class="">
        <div class="modal-header text-center">
           
            <h2 class=' azul text-center'>{{title}}</h2>
          <div type="button" class="close" mat-dialog-close  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>
        <div id='html' class="modal-body ">
          
        </div>
      </div>
    </div>
 