import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-autoridades',
  templateUrl: './autoridades.component.html',
  styleUrls: ['./autoridades.component.scss']
})
export class AutoridadesComponent implements OnInit {
  datos: any;   
  carrer: any;
  maestria: any;
  language: any;
  venue: any;
  carreraTitulo: any;
  type: any;
  typeTitulo: any;
  venueTitulo: any;
  carrerTypes=["maestria","pregrado","profesorado","posgrado","ciclo-de-licenciatura","tecnicatura","carrera-de-grado","doctorado","postitulo","curso-de-posgrado","curso-online","especializacion","posgrado-online"];

  constructor(private router: Router, private services: GeneralService,public route: ActivatedRoute) { 
    this.route.params.forEach((params: Params) => {
      this.carrer = params['carrer'];
      this.carreraTitulo = this.carrer.replace(/-/g, " "); 
      this.carreraTitulo=this.carreraTitulo.replace(/^\w/, (c) => c.toUpperCase());
      this.type = params['type'];
      this.typeTitulo = this.type.replace(/-/g, " ");
      this.typeTitulo = this.typeTitulo.replace(/^\w/, (c) => c.toUpperCase());
      this.venue = params['venue'];
      this.venueTitulo = this.venue.replace(/-/g, " ");
      this.venueTitulo = this.venueTitulo.replace(/^\w/, (c) => c.toUpperCase());
      this.language =params['language'];
       if(this.carrerTypes.includes(this.type)){
        this.services.getAutoridades(this.carrer).subscribe(
          data =>{
            if(data.data){
              this.datos = data.data[0].authority;
              if (this.datos) {
                  const data = document.querySelector("#body");
                  for (const item of this.datos) {
                    const div = document.createElement("div");
                    let html_content ;
                    if(item.email =="null"){
                      html_content = ' <ng-container >' +
                      '<div class="row " >' +
                      '<div class="mt-2 pt-3 pl-3 pr-3 ml-3">' +
                      '   <img onerror="this.src="./assets/img/default.png" src="' + item.path + '">' +
                      '</div>' +
                      '<div class="col-md-8 pt-3 pl-3 pr-3" style="color: #1e3e6f">' +
                      '<p><b>' + item.name + '</b></p>' +
                      '<p><span>' + item.place + '</span></p>' +
                      '<p>' + item.venue + '</p>' +
                      '<div>' +
                      '<p style="line-height: 16px;font-size:12px">Email:' +
                      item.email + '</p>' +
                      '       </div>' +
                      '     </div>' +
                      '  </div>' +
                      '  </ng-container>';
                    }else{
                      html_content = ' <ng-container >' +
                      '<div class="row " >' +
                      '<div class="mt-2 pt-3 pl-3 pr-3 ml-3">' +
                      '   <img onerror="this.src="./assets/img/default.png" src="' + item.path + '">' +
                      '</div>' +
                      '<div class="col-md-8 pt-3 pl-3 pr-3" style="color: #1e3e6f">' +
                      '<p><b>' + item.name + '</b></p>' +
                      '<p><span>' + item.place + '</span></p>' +
                      '<p>' + item.venue + '</p>' +
                      '<div>' +
                      '<p style="line-height: 16px;font-size:12px">Email:' +
                      '' + '</p>' +
                      '       </div>' +
                      '     </div>' +
                      '  </div>' +
                      '  </ng-container>';
                    }
                    data.insertAdjacentHTML("beforeend",html_content);
                  }
    
              }
  
            }else{
              router.navigate(['404']);
              }
            
          },
          error => {
          }
        )
       }else{
        router.navigate(['404']);
       }
    });


  }

  ngOnInit(): void {
  }

}
