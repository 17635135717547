import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import { Patters } from 'src/app/shared/models/patters';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-video-mas-formulario-dos',
  templateUrl: './video-mas-formulario-dos.component.html',
  styleUrls: ['./video-mas-formulario-dos.component.scss']
})
export class VideoMasFormularioDosComponent implements OnInit {

  @Input() linkCode: string;
  @Input() linkForm: string;
  formDatos:FormGroup;
  seccion:string;
  id:string;
  typeOfCareer:string;
  video:any;
  formulario:any;
  constructor(private aRouter :ActivatedRoute,private _sanitizer: DomSanitizer,private form:FormBuilder, private services: GeneralService) {
   
    this.seccion = this.aRouter.snapshot.params['seccion']
    this.id = this.aRouter.snapshot.params['id']
    var url=window.location.href;
    var index=url.indexOf(this.seccion);
    var urlBase=url.slice(index+(this.seccion+'/').length,url.length)
    this.typeOfCareer=urlBase.replace('/'+this.id,'')
    this.formDatos = this.form.group({
      name: ['',Validators.required],
      email: ['', [Validators.email,Validators.required]],
      phone: ['',[Validators.pattern(Patters.OnlyNumber)]],
      region: ['',Validators.required],
    });

   }

  ngOnInit(): void {
    this.video=this.getVideoIframe(this.linkCode);
    this.formulario=this.getSanitize(this.linkForm);
  }
  consultar() {
    let datos = new ConsultaRequest();
    datos.venue=this.seccion;
    datos.career=this.id;
    datos.typeOfCareer=this.typeOfCareer;
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.phone = this.formDatos.get('phone').value;

    if (datos.venue && datos.career) {
      this.consultarEmblue(datos);
    }

    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )

  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?disablekb=1');
  }
  getSanitize(url){
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  consultarEmblue(datos: ConsultaRequest){
    let atributos = (
      {
        career: carrera,
        name: nombre,
        phone: whatsapp,
        region: sede
      }
    ) => ({ carrera ,nombre, whatsapp, sede });
    let eventoEmblue = {
      eventName: 'form_derecho',
      email: datos.email,
      attributes: atributos(datos)
    };
    if (datos.venue) {
      switch (datos.venue) {
        case "facultad-de-derecho":
          eventoEmblue.eventName = "form_derecho"
          break;
        case "facultad-de-psicologia-y-psicopedagogia":
          eventoEmblue.eventName = "form_psico"
          break;
        case "facultad-de-ciencias-medicas":
          eventoEmblue.eventName = "form_cs.medicas"
          break;
        case "facultad-de-ingenieria-y-ciencias-agrarias":
          eventoEmblue.eventName = "form_ingeniería"
          break;
        case "facultad-de-artes-y-ciencias-musicales":
          eventoEmblue.eventName = "form_musica"
          break;
        case "facultad-de-filosofia-y-letras":
          eventoEmblue.eventName = "form_filo"
          break;
        case "facultad-de-ciencias-economicas":
          eventoEmblue.eventName = "form_economicas"
          break;
        case "facultad-de-ciencias-sociales":
          eventoEmblue.eventName = "form_sociales"
          break;
      
        default:
          eventoEmblue.eventName = "form_derecho"
          break;
      }
    }
    this.services.postEmblueForm(eventoEmblue).subscribe(
      (data) => console.log({ message: 'data enviada', data: data}),
      error => console.log({ message: 'ocurrio un error', error: error })
    )
  }
}
