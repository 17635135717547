import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RegistroSlider } from 'src/app/shared/models/RegistroSlider';

@Component({
  selector: 'app-slider-posgrados',
  templateUrl: './slider-posgrados.component.html',
  styleUrls: ['./slider-posgrados.component.scss']
})
export class SliderPosgradosComponent implements OnInit {
  @Input() data: any[] = []
  @Input() datos: any;
  items: RegistroSlider[] = [];
  selected: RegistroSlider;
  positionItem = 0;
  positionItemLast = 0;
  constructor(private router: ActivatedRoute) {
  }
  ngOnInit(): void {
      let lista = this.data;
      lista.forEach(element => {
        let registro = new RegistroSlider();
        registro.foto = element.path;
        registro.fotoMovil = element.pathMobile;
        registro.id = element.id;
        registro.state = 'inactive'
        this.items.push(registro);

      });
    this.cambiarSlider(0)
    setInterval(() => {
      this.positionItem++;
      if (this.positionItem == (this.items.length)) {
        this.positionItem = 0;
      }
      this.cambiarSlider(this.positionItem);
    }, 10000);
  }

  cambiarSlider(i: number) {
    this.items.forEach(f => {
      f.state = 'inactive';
    })
    this.items[i].state = 'actived'
    this.positionItem = i;
  }
}
