
export class ConsultaRequest{
    email: string;
    isHome=true;
    curso=false;
    language= "es";
    message: string;
    name: string;
    region: string;
    career: string;
    phone:string;
    typeOfCareer:string;
    venue:string;
    entityId:number;
    subject:string;
    page:string;
    isPosgrado: boolean;
    mail_destinatario: string

}