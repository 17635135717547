import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-item-general',
  templateUrl: './text-item-general.component.html',
  styleUrls: ['./text-item-general.component.scss']
})
export class TextItemGeneralComponent implements OnInit {
  @Input() data:any;
  @Input() i:any;
  constructor() { }

  ngOnInit(): void {
   
  }
  ngAfterViewInit(): void {
    document.getElementById('text'+this.i).innerHTML=this.data;
  }

}
