<section class="subheader doble-m" *ngIf='spinner'>
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="noticia.path">
  <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="noticia.pathMobile">
  <div class="texto-s container">
    <div class="d-flex justify-content-center" *ngIf='spinner'>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

  </div>
</section>

<section class="subheader doble-m" *ngIf='!spinner'>
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="noticia.path">
  <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="noticia.pathMobile">
  <div class="texto-s container">
    <h1 class="azul">{{noticia.title}}</h1>
  </div>
</section>
<section class="agsec1">
  <div class='row'>
    <div class='col'>
      <p *ngIf="noticia.simplePageTitle" _ngcontent-c3="" class="fs-16 c-principal breadcump">
        <span _ngcontent-c3="" id="breadcump">
          <a [href]="language+'/' +noticia.simplePageSystemTitle" class="c-principal">
            <strong> {{noticia.simplePageTitle}} > </strong>
          </a>
          {{noticia.title}}
        </span>
      </p>
      <p *ngIf="noticia.venueTitle" _ngcontent-c3="" class="fs-16 c-principal breadcump">
        <span _ngcontent-c3="" id="breadcump">
          <a [href]="language+'/facultades/' +noticia.venueSystemTitle" class="c-principal">
            <strong> {{noticia.venueTitle}} > </strong>
          </a>
          {{noticia.title}}
        </span>
      </p>
    </div>
  </div>
</section>

<section class="agsec1 " style="padding-top:0px!important;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div id='body'></div>
      </div>
      <div class="col-lg-12">
        <p style='margin: 0px;' *ngIf='noticia.publicationAt'>Publicado el {{noticia.publicationAt.date |
          date:'d/M/yy'}}</p>
        <p style='margin: 0px;' *ngIf='noticia.venueTitle'>Publicado por {{noticia.venueTitle}}</p>
      </div>
    </div>
  </div>
</section>