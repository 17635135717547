import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-info-posgrado-card',
  templateUrl: './info-posgrado-card.component.html',
  styleUrls: ['./info-posgrado-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoPosgradoCardComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() title: any[] = [];
  @Input() color;

  list: any[] = [];
  constructor() {
    
  }

  ngOnInit(): void {
  
    this.data.forEach(d => {
      if (d.title !== "" && d.body !== "") {
        this.list.push(d)
      }
    })
  }

  ngAfterContentChecked(): void {
    
    this.list.forEach(d => {
      if (document.getElementById(d.title) && document.getElementById(d.title).innerHTML == "") {
        let content=document.getElementById(d.title).parentNode.parentNode.parentElement;
        content.style.border="1px solid "+this.color;
        const headerLabels=document.querySelectorAll<HTMLElement>(".mat-tab-label");
        for(let i=0 ;headerLabels.length>i;i++ ){
          headerLabels[i].style.color="white";
          headerLabels[i].style.borderTop="1px solid "+this.color;
          headerLabels[i].style.borderLeft="1px solid "+this.color;
          headerLabels[i].style.borderRight="1px solid "+this.color;
          headerLabels[i].style.borderBottom="1px solid "+this.color;
        }
  
        const headerLabel=document.querySelectorAll<HTMLElement>(".mat-tab-label-active")[0];
        headerLabel.style.color=this.color;
        headerLabel.style.borderTop="1px solid "+this.color;
        headerLabel.style.borderLeft="1px solid "+this.color;
        headerLabel.style.borderRight="1px solid "+this.color;
        headerLabel.style.borderBottom="1px solid "+this.color;
        document.getElementById(d.title).innerHTML = d.body;
      }
    })
  }

  

  tabChanged($event){
    const headerLabels=document.querySelectorAll<HTMLElement>(".mat-tab-label");
    for(let i=0 ;headerLabels.length>i;i++ ){
      headerLabels[i].style.color="white";
      headerLabels[i].style.borderTop="1px solid "+this.color;
      headerLabels[i].style.borderLeft="1px solid "+this.color;
      headerLabels[i].style.borderRight="1px solid "+this.color;
      headerLabels[i].style.borderBottom="1px solid "+this.color;
    }

    const headerLabel=document.querySelectorAll<HTMLElement>(".mat-tab-label-active")[0];
    headerLabel.style.color=this.color;
    headerLabel.style.borderTop="1px solid "+this.color;
    headerLabel.style.borderLeft="1px solid "+this.color;
    headerLabel.style.borderRight="1px solid "+this.color;
    headerLabel.style.borderBottom="1px solid "+this.color;
  }
}
