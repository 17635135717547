<div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2 [class]='styleTitle'>La UCA en números</h2> 
      </div>
      <div class="num-cont">
        <div class="num-item" *ngFor="let item of ListaNumeros">
          <img onerror="this.src=''" [src]="item.img" />
          <div class="cole-text">
            <h4>{{item.numero}}</h4>
            <p>{{item.titulo}}</p>
          </div>
        </div>
       
      </div>
    </div>
  </div>