import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { CarrouselImgComponent } from '../../components/carrousel-img/carrousel-img.component';

@Component({
  selector: 'app-general-gallery',
  templateUrl: './general-gallery.component.html',
  styleUrls: ['./general-gallery.component.scss']
})
export class GeneralGalleryComponent implements OnInit {
  @Input() datos: any;
  itemsheaderSection: any[] = [];
  itemsChildren: any[] = [];
  links: any[] = [];
  titleList: string;
  archivo: any;
  language: any;
  titleLink: any;
  itemCarrouselHeader: any[] = [];
  itemsInfo: any[] = [];
  title: any;
  pathMob: any;
  path: any;

  viewContainerRefs: any[] = []
  constructor(private router: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver, public route: ActivatedRoute, public services: GeneralService) {
    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];
    });

    this.router.url.subscribe((segments: UrlSegment[]) => {
      segments.forEach((segment: UrlSegment) => {
        this.titleLink += "/" + segment.path;
      });
    });
  }
  ngOnInit(): void {
    if (this.datos['type'] === "gallery") {
      this.title = this.datos.title;
      this.pathMob = this.datos.pathMobile;
      this.path = this.datos.path;
      if(this.datos.children){
        this.itemCarrouselHeader=this.datos.children.slice(0,6);
      }
      if(this.datos.headerSection){
        this.itemsInfo=this.datos.headerSection.headerSection.headerSectionItem

      }
    }
  }
  url(element: any) {
    let url = '';
    if (element.route) {
      if(element.externalLink){
        url = element.route;

      }else{
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if(element.externalLink){
        url = element.systemTitle;

      }else{
      if (element.systemTitle[0] == '/') {
        url = element.systemTitle = element.systemTitle.replace('/', '')
      }
      url = '/' + this.language + '/' + url;
    }
    }

    return url;
  }
}
