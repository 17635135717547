import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contacto-shell',
  templateUrl: './contacto-shell.component.html',
  styleUrls: ['./contacto-shell.component.scss']
})
export class ContactoShellComponent implements OnInit {
  formDatos: FormGroup;
  constructor(private form: FormBuilder, private services: GeneralService) {
    this.formDatos = this.form.group({
      name: ['',[Validators.required]],
      email: ['',[Validators.email,Validators.required] ],
      region: ['',[Validators.required]],
      message: [''],
    });
  }

  ngOnInit(): void {
  }
  consultar() {
    let datos = new ConsultaRequest();
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.message = this.formDatos.get('message').value;
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        this.formDatos.reset()
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )

  }
}

