import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard2 } from '../../models/registroNoticia';

@Component({
  selector: 'app-cards-noticias',
  templateUrl: './cards-noticias.component.html',
  styleUrls: ['./cards-noticias.component.scss']
})
export class CardsNoticiasComponent implements OnInit {

  Noticias:RegistroCard2[]=[];
  noticiaActive:string;
  constructor(private servicioGeneral:GeneralService) { 
     this.servicioGeneral.getNoticias().subscribe(data=>{
      let lista =data.data[0]['entityList'][0].entity;
      this.noticiaActive=lista[0].id;
      lista.forEach(element => {
         let registro=new RegistroCard2();
         registro.title=element.title;
         registro.foto=element.path;
         registro.id=element.id;
         registro.categoria=element.categoryTitle;
         registro.link=element.path;
         registro.systemTitle='es/noticias/'+element.typeEntity.toLowerCase()+'/'+element.systemTitle;
         this.Noticias.push(registro);

      });


    })


  }

  ngOnInit(): void {
  }
  cambiarSlider(i:number){
    this.noticiaActive=this.Noticias[i].id;

  }

}
