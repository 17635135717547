import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';
import { RegistroAgenda } from 'src/app/shared/models/registroAgenda';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eventos-dos',
  templateUrl: './eventos-dos.component.html',
  styleUrls: ['./eventos-dos.component.scss']
})
export class EventosDosComponent implements OnInit {

  agenda: any;
  Agenda: RegistroAgenda[] = [];
  noticiaActive: string;
  month: any;
  year: any;
  language: any;
  constructor(public route: ActivatedRoute, private services: GeneralService, private redirectService: RedirectService) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();

    var url = window.location.href;
    var hostname = environment.frontUrl + '/es/eventos/';
    var id = url.replace(hostname, '');
    this.services.getAgendaDetalle(id).subscribe(data => {
      if (data.data) {
        this.agenda = data.data[0];
        document.getElementById('body').innerHTML = this.agenda.body;
        this.noticiaActive = this.agenda.otherEvent[0].id;

        this.agenda.otherEvent.forEach(element => {
          let registro = new RegistroAgenda();
          registro.title = element.title;
          registro.date = element.startOn.date;
          registro.hours = element.startOn.date;
          registro.id = element.id;
          registro.link = '/eventos/' + element.systemTitle
          this.Agenda.push(registro);

        });
      } else {
        this.redirectService.toHome();
      }

    })
  }

  ngOnInit(): void {
  }
  cambiarSlider(i: number) {
    this.noticiaActive = this.Agenda[i].id;

  }

}
