import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuDefault = false;
  language = 'es';
  @Output() sidenavToggle = new EventEmitter<void>();
  menu: any;
  header: any;
  ultimoSubMenu: any[] = [];
  constructor(private general: GeneralService, private route: Router, private router: ActivatedRoute) {
    route.events.subscribe((val) => {
      var x = document.getElementById("myTopnav");
      x.classList.remove("responsive");
      let matches = document.querySelectorAll<HTMLElement>('.ul-menu')
      for (var i = 0; i < matches.length; i++) {
        matches[i].style.display = 'none';
      }

      let matches2 = document.querySelectorAll<HTMLElement>('.li-parent-mob')
      for (var i = 0; i < matches2.length; i++) {
        matches2[i].classList.remove('show');
      }
    })
    this.router.params.forEach((params: Params) => {
      this.language = (params['language']) ? params['language'] : 'es';
      this.general.getItemsHeader(params['language']).subscribe(header => {
        const menu = header.data[0].menuItem;
        let menu2 = [];
        menu.forEach(parent => {
          let parent2 = Object.assign({}, parent)
          parent2.menuItem = [];
          parent.menuItem.forEach(child => {
            let child2 = Object.assign({}, child)
            var i = 0;
            let indexMenuItem = 0;
            child2.menuItem = []
            child2.menuItem[indexMenuItem] = []
            child.menutItem.forEach(subchild => {
              let subchild2 = Object.assign({}, subchild)
              if (child2.menuItem[indexMenuItem].length == 10) {
                indexMenuItem++;
                child2.menuItem[indexMenuItem] = []
                child2.menuItem[indexMenuItem].push(subchild2)
              } else {
                child2.menuItem[indexMenuItem].push(subchild2)
              }
              i++;
            });
            parent2.menuItem.push(child2)

          });
          menu2.push(parent2)
        });
        this.menu = menu2;
      })
    });



  }

  ngOnInit() {


    jQuery(document).on("mouseover", ".dropdown-h", function (e) {
      let offsetLeft = $(this).parent().parent()[0].offsetLeft;
      let offsetTop = (Number($(this)[0].offsetTop) + 68) - Number($(this).parent()[0].scrollTop);
      let pageHeight = Number(window.innerHeight) - offsetTop;

      let parent = $(this)[0];
      let matchesParent = document.querySelectorAll<HTMLElement>('.class-' + parent.id)
      let matchesTotal = document.querySelectorAll<HTMLElement>('.child')

      var elementStyle1 = window.getComputedStyle(parent);
      var elementStyle2 = window.getComputedStyle(matchesParent[0]);

      var widthNivelUno = Number(elementStyle1.getPropertyValue('width').replace('px', ''));
      var widthNivelDos = Number(elementStyle2.getPropertyValue('width').replace('px', '')) - 1;
      for (var i = 0; i < matchesTotal.length; i++) {
        matchesTotal[i].style.display = 'none';
      }
      for (var i = 0; i < matchesParent.length; i++) {
        let position = Number(matchesParent[i].id.replace('index-', ''));
        matchesParent[i].style.maxHeight = pageHeight.toString() + 'px';
        matchesParent[i].style.display = 'block';
        matchesParent[i].style.top = offsetTop + 'px';
        let v = ($(this).parent()[0].scrollHeight > $(this).parent()[0].clientHeight) ? 10 : 0;
        matchesParent[i].style.left = ((widthNivelUno + offsetLeft + v + (widthNivelDos * position))).toString() + 'px';
      }

    });
    jQuery(document).on("mouseleave", ".ul-menu", function (e) {
      let matchesTotal = document.querySelectorAll<HTMLElement>('.child')
      for (var i = 0; i < matchesTotal.length; i++) {
        matchesTotal[i].style.display = 'none';
      }
    });
    jQuery(document).on("click", ".li-parent-mob", function (e) {
      $(this).children().toggleClass('show');
    });

    /*jQuery(document).on("click", ".dropdown-h-mob", function (e) {
       console.log($(this).children())
      $(this).parent().toggleClass('show');
      $(this).children().toggleClass('show');
    });*/
    /* jQuery(document).on("click", ".arrow-sub", function (e) {
      let id = "lu-" + $(this)[0].id
      $('#' + id).toggleClass('show');
      $('#' + id).parent().parent().toggleClass('show');

    });*/

    jQuery(document).on("click", ".mob.label-menu ", function (e) {
      let href = $(this).attr('href');
      if (href == undefined) {
        e.preventDefault();
        $(this).parent().toggleClass('show');
      }
    });
  }

  arrow(e: string) {
    let id = "lu-" + e;
    console.log(id)
    console.log($('#' + id))
    $('#' + id).toggleClass('show');
    $('#' + id).parent().parent().toggleClass('show');
  }
  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive ";
    } else {
      x.className = "topnav";
    }
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }


  buscarWeb() {
    var valor = ((document.getElementById("buscadorWeb") as HTMLInputElement).value);
    this.route.navigate(['busqueda/' + valor])

  }
  buscarMob() {
    var valor = ((document.getElementById("buscadorMob") as HTMLInputElement).value);
    this.route.navigate(['busqueda/' + valor])


  }
  S

  goToUrl(url: string): void {
    document.location.href = url;
  }
  ir(url) {
    this.route.navigate([this.language + '/' + url])
  }
  cerrarContacto() {
    $('.li-parent-mob').removeClass('show');
  }

}
