import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-slider-cards',
  templateUrl: './slider-cards.component.html',
  styleUrls: ['./slider-cards.component.scss']
})
export class SliderCardsComponent implements OnInit {
  @Input() cards:any[]=[];
  @Input()  title:string;
  constructor(private services:GeneralService) { 
    this.services.getEntitylist('269','compromiso-social').subscribe(data=>{
      this.cards=data.data[0].entityList[0].entity;
 
}) 
  }

  ngOnInit(): void {
 
  }

}
