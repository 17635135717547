<div class="container mt-4">
  <div class="row">
    <div class="col-lg-12 text-center">
      <h2 [class]='styleTitle' style="margin-bottom:10px;">{{title}}</h2>
    </div>
  </div>
  <div class=" desk mt-3">
    <div class='row'>
      <div class="col col-9">
        <a id="card" [href]='ListaUCActualidad[0].link' *ngIf='ListaUCActualidad[0]'>
          <div class="actua-item item-max  grid-item ">
            <img onerror="this.src='./assets/img/default.png'" [src]="ListaUCActualidad[0].foto" />
            <p>{{ListaUCActualidad[0].title}}</p>
          </div>
        </a>

      </div>
      <div class="col col-3">
        <a id="card"[href]='ListaUCActualidad[1].link' *ngIf='ListaUCActualidad[1]'>
          <div class="actua-item item-mini grid-item ">
            <img onerror="this.src='./assets/img/default.png'" [src]="ListaUCActualidad[1].foto" />
            <p>{{ListaUCActualidad[1].title}}</p>
          </div>
        </a>


        <a id="card" [href]='ListaUCActualidad[2].link' *ngIf='ListaUCActualidad[2]'>
          <div class="actua-item item-mini  grid-item ">
            <img onerror="this.src='./assets/img/default.png'" [src]="ListaUCActualidad[2].foto" />
            <p>{{ListaUCActualidad[2].title}}</p>
          </div>
        </a>

        <a id="card" [href]='ListaUCActualidad[3].link' *ngIf='ListaUCActualidad[3]'>
          <div class="actua-item item-mini  grid-item ">
            <img onerror="this.src='./assets/img/default.png'" [src]="ListaUCActualidad[3].foto" />
            <p>{{ListaUCActualidad[3].title}}</p>
          </div>
        </a>

      </div>
    </div>

    <div class='row'>
      <div class="col col-4" *ngFor="let item of ListaUCActualidad;index as i ">
        <a [href]='item.link' *ngIf='i>3'>
          <div  class="actua-item grid-item">
            <img onerror="this.src='./assets/img/default.png'" [src]="item.foto" />
            <p>{{item.title}} </p>
          </div>
        </a>

      </div>

    </div>



  </div>
  <div class="actua-cont mob">

    <div id="actua-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
      <ol class="carousel-indicators">
        <li *ngFor='let item of ListaUCActualidad;index as i ' data-target="Noticias-s" (click)="cambiarSlider(i)"
          [ngClass]='(item.id== ActividadActive)?"active" : ""'></li>

      </ol>
      <div class="carousel-inner">

        <div class="carousel-item " *ngFor='let item of ListaUCActualidad'
          [ngClass]='(item.id== ActividadActive)?"active" : ""'>
          <div class="actua-item grid-item">
            <img onerror="this.src='./assets/img/default.png'" [src]="item.foto" />
            <p>{{item.title}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>