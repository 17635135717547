import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard } from 'src/app/shared/models/registroComunidad';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-otras-carreras',
  templateUrl: './otras-carreras.component.html',
  styleUrls: ['./otras-carreras.component.scss']
})
export class OtrasCarrerasComponent implements OnInit {
  carrera: string;
  @Input() data: any;
  cards: RegistroCard[] = [];

  constructor(private aRouter:ActivatedRoute){
   this.carrera=this.aRouter.snapshot.params['seccion']
  }
  ngOnInit(): void {
    this.data.forEach(element => {
      let registro = new RegistroCard();
      registro.titulo = element.title;
      registro.img = element.path;
      if (element.systemTitle[0] == '/') {
        element.systemTitle = element.systemTitle.replace('/', '')
      }
      registro.systemTitle = 'es/facultades/' + this.carrera + '/carrera-de-grado/' + element.systemTitle;
      this.cards.push(registro);
    });
  }
}
