<section class="subheader doble-m">
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path">
  <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="datos.pathMobile">
  <div class="texto-s container">
    <h1 class="azul ">{{datos.title}}</h1>
  </div>
</section>
<section class="agsec1">
  <div class="container">
    <div class="row">
      <div [ngClass]='(itemsChildren.length>0)?"col-lg-8" : "col-lg-12"'>

        <div  id='body'></div>
      </div>
      <div class="col-lg-4" *ngIf='itemsChildren.length > 0'>
        <ul class="list-group">
          <li class="list-group-item active ">Más información
          </li>
          <li *ngFor='let item of itemsChildren' class="list-group-item "><a
              [href]="url(item)">{{item.title}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section style='padding-top:0px!important;' class="" *ngIf='archivo'>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 _ngcontent-c15="" class="c-principal fs-32 bold ">Archivos: </h3>
        <div _ngcontent-c15="" class="row">
          <div _ngcontent-c15="" class="col-md-6">
            <a _ngcontent-c15=""
              href="http://wadmin.uca.edu.ar/public/20180416/1523887911_2017-Universidad-Historia-de-la-Universidad-Catolica-Argentina.pdf">
              <div _ngcontent-c15="" class="row">
                <div _ngcontent-c15="" class="col-md-2">
                  <img onerror="this.src='./assets/img/default.png'" _ngcontent-c15="" class="img-fluid mt-4"
                    src="http://wadmin.uca.edu.ar/public/filters/icon/images/pdf.png">
                </div>
                <div _ngcontent-c15="" class="col-md-10">
                  <p _ngcontent-c15="" class="c-principal fs-21 bold mt-3">Historia de la Universidad Católica Argentina
                  </p>
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>