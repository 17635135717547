import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menuItem = [];
  constructor(private general: GeneralService) {
    this.general.getItemsFooter('es').subscribe(footer => {
      var menuAux = footer.data[0].menu[0].menuItem;
      this.menuItem[0] = [];
      let index = 0;
      menuAux.forEach(element => {
        if(element.children.length > 5  || this.menuItem[index].count > 10){
          index++;
          this.menuItem[index]=[]
          this.menuItem[index].push(element);
          this.menuItem[index].count= element.children.length;

        }else{
          this.menuItem[index].push(element)
          this.menuItem[index].count = this.menuItem[index].count + element.children.length;

        }
      });
    })
  }

  ngOnInit() {
  }
}
