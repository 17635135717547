<div class="ingreso-container">
    <section class="subheader sub-video doble-m">
        <div class="overlay"></div>
        <img onerror="this.src='./assets/img/default.png'" *ngIf="!isMob" class="img-title w-100  img-desk" [src]="path">
        <img onerror="this.src='./assets/img/default.png'" *ngIf="isMob" class="img-title w-100  img-mob" [src]="pathMob">
        <img class="w-100 banda desk" src="./assets/img/banda-azul.png">
        <img class="w-100 banda mob" src="./assets/img/banda-azul-mob.png">

        <div class="texto-s " >
            <h4 class="subtitulo quicksand-medium"> Sede </h4>
            <h1 class="titulo quicksand-bold"> {{title}} </h1>
        </div>
    </section>
    <section class="buscador">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <app-buscador-carreras [showPagination]="false" [sede]="sedeFormater"></app-buscador-carreras>
                </div>
            </div>
        </div>
    </section>
   
</div>
<div class="row">

    <ng-container #componenteDinamico1></ng-container>
    <ng-container #componenteDinamico2></ng-container>
    <ng-container #componenteDinamico3></ng-container>
    <ng-container #componenteDinamico4></ng-container>
    <ng-container #componenteDinamico5></ng-container>
    <ng-container #componenteDinamico6></ng-container>
    <ng-container #componenteDinamico7></ng-container>
    <ng-container #componenteDinamico8></ng-container>
    <ng-container #componenteDinamico9></ng-container>
    <ng-container #componenteDinamico10></ng-container>


</div>