<section class="subheader doble-m">
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="agenda.path">
  <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="agenda.pathMobile">
  <div class="texto-s container">
    <h1 class="azul">{{agenda.title}}</h1>
  </div>
</section>

<section class="agsec1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="fecha">{{agenda.startOn.date| date: 'h:mm a'}} - {{agenda.endOn.date| date: 'h:mm a'}}</h2>
      </div>
      <div class="col-lg-8">
        <p><span class="azul">Fecha de inicio:</span> {{agenda.startOn.date| date: 'd/M/yy, h:mm a'}}</p>
        <p><span class="azul">Fecha de culminación:</span> {{agenda.endOn.date| date: 'd/M/yy, h:mm a'}}</p>
        <p>{{agenda.summary}}</p>
        <div id='body'></div>
        <p style='margin: 0px 0px 40px 0px;' > 
          <ng-container *ngIf='agenda.publishedBy.venue'>
            Publicado por {{agenda.publishedBy.venue.title}}
          </ng-container>
          <ng-container *ngIf='agenda.publishedBy.simplePage'>
            Publicado por {{agenda.publishedBy.simplePage.title}}
          </ng-container>
        </p>
      </div>

      <div class="col-lg-4">
        <h2>Agenda</h2>
        <div class="agenda-lat desk">
          <ng-container *ngFor='let item of Agenda'>
            <div  class="agenda-lat-item">
              <a class='pointer' [href]="item.link">
                <div class="fecha">{{item.date | date:'d/M/yy'}} <span>{{item.hours| date: 'h:mm a'}}</span></div>
                <div class="desc">{{item.title}}</div>
              </a>
            </div>
          </ng-container>
          <a [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
        </div>
        <div class="agenda-lat mob">
          <div id="agenda-s" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
            <ol class="carousel-indicators">
              <li *ngFor='let item of Agenda;index as i ' data-target="agenda-s" (click)="cambiarSlider(i)"
                [ngClass]='(item.id== noticiaActive)?"active" : ""'>
                {{item.title}}
              </li>

            </ol>
            <div class="carousel-inner">
              <div *ngFor='let item of Agenda' class="carousel-item "
                [ngClass]='(item.id== noticiaActive)?"active" : ""'>
                <div class="agenda-lat-item">
                  <a class='pointer' [href]="item.link">

                    <div class="fecha">{{item.date | date:'d/M/yy'}} <span>{{item.hours| date: 'h:mm a'}}</span></div>
                    <div class="desc">{{item.title}}</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a [href]="language+'/calendario/'+month+'/'+year" class="btn btn-verde blanco">Ver agenda completa</a>
        </div>
      </div>
    </div>
  </div>
</section>