<div class="container">
    <div class="row">
        <div class="col-lg-3 ">
            <h2 class='azul'>Seguinos en redes</h2>
            <div *ngFor='let r of redes' class='redes'>
                <div class="titulo">
                    <h5 class='gray fs-20'>{{r.sede}}</h5>
                </div>
                <div class='iconos'>
                        <a  style='cursor:pointer;color: #007bff;' (click)='windowOpen(r.instagram)'>
                            <img onerror="this.src='./assets/img/default.png'" class='icon'
                                src="../../../../../assets/img/instagram-logo-circle.png" alt="">
                        </a>
                        <a  *ngIf="r.sede !='Rosario'" style='cursor:pointer;color: #007bff;' (click)='windowOpen(r.facebook)'>
                            <img onerror="this.src='./assets/img/default.png'" class='icon'
                                src="../../../../../assets/img/facebook-circle.png" alt="">
                        </a>
                        <a style='cursor:pointer;color: #007bff;' (click)='windowOpen(r.whatsapp)'>
                            <img onerror="this.src='./assets/img/default.png'" class='icon'
                                src="../../../../../assets/img/whatsapp-logo-circle.png" alt="">
                        </a>
                </div>
            </div>
        </div>
        <div class="col-lg-5 " style='padding-left:8.3%;'>
            <h2 class='azul' style='margin-bottom:5px;'>Descarga la app</h2>
            <div style='display: flex;' class='text-center'>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-appStore.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/app-store.png"
                        alt="">

                </div>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-googlePlay.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/google-play.png"
                        alt="">
                </div>

            </div>

        </div>

        <div class="col-lg-4 text-center">
            <h2 class='azul'>Contactate con nosotros</h2>
            <a (click)='openModal()' class="btn btn-primary btn-contacto">Contacto</a>

        </div>
    </div>
</div>