

<section class="logsec1" >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
            <div *ngIf='data' [id]="'text'+i"></div>
        </div>
      </div>
    </div>
  </section>

