<div *ngFor="let nivel of array">
    <div style="display: flex;" class="title-container">
      <div [style.text-align]="'center'" 
      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
      [style.width]="'100%'"
     
      [style.margin]="'5px'"
      [style.margin-top]="'0px'"
      ></div>
      <h2 style="min-width: max-content;">
        {{ nivel.nivel }}
      </h2>
      <div [style.text-align]="'center'" 
      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
      [style.width]="'100%'"
     
      [style.margin]="'5px'"
      [style.margin-top]="'0px'"
      ></div>
    </div>
  
    <!-- <li class='mt-3 fs-21' *ngFor='let carrera of resultados'><a [href]="language +carrera.link"> {{carrera.nombre}} | {{carrera.modalidad}} </a></li> -->
    <div class="sede-container">
        <ng-container *ngFor="let carrera of nivel.carreras.carreras">
          
            <div class="sede" (click)="redirect(carrera)">
                <div class="sede-overlay">
                    <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img" onerror="this.src='../../../../assets/img/default.png'">
                    <img *ngIf="!carrera.pathMobile" src="../../../../assets/img/default.png" alt="" class="img">
                    <div class="overlay-text">
                        <div>
                            <p class="text text-small" style="display: flex; margin-bottom: 5px;" >{{carrera.modalidad}}
                                <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                                    <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad">
                                </ng-container>
                               
                                <ng-container *ngIf="carrera.modalidad == 'Virtual'">
                                    <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad">
                                </ng-container>
                                <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                                    <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad" >
                                    <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad" >
                                </ng-container>
                            </p>
                            <p class="text dorado" style="background-color: #C1B283; padding: 8px;  margin-bottom: 5px;     width: fit-content;">{{carrera.nivel}}</p>
                            <p class="text text-small facultad" style="" [style.border-left]="'18px solid ' + carrera.color" *ngIf="carrera.facultad && carrera.facultad.length >0">{{carrera.facultad[0]?.title}}</p>
                        </div>
                        <div>
                            <p class="text text-large">{{cortarTitulo(carrera.title) }}</p>
                            <p class="text text-small"><span class="text-small-b">INICIO:</span> {{  formatearFecha(carrera.inicio?.date) }}</p>
                            <p class="text text-small"><span class="text-small-b">SEDE:</span>  {{carrera.sede}}</p>
                            <a [href]="language +carrera.linkCareer">
                                <p  class="info text-small-b">MÁS INFO >></p>
                            </a>
                        </div>
                        
                    </div>
                    
                  </div>
            </div>
        </ng-container>
    </div>
    
  </div>