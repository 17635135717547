import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
  @Input() data: any[] = []
  @Input() dataImages: any[] = []
  @Input() dataImagesMobile: any[] = []
  @Input() ingreso: boolean = true

  isDesktop: boolean;

  constructor() {
    this.isDesktop = window.innerWidth > 768;

  }
  ngOnInit(): void {
    this.cambiarSliderMob(0)
    this.cambiarSliderDesk(0)
    setInterval(() => {
      if(this.isDesktop){
        this.positionDesk++;
        this.cambiarSliderDesk(this.positionDesk);
      }else{
        this.positionMob++;
        this.cambiarSliderMob(this.positionMob);
      }
     
    }, 10000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768;
  }
  positionDesk = 0;
  positionMob =  0;

  cambiarSliderDesk(i: number) {
    if (i >= this.dataImages.length) {
      i = 0;
    }
    if (i < 0) {
      i = this.dataImages.length - 1;
    }

    this.dataImages.forEach(f => {
      f.state = 'inactive';
    })
    this.dataImages[i].state = 'actived'
    this.positionDesk = i;
  }
  cambiarSliderMob(i: number) {
    if (i >= this.dataImagesMobile.length) {
      i = 0;
    }
    if (i < 0) {
      i = this.dataImagesMobile.length - 1;
    }

    this.dataImagesMobile.forEach(f => {
      f.state = 'inactive';
    })
    this.dataImagesMobile[i].state = 'actived'
    this.positionMob = i;
  }
 
}
