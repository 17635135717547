<section class="subheader">
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path">
  <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 mob" [src]="datos.pathMobile">
  <div class="texto-s container text-s-top">
    <h1 class="blanco">{{datos.title}}</h1>
  </div>
</section>

<section class="pagsec1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default" *ngFor='let item of items;index as i'>
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle " data-toggle="collapse" data-parent="#accordion" (click)='abrirPanel(i)'>
                  <h3 class="titulo sinbord ">{{item.title}}</h3>
                </div>
              </h4>
            </div>
            <div [id]="'collapse'+i" class="panel-collapse collapse in ">
              <div class="panel-body">
                <ul>
                  <li *ngFor='let subitem of item.linkItems'><a class=''
                      [href]="language+subitem.route">{{subitem.title}}</a></li>

                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>