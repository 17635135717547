export class RegistroCard{
    titulo:string;
    redSocialIcon:string;
    img:string;
    descripcion:string;
    link:string;
    numero:number;
    systemTitle:string;
    id:number;
    categoria:string;
}