import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { HeaderSectionComponent } from '../../components/header-section/header-section.component';
import { BuscadorCursosComponent } from '../../components/buscador-cursos/buscador-cursos.component';
import { PortadaComponent } from 'src/app/shared/components/portada/portada.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/components/formas-de-contacto/formas-de-contacto-dos.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit {
  datos: any;
  language: any;
  data: any;
  areas: Array<{ [key: string]: any }> = [];
  isMob: boolean
  busqueda: boolean
  path: any
  pathMob: any
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  @ViewChild('componenteDinamico13', { read: ViewContainerRef }) viewContainerRef13: ViewContainerRef;
  @ViewChild('componenteDinamico14', { read: ViewContainerRef }) viewContainerRef14: ViewContainerRef;
  @ViewChild('componenteDinamico15', { read: ViewContainerRef }) viewContainerRef15: ViewContainerRef;
  @ViewChild('componenteDinamico16', { read: ViewContainerRef }) viewContainerRef16: ViewContainerRef;
  @ViewChild('componenteDinamico17', { read: ViewContainerRef }) viewContainerRef17: ViewContainerRef;
  @ViewChild('componenteDinamico18', { read: ViewContainerRef }) viewContainerRef18: ViewContainerRef;
  @ViewChild('componenteDinamico19', { read: ViewContainerRef }) viewContainerRef19: ViewContainerRef;
  @ViewChild('componenteDinamico20', { read: ViewContainerRef }) viewContainerRef20: ViewContainerRef;
  titulo: any
  subitulo: any
  infoAreaRecomendada: any
  id:any;
  constructor(private services: GeneralService, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver, public router: ActivatedRoute, public venueService: GeneralService, public title: Title, public meta: Meta) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

    this.checkScreenWidth();
  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }

    });
  }
  convertirObjetoAArray(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !isNaN(Number(key))) {
        this.areas.push(data[key] as any);
      }
    }
  }
  onBuscadorCursosValueChange(newValue: boolean) {
    this.busqueda = newValue
  }
  ngOnInit(): void {
  }
  headerSection: any = {}
  ngAfterViewInit() {
    var url = decodeURIComponent(window.location.href);
    this.id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.definirComponentes(Number(key), arrayValue[key][0], 8, data1)
          });
        }
      });
    })
  }

  refBuscador;

  definirComponentes(index: number, element: any, width: number, data1: any) {
    let info = data1.data;
    switch (element.entityType) {
      case "HeaderSection":
        this.newComponent(index, 'HeaderSection', info, width)

        break;
      case "Portada":
        this.newComponent(index, 'Portada', info, width)
        break;
      case "Buscador":
        this.newComponent(index, 'Buscador', info, width)
        break

      case "Area":
        this.newComponent(index, "Area", info, width)
        break;
      case "Contacto":
        this.newComponent(index, "Contacto", info, width)
        break;
      case "AreaRecomendada":
        if (this.refBuscador) {
          this.refBuscador.instance.infoAreaRecomendada = info;
          this.refBuscador.changeDetectorRef.detectChanges();
        }

        break
      case "TextItem":
        this.newComponent(index, 'TextItem', info, width)
        break;

      case 'EntityList':
        info.entityList.forEach(element => {
          let type = element.typeDisplay;
          var background = element.background;
          this.newComponent(index, type, info, width, background)
        });

        break;
      case "Diary":

        this.newComponent(index, 'Diary', info, width)

        break;
    }

  }

  newComponent(index: number, type: string, info: any, width: number, background?: string) {

    switch (type) {
      case 'HeaderSection': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(HeaderSectionComponent);
        let ref2 = (this.viewContainerRefA(index)).createComponent(factory);
       // ref2.location.nativeElement.classList.add('col-lg-' + width);
        ref2.instance.pathMob = info.headerSection.pathMobile;
        ref2.instance.path = info.headerSection.path;
        ref2.instance.language = this.language
        /* ref2.instance.itemCarrouselHeader = info.headerSection.headerSectionItem.slice(0, 6); */

      }
        break;
      case 'Buscador': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorCursosComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        this.refBuscador.instance.data = info;
        if (this.infoAreaRecomendada) this.refBuscador.instance.infoAreaRecomendada = this.infoAreaRecomendada;
        this.refBuscador.instance.translateY = 110;
        this.refBuscador.instance.padding = "30px 38.6px 30px 38.6px";
        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        this.titulo = info[0].cover.title
        this.subitulo = info[0].cover.subtitle
        ref.instance.data = info[0];

      }
        break;
      case "Area": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
      //  ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info[0];
        ref.instance.fondo="Fondo-cursos.png";
        ref.instance.fondoMob="fondo-continua-mobile.jpg";

        ref.instance.cursos = true

      }
        break;
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
       // ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.data = info;
       ref.instance.fondo = "fondo-web.jpg";
       ref.instance.fondoMob = "fondo-mobile.jpg";
       ref.instance.esCurso = true
        //ref.instance.cursos = true

      }
        break;
        /*  case 'Primera foto destacada': {
           let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
           let ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.data = info['entityList'][0].entity;
           ref.instance.title = info['entityList'][0].title;
           ref.changeDetectorRef.detectChanges();
         } */


        break;
      /*  case 'Simple': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         if (width == 8) {
           ref.instance.numberCards = 3;
         }
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.instance.background = background;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'CustomPublication': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.publication;
         ref.instance.title = 'Publicaciones';
         ref.instance.background = background;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'Noticias con resumen': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'Carrousel':
         if (info.entityList[0].entity) {
           let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
           let ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.data = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'Lista desplegable':
         if (info.entityList[0].entity) {
           let factory = this.componentFactoryResolver.resolveComponentFactory(ListDesplegableComponent);
           let ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.items = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.changeDetectorRef.detectChanges();
         }
         break;
       case 'Cards':
         let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.socialNetwork.socialNetworkItem;
         ref.instance.title = info.socialNetwork.title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
 
         break;
       case 'Lista de links':
         if (info.entityList[0].entity) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.data = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.background = background;
           ref.changeDetectorRef.detectChanges();
         }
         break;
 
       case 'Video + Formulario (en ingles)':
         if (info.entityList[0].linkCode) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
           );
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.linkCode = info.entityList[0].linkCode;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.instance.idioma = 'en';
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'Video + Formulario (en español)':
         if (info.entityList[0].linkCode) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
           );
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.linkCode = info.entityList[0].linkCode;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.instance.idioma = 'es';
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'TextItem': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.i = index;
         ref.changeDetectorRef.detectChanges();
 
       }
         break; 
 
       case 'CustomForm': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
       case 'Diary': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         if (index == 2) {
           width = 4;
         }
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.data = info.diary[0].diaryItem;
         ref.instance.title = info.diary[0].title;
         ref.instance.width = width;
         ref.instance.botonTitle = "Eventos de la facultad";
         ref.changeDetectorRef.detectChanges();
 
 
       }
         break;
       case 'CustomStats': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.data = info.CustomStats;
         ref.instance.width = width;
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
       case 'Cabecera': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.title = info.entityList[0].title;
         ref.instance.slider = info.entityList[0].entity;
         ref.instance.background = info.entityList[0].background;
         ref.instance.path = info.entityList[0].path;
         ref.instance.width = width;
         ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
         ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
         ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
 
  */
    }






  }

  viewContainerRefA(index: number): any {
    switch (index) {
      case 1:
        return this.viewContainerRef1;
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;
      case 11:
        return this.viewContainerRef11;
      case 12:
        return this.viewContainerRef12;
      case 13:
        return this.viewContainerRef13;
      case 14:
        return this.viewContainerRef14;
      case 15:
        return this.viewContainerRef15;
      case 16:
        return this.viewContainerRef16;
      case 17:
        return this.viewContainerRef17;
      case 18:
        return this.viewContainerRef18;
      case 19:
        return this.viewContainerRef19;
      case 20:
        return this.viewContainerRef20;
      default:
        // 
        break;
    }
  }
  url(element: any) {
    let url = '';
    if (element.route) {
      if (element.externalLink) {
        url = element.route;

      } else {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if (element.externalLink) {
        url = element.systemTitle;

      } else {
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }
    }

    return url;
  }



}