<h2 >Información</h2>
<ng-container *ngIf="autoridades">
  <div class="grid-cont desk grid-3">
    <div class="grid-item" *ngFor='let item of Noticias' [routerLink]='item.systemTitle' >
      <a  class='pointer' [href]="item.systemTitle"  >
      <div *ngIf='item.categoria' class='categoria'>{{item.categoria}}</div>

      <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
      <p>{{item.title}}</p>
    </a>
    </div>
  </div>

  <div id="hsec1-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
      <ol class="carousel-indicators">
          <li  *ngFor='let item of Noticias;index as i '  data-target="Noticias-s" (click)="cambiarSlider(i)" [ngClass]='(item.id== noticiaActive)?"active" : ""' ></li>
        
        </ol>
    <div class="carousel-inner">
      <div class="carousel-item "  *ngFor='let item of Noticias' class="carousel-item " [ngClass]='(item.id== noticiaActive)?"active" : ""'>
        <a  class='pointer' [href]="item.systemTitle"  >

        <div class="grid-item">zz
          <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
          <p>{{item.title}}</p>
        </div>
        </a>
      </div>

    </div>
  </div>
</ng-container>           
<ng-container *ngIf="autoridades != 1">
  <div class="grid-cont desk grid-2">
    <div class="grid-item" *ngFor='let item of Noticias' [routerLink]='item.systemTitle' >
      <a  class='pointer' [href]="item.systemTitle"  >
      <div *ngIf='item.categoria' class='categoria'>{{item.categoria}}</div>

      <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
      <p>{{item.title}}</p>
    </a>
    </div>
  </div>

  <div id="hsec1-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
      <ol class="carousel-indicators">
          <li  *ngFor='let item of Noticias;index as i '  data-target="Noticias-s" (click)="cambiarSlider(i)" [ngClass]='(item.id== noticiaActive)?"active" : ""' ></li>
        
        </ol>
    <div class="carousel-inner">
      <div class="carousel-item "  *ngFor='let item of Noticias' class="carousel-item " [ngClass]='(item.id== noticiaActive)?"active" : ""'>
        <a  class='pointer' [href]="item.systemTitle"  >

        <div class="grid-item">zz
          <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">
          <p>{{item.title}}</p>
        </div>
        </a>
      </div>

    </div>
  </div>
</ng-container>  