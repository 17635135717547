import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-todas-las-carreras',
  templateUrl: './todas-las-carreras.component.html',
  styleUrls: ['./todas-las-carreras.component.scss']
})
export class TodasLasCarrerasComponent implements OnInit {

  datos: any;
  datosCarreras: any[] = [];
  universidades: any[] = [];
  items: any[] = [];
  language: any;
  constructor(private services: GeneralService, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.services.getCarreras(1, '', '').subscribe(data => {
      this.universidades = data.data[0].region;
      for (const key in data.data[0].region) {
        let sede=data.data[0].region[key];
        this.services.getCarreras(sede.id, '', '').subscribe(data => {
          this.datosCarreras[key] = [];
          data.data[0].career.forEach(d => {
            d.linkCareer = d.linkCareer + '?sede_de_interes='+sede.title;
            this.datosCarreras[key].push(d)
          })
          this.datosCarreras[key].sort(function (a, b) {
            if (a.title > b.title) {
              return 1;
            }
            if (a.title < b.title) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
        })
      }
    })

  }

  ngOnInit(): void {
  }
  abrirPanel(i) {
    if ($('#collapse' + i).hasClass('show')) {
      $('.show').addClass('show');
      $('#collapse' + i).removeClass('show');
    } else {
      $('.show').removeClass('show');
      $('#collapse' + i).addClass('show');
    }


  }
}
