import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalGeneralComponent } from 'src/app/shared/components/modal-general/modal-general.component';

@Component({
  selector: 'app-plan-de-estudio',
  templateUrl: './plan-de-estudio.component.html',
  styleUrls: ['./plan-de-estudio.component.scss']
})
export class PlanDeEstudioComponent implements OnInit {
  @Input() planData: any;
  @Input() buttonStudyPlan:any;
  @Input()  titlesPlanData:any;
  
  constructor(public dialog: MatDialog) { 

  }

  ngOnInit(): void {
  }

  restart(index) {
    var i = 0;
    this.planData.studyPlanItems.forEach(item => {
      if (i != index && document.getElementById('id-' + i)) {
        document.getElementById('id-' + i).innerHTML = "";
        document.getElementById('id-' + i).style.position = "relative"
        document.getElementById('arrowdown-' + i).style.display = "block";
        document.getElementById('arrowup-' + i).style.display = "none";
        document.getElementById('id-' + i).style.paddingTop = "0px";
        document.getElementById('id-' + i).style.paddingBottom = "0px";
        document.getElementById('id-' + i).style.marginBottom = "0px";
      }
      i++;
    })
  }

  open(button) {
    this.dialog.open(ModalGeneralComponent, { panelClass: 'custom-dialog-container', data: { html: button.body, title: button.title} });

  }


  openPlan(item, i) {
    this.restart(i);
    document.getElementById('id-' + i).innerHTML = item.body;
    document.getElementById('arrowdown-' + i).style.display = "none";
    document.getElementById('arrowup-' + i).style.display = "block";
    document.getElementById('id-' + i).style.paddingTop = "20px";
    document.getElementById('id-' + i).style.paddingBottom = "20px";
    document.getElementById('id-' + i).style.marginBottom = "2em";


  }

  closePlan(i) {
    this.restart(i);
    document.getElementById('id-' + i).innerHTML = "";
    document.getElementById('arrowdown-' + i).style.display = "block";
    document.getElementById('arrowup-' + i).style.display = "none";
    document.getElementById('id-' + i).style.paddingTop = "0px";
    document.getElementById('id-' + i).style.paddingBottom = "0px";
    document.getElementById('id-' + i).style.marginBottom = "0px";


  }
  openClosePanel(item, i) {
    if (document.getElementById('id-' + i).innerHTML == "") {
      this.openPlan(item, i);
    } else {
      this.closePlan(i)
    }
  }
  openDate() {
    this.restart(null);
    var innerHTML = this.buttonStudyPlan[0].body;
    this.dialog.open(ModalGeneralComponent, { panelClass: 'custom-dialog-container', data: { html: innerHTML, title: 'Días y horarios de cursada' } });

    document.getElementById('date').style.position = "absolute";
    document.getElementById('arrowdowndate').style.display = "none";
    document.getElementById('arrowupdate').style.display = "inline";
  }
  closeDate() {
    document.getElementById('date').innerHTML = "";
    document.getElementById('date').style.position = "relavite";
    document.getElementById('arrowdowndate').style.display = "inline";
    document.getElementById('arrowupdate').style.display = "none";
  }
}
