import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-cards-posgrados',
  templateUrl: './cards-posgrados.component.html',
  styleUrls: ['./cards-posgrados.component.scss']
})
export class CardsPosgradosComponent implements OnInit {
  @Input() array = [];
  language='es'
  constructor(private aRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }

  redirect(c){
    if(c.route){
      window.location.href = this.language +c.route
    } else if (c.linkCareer){
      window.location.href = this.language +c.linkCareer
    }
   
  }
  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'CONSULTAR'
    }

  }
  cortarTitulo(titulo){
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }
}
