import { Component, OnInit } from '@angular/core';
import { AgendaService } from 'src/app/core/services/agenda/agenda.service';
import { RegistroAgenda } from '../../models/registroAgenda';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  Agenda:RegistroAgenda[]=[];
  noticiaActive:string;
  constructor(private services:AgendaService) { 
     this.services.getAgenda().subscribe(data=>{
       this.noticiaActive=data.data[0]['CustomDiary'][0].id;
       data.data[0]['CustomDiary'].forEach(element => {
          let registro=new RegistroAgenda();
          registro.title=element.title;
          registro.date=element.startOn.date;
          registro.hours=element.startOn.date;
          registro.id=element.id;
          registro.link='/eventos/'+element.systemTitle
          this.Agenda.push(registro);

       });


     })

  }

  ngOnInit(): void {
  }

  cambiarSlider(i:number){
    this.noticiaActive=this.Agenda[i].id;

  }

}
