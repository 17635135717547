<div class="day-content">
    <ng-container *ngFor="let event of events">
      <ng-container *ngIf="event.startOnDate == check">
        <a href="{{language}}/eventos/{{event.systemTitle}}">
          <p class="content-title px-2">
            <span class="c-secundario">{{event.startOn.date | date:'HH:mm a'}}</span>
            {{event.title | slice:0:64}}...
          </p>
        </a>
      </ng-container>
    </ng-container>
  </div>