<div class="posgrados-container">
  <div class="w-100 position-relative">
    <div class="overlay"></div> <!-- Capa transparente -->
    <app-portada [data]="dataPortada"></app-portada>
  </div>

  <app-buscador-posgrados></app-buscador-posgrados>

  <section class="">
    <app-areas-de-conocimiento [data]="posgrados"></app-areas-de-conocimiento>
  </section>



  <section class="">

    <app-formas-de-contacto-dos [data]="dataContacto"></app-formas-de-contacto-dos>

  </section>
  <section id='nuestras-sedes'>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-nuestras-sedes [backgroundColor]="'#414141'" [btnColor]="'#414141'"></app-nuestras-sedes>
        </div>
      </div>
    </div>
</section>
<section class="">
  <app-icons [dataImages]="images" [dataImagesMobile]="imagesMobile" [ingreso]="false"></app-icons>
</section>


</div>

<!--<div class="posgrados-container">


  <ng-container #componenteDinamico1></ng-container>

  <ng-container #componenteDinamico2></ng-container>

  <ng-container #componenteDinamico3></ng-container>
  <ng-container #componenteDinamico4></ng-container>
  <ng-container #componenteDinamico5></ng-container>
  <ng-container #componenteDinamico6></ng-container>
  <ng-container #componenteDinamico7></ng-container>
  <ng-container #componenteDinamico8></ng-container>
  <ng-container #componenteDinamico9></ng-container>
  <ng-container #componenteDinamico10></ng-container>
  <ng-container #componenteDinamico11></ng-container>
  <ng-container #componenteDinamico12></ng-container>
  <ng-container #componenteDinamico13></ng-container>
  <ng-container #componenteDinamico14></ng-container>
  <ng-container #componenteDinamico15></ng-container>
  <ng-container #componenteDinamico16></ng-container>
  <ng-container #componenteDinamico17></ng-container>
  <ng-container #componenteDinamico18></ng-container>
  <ng-container #componenteDinamico19></ng-container>
  <ng-container #componenteDinamico20></ng-container>

 

</div>-->