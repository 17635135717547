<section class="agsec1">
    <div class="container">
        <div class="row">
            <div class='col-lg-12'>
                <carousel style='height: 20vw;display: flex;' cellsToShow='3' class='desk' counter='true' autoplay='true' lightDOM='true' loop='true'>
                    <div class="carousel-cell " *ngFor="let item of  gallery;">
                        <img onerror="this.src='./assets/img/default.png'" class='img' [src]="item.path" (click)='openModal(item.position)'>
                    </div>
                </carousel>

                <carousel  style='height: 60vw;display: flex;' class='mob' cellsToShow='1' counter='true' autoplay='true' lightDOM='true' loop='true'>
                    <div style='width:60vw !important' class="carousel-cell " *ngFor="let item of  gallery;">
                        <img onerror="this.src='./assets/img/default.png'" class='img' [src]="item.path" (click)='openModal(item.position)'>
                    </div>
                </carousel>
            </div>

        </div>
    </div>
</section>