<section class="possec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="posg-cont">
                    <div [ngClass]="(posgrados.length>1)?'posg-item' :'posg-item-2' " *ngFor='let item of posgrados;index as i' [ngStyle]="{'background-color':item.background}"><a
                            [href]="'/es/'+item.systemTitle">{{item.title}}</a></div>
                </div>
            </div>
        </div>
    </div>
</section>