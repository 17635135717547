import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-cursos-de-extension',
  templateUrl: './cursos-de-extension.component.html',
  styleUrls: ['./cursos-de-extension.component.scss']
})
export class CursosDeExtensionComponent implements OnInit {
  iconos:any[]=[];
  @Input() data:any[]=[]
  constructor() {
   }

  ngOnInit(): void {
    this.data.forEach(i=>{
      this.iconos.push(i);
    })
  
  }
}



