<section id="footer">
  <div class="f-item">
    <a href="/"><img class='logo-footer' nerror="this.src='./assets/img/default.png'"
        src="../../../../../assets/img/UCA-Logo-3.png" /></a>
    <div _ngcontent-c3="" class="mt-3">
      <span _ngcontent-c3="" class="info bold mb-4 w-100 float-left">Teléfono: 0810-2200-822 (UCA)</span><br
        _ngcontent-c3="">
      <span _ngcontent-c3="" class="info bold w-100 float-left">Buenos Aires</span><br _ngcontent-c3="">
      <span _ngcontent-c3="" class="info w-100 float-left">Av. Alicia Moreau de Justo 1300 (C1107AAZ).
        C.A.B.A.</span><br _ngcontent-c3="">
      <span _ngcontent-c3="" class="info bold w-100 float-left">Mendoza</span><br _ngcontent-c3="">
      <span _ngcontent-c3="" class="info w-100 float-left">Uruguay 750, (M550AYH). Godoy Cruz, Mendoza</span><br
        _ngcontent-c3="">
      <span _ngcontent-c3="" class="info bold w-100 float-left">Paraná</span><br _ngcontent-c3="">
      <span _ngcontent-c3="" class="info w-100 float-left">Buenos Aires 239 (E3100BQF). Paraná, Entre Ríos</span><br
        _ngcontent-c3="">
      <span _ngcontent-c3="" class="info bold w-100 float-left">Rosario</span><br _ngcontent-c3="">
      <span _ngcontent-c3="" class="info w-100 float-left">Av. Pellegrini 3314 (S2002QEO). Rosario,Santa Fe</span><br
        _ngcontent-c3="">
    </div>
    <div class="redes">
      <a href="https://es-la.facebook.com/universidad.catolica.argentina/" target="_blank"><img
          onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/fb.png"></a>
      <a href="https://www.youtube.com/user/UCAarg" target="_blank"><img onerror="this.src='./assets/img/default.png'"
          src="../../../../../assets/img/yt.png"></a>
      <a href="https://www.instagram.com/univcatolicaargentina/?hl=es" target="_blank"><img
          onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/ig.png"></a>
      <a href="https://www.linkedin.com/school/pontificia-universidad-cat%C3%B3lica-argentina/" target="_blank"><img
          onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/ln.png"></a>
          <a href="https://x.com/UCA_Oficial/" target="_blank"><img
            onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/X.png"></a>

 <a href="https://www.tiktok.com/@ucaarg/" target="_blank"><img
                onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/tik tok.png"></a>
    </div>
  </div>
  <div class="f-item" *ngFor='let menu of menuItem'>
    <div *ngFor='let item of menu; index as i '>
      <p [ngClass]="(i > 0)?'f-tit f-tit-sub' : 'f-tit'">{{item.title}}</p>
      <p class="links">
        <ng-container *ngFor='let children of item.children'>
          <ng-container *ngIf='!children.title'>
            <p></p>
          </ng-container>
          <ng-container *ngIf='children.title'>
            <a [href]="(children.externalLink)?children.link:'es'+children.linkSystem">{{children.title}}</a>
          </ng-container>
        </ng-container>
      </p>
    </div>
  </div>

</section>
<div class="copy">
  <p>Fundación Universidad Católica Argentina</p>
</div>