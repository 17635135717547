import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private itemNameSubject = new BehaviorSubject<string>(null);

  setItemName(name: string) {
    this.itemNameSubject.next(name);
  }

  getItemName() {
    return this.itemNameSubject.asObservable();
  }
}
