import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';
import { RegistroAgenda } from 'src/app/shared/models/registroAgenda';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {
  agenda:any;
  Agenda:RegistroAgenda[]=[];
  noticiaActive:string;
  language: any;
  month: number;
  year: number;
  constructor(private aRouter:ActivatedRoute,private services:GeneralService,private redirectService:RedirectService) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.month=new Date().getMonth()+1;
    this.year=new Date().getFullYear();
      this.services.getAgendaDetalle(this.aRouter.snapshot.params['id']).subscribe(data=>{
          if(data.data){
            this.agenda=data.data[0];

            document.getElementById('body').innerHTML=this.agenda.body;
            this.noticiaActive=this.agenda.otherEvent[0].id;
            this.agenda.otherEvent.forEach(element => {
               let registro=new RegistroAgenda();
               registro.title=element.title;
               registro.date=element.startOn.date;
               registro.hours=element.startOn.date;
               registro.id=element.id;
               registro.link=this.language+'/eventos/'+element.systemTitle;
               
               this.Agenda.push(registro);
     
            });
          }else{
            this.redirectService.toHome();
          }
     
      }) 
   } 

  ngOnInit(): void {
  }
  cambiarSlider(i:number){
    this.noticiaActive=this.Agenda[i].id;

  }
}
