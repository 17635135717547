<div class="fondo-contacto">
    <div class="container2">
        <div class="container-izq" #animatedSection
            [ngClass]="{'slideLeftReturn': isInView, ' hidden-animation': !isInView}">
            <img [src]="apiUrl+data?.file" alt="" class="img-izq">

        </div>
        <div class="container-der" #animatedSection
            [ngClass]="{'slideRightReturn': isInView, ' hidden-animation': !isInView}"
            [style.background-color]="data?.background_color">
            <div class="title-wpp">
                <h5 class="titulo quicksand-medium" [style.color]="data?.color_title">{{ data?.title }}</h5>
                <p style="color: white;margin-top:-10px;" class="text-small quicksand-medium"
                    [style.color]=" data?.color_subtitle">{{data?.subtitle}}</p>
                <div class="grey zoomIn" id="boton-wsp" [style.background]="data?.btn_color">
                    <a style="   margin-bottom: 0px; color: white; align-content: center;" class="text-m nunito-regular"
                        [href]="data?.btn_link" target="_blank">{{data?.btn_title}}</a>
                    <img *ngIf="!isMob" src="../../../../../assets/img/whsatsapp-blanco.png" alt="" class="wpp-icon">
                </div>
            </div>


            <div class="datos">
                <div>
                    <span [style.color]="data?.color_title_item" class="label nunito-bold">Horario de atención</span>
                    <span [style.color]="data?.color_description_item" class="value nunito-regular">{{data.horarioAtencion}}</span>
                </div>
                <div>
                    <span [style.color]="data?.color_title_item" class="label nunito-bold">Dirección</span>
                    <span [style.color]="data?.color_description_item" class="value nunito-regular">{{data.direccion}}</span>
                </div>

                <div>
                    <span [style.color]="data?.color_title_item" class="label nunito-bold">Teléfono</span>
                    <span [style.color]="data?.color_description_item" class="value nunito-regular">{{data.telefono}}</span>
                </div>

                <div>
                    <span [style.color]="data?.color_title_item" class="label nunito-bold">E-mail</span>
                    <span [style.color]="data?.color_description_item" class="value nunito-regular">{{data.email}}</span>
                </div>

            </div>
            <div class="redes2">
                <div style="display: flex; align-items: center;">
                    <p class="text-small nunito-medium text-redes"
                        style="margin-bottom: 0px; font-style: italicx !important;" [ngClass]="{ 'azul-curso': blue }">
                        Seguinos en redes</p>
                    <a *ngIf="data.link_face" id="redes" style='cursor:pointer;color: #007bff;'
                        (click)='windowOpen(data.link_face)'>
                        <img [src]="blue ? '../../../../../assets/img/fb2.png' : '../../../../../assets/img/fb-posgrado.png'"
                            alt="" class="img-icon">
                    </a>
                    <a *ngIf="data.link_inst" id="redes" href="" style='cursor:pointer;color: #007bff;'
                        (click)='windowOpen(data.link_inst )'>
                        <img [src]="blue ? '../../../../../assets/img/ig2.png' : '../../../../../assets/img/ig-posgrado.png'"
                            alt="" class="img-icon icon2">
                    </a>
                    <a *ngIf="data.link_twitter" id="redes" href="" style='cursor:pointer;color: #007bff;'
                        (click)='windowOpen(data.link_twitter)'>
                        <img [src]="blue ? '../../../../../assets/img/X2.png' : '../../../../../assets/img/ig-posgrado.png'"
                            alt="" class="img-icon icon2">
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>