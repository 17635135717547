import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-cursos-abiertos-jornadas-extension',
  templateUrl: './cursos-abiertos-jornadas-extension.component.html',
  styleUrls: ['./cursos-abiertos-jornadas-extension.component.scss']
})
export class CursosAbiertosJornadasExtensionComponent implements OnInit {
  id:string;
  idSeccion:String;
  title:string;
 constructor(private router:ActivatedRoute,private services:GeneralService) { 
   this.id=this.router.snapshot.params['id'];

    this.services.getSimplepage('es/posgrados/'+this.id).subscribe(data=>{
      this.title=data.data[0].title;
      this.idSeccion=data.data[0].pages[1][0].entityId;

    })
  }

  ngOnInit(): void {
  }

}
