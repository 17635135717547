<section class="subheader doble-m">
    <img onerror="this.src='./assets/img/default.png'" class=" desk img-title w-100 "
        src="../../../../../assets/img/img-interna.jpg" style='min-height: 20.5em;'>
    <img onerror="this.src='./assets/img/default.png'" class="mob img-title w-100 "
        src="../../../../../assets/img/img-interna.jpg">
    <div class="texto-s container">
        <div class='row'>
            <div class='col'>
                <h3 class="fs-21 c-principal bold">Año {{year}}</h3>
                <h1 class="azul">Todos los eventos</h1>
            </div>
        </div>
        <div class="carousel-container carousel-moving">
            <div class='years'>
                <a [href]="'/es/calendario/1/'+lastYear" style='margin-bottom: 10px;'> <span
                        style='margin-bottom: 3%;float: left;' class="fs-16 c-principal bold">
                        <i class="fas fa-chevron-left"></i>
                        {{lastYear}}
                    </span>
                </a>
                <a [href]="'/es/calendario/1/'+nextYear" style='margin-bottom: 10px;'><span
                        style='margin-bottom: 3%;float: right;' class="fs-16 c-principal bold">
                        {{nextYear}}
                        <i class="fas fa-chevron-right"></i>
                    </span>
                </a>
            </div>
            <carousel class='desk ' cellsToShow='5' style='display:flex;min-height: 100px;height: 100px;'>

                <div class="carousel-cell  " id="mes-d1">
                    <a href="{{language}}/calendario/1/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Enero</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d2">
                    <a href="{{language}}/calendario/2/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Febrero</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d3">
                    <a href="{{language}}/calendario/3/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Marzo</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d4">
                    <a href="{{language}}/calendario/4/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Abril</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d5">
                    <a href="{{language}}/calendario/5/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Mayo</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d6">
                    <a href="{{language}}/calendario/6/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Junio</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d7">
                    <a href="{{language}}/calendario/7/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Julio</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d8">
                    <a href="{{language}}/calendario/8/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Agosto</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d9">
                    <a href="{{language}}/calendario/9/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Septiembre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d10">
                    <a href="{{language}}/calendario/10/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Octubre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d11">
                    <a href="{{language}}/calendario/11/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Noviembre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-d12">
                    <a href="{{language}}/calendario/12/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Diciembre</h3>
                    </a>
                </div>
            </carousel>
            <carousel class='mob ' cellsToShow='1' style='display:flex;min-height: 100px;height: 100px;'>
                <div class="carousel-cell  " id="mes-m1">
                    <a href="{{language}}/calendario/01/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Enero</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m2">
                    <a href="{{language}}/calendario/02/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Febrero</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m3">
                    <a href="{{language}}/calendario/03/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Marzo</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m4">
                    <a href="{{language}}/calendario/04/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Abril</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m5">
                    <a href="{{language}}/calendario/05/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Mayo</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m6">
                    <a href="{{language}}/calendario/06/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Junio</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m7">
                    <a href="{{language}}/calendario/07/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Julio</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m8">
                    <a href="{{language}}/calendario/08/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Agosto</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m9">
                    <a href="{{language}}/calendario/09/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Septiembre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m10">
                    <a href="{{language}}/calendario/10/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Octubre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m11">
                    <a href="{{language}}/calendario/11/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Noviembre</h3>
                    </a>
                </div>
                <div class="carousel-cell " id="mes-m12">
                    <a href="{{language}}/calendario/12/{{year}}{{linkVenue}}"
                        class="item-slide-size pointer pb-5 swiper-slide">
                        <h3 class="fs-16 c-principal bold">Diciembre</h3>
                    </a>
                </div>
            </carousel>
        </div>
    </div>
</section>
<section>
    <div class="row calendar-container mx-3 mx-md-5">
        <div *ngIf="events" class="col-lg-8 calendar-size mt-5 pl-md-5">
            <app-calendar [month]=month [year]="year" [events]=events></app-calendar>
        </div>
        <div class="col-lg-4 " *ngIf='lastEvents'>
            <app-custom-diary-general title='Proximos eventos' [data]='lastEvents' boton='false'>
            </app-custom-diary-general>
        </div>
    </div>


</section>