import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  constructor(private http: HttpClient) {
  }

  getAgenda(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/page/custom-entity/?id=13`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
}

