<section class="facsec2">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-lg-8 mb-5 ">
                <h2>Conocenos</h2>
                <p style="display: flex;margin-top: 7%;">
                    <iframe width="700" height="400" [src]="video" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </p>
            </div>
            <div class="col-md-5 col-lg-4 mb-5 pr-3" *ngIf="linkForm">
                <h2>Dejanos tus datos para recibir más información de esta carrera</h2>
                <div id="externalLinkContainer">
                    <iframe _ngcontent-c5="" id="externalFormIframe" [src]="formulario"></iframe>
                </div>    
            </div>
            <div class="col-md-5 col-lg-4 " id='contacto' *ngIf="!linkForm">
                <h2>Dejanos tus datos para recibir más información</h2>

                <form [formGroup]="formDatos">
                    <div class="cont-3">
                        <input type="text" formControlName='name' placeholder="*Nombre">
                        <div *ngIf="formDatos.get('name').hasError('required') && formDatos.get('name').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>
                        <input type="text" formControlName='email' placeholder="*Email">
                        <div *ngIf="formDatos.get('email').hasError('email') && formDatos.get('email').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo debe ser un email valido </span>
                        </div>
                        <div *ngIf="formDatos.get('email').hasError('required') && formDatos.get('email').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>
                    </div>

                    <div class="cont-3">
                        <input formControlName='phone' placeholder="Telefono/Whatsapp" />
                        <div *ngIf="formDatos.get('phone').hasError('pattern') && formDatos.get('phone').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo debe ser un email valido </span>
                        </div>
                    </div>
                    <div class="cont-3">

                        <select formControlName='region'>
                            <option disabled="" hidden="" select="" value="">* Elige la sede</option>
                            <option value="Buenos Aires">Buenos Aires</option>
                            <option value="Mendoza">Mendoza</option>
                            <option value="Paraná">Paraná</option>
                            <option value="Rosario">Rosario</option>
                        </select>
                        <div *ngIf="formDatos.get('region').hasError('required') && formDatos.get('region').touched"
                            class="text-center">
                            <span class="mda-form-msg">Este campo es requerido </span>
                        </div>
                    </div>

                    <div class="cont-3">
                        <button (click)='consultar()' [disabled]='formDatos.invalid' type="submit"
                            class="btn btn-primary blanco ">CONSULTAR</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>