import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-general-simple',
  templateUrl: './general-simple.component.html',
  styleUrls: ['./general-simple.component.scss']
})
export class GeneralSimpleComponent implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  @Input() datos: any;
  itemsheaderSection: any[] = [];
  itemsChildren: any[] = [];
  links: any[] = [];
  titleList: string;
  archivo: any;
  language: any;
  titleLink: any;
  itemCarrouselHeader: any[] = [];
  itemsInfo: any[] = [];
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  parents: any;
  constructor(private router: ActivatedRoute, private services: GeneralService) {
    // console.log(document.referrer);
    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];
      if (params['nivel2']) {
        this.titleLink = this.titleLink + "/" + params['nivel2']
      }
      if (params['nivel3']) {
        this.titleLink = this.titleLink + "/" + params['nivel3']
      }
      if (params['nivel4']) {
        this.titleLink = this.titleLink + "/" + params['nivel4']
      }
      if (params['nivel5']) {
        this.titleLink = this.titleLink + "/" + params['nivel5']
      }
      if (params['nivel6']) {
        this.titleLink = this.titleLink + "/" + params['nivel6']
      }
      if (params['nivel7']) {
        this.titleLink = this.titleLink + "/" + params['nivel7']
      }

      this.services.getSimplepage(this.titleLink, this.language).subscribe(data => {
        if (this.datos.parent) {
          this.parents = this.datos.parent.reverse()
        }
        if (this.datos.type == 'simple') {

          if (this.datos.body) {
            if (this.datos.body_entity_inserted) {
              let body = document.createElement("div");
              body.innerHTML = this.datos.body;
              this.datos.body_entity_inserted.forEach(item => {
                let html_contentE = document.createElement("div");
                let authorityIdE = body.getElementsByClassName("authority-" + item.id)[0];
                item.authority.email = (item.authority.email) ? item.authority.email : " - ";

                let html_content = ' <ng-container >' +
                  '<div class="row " >' +
                  '<div class="mt-2 pt-3 pl-3 pr-3 ml-3">' +
                  '   <img onerror="this.src="./assets/img/default.png" src="' + item.authority.path + '">' +
                  '</div>' +
                  '<div class="col-md-8 pt-3 pl-3 pr-3" style="color: #1e3e6f">' +
                  '<p><b>' + item.authority.name + '</b></p>' +
                  '<p><span>' + item.authority.place + '</span></p>' +
                  '<p>' + item.authority.venue + '</p>' +
                  '<div>' +
                  '<p style="line-height: 16px;font-size:12px">Email:' +
                  item.authority.email + '</p>' +
                  '       </div>' +
                  '     </div>' +
                  '  </div>' +
                  '  </ng-container>';
                html_contentE.innerHTML = html_content;
                authorityIdE.parentNode.replaceChild(html_contentE, authorityIdE);


              })
              document.getElementById('body').append(body)

            } else {
              document.getElementById('body').innerHTML = this.datos.body;

            }

          } else
            if (this.datos['body_entity_inserted'] != null && this.datos) {
              let autorities = this.datos['body_entity_inserted'];
              this.datos.autorities = [];
              autorities.forEach(a => {
                this.datos.autorities.push(a['authority'])
              });

            } else if (this.datos['profile_title_entity_inserted'] != null && this.datos) {
              let autorities = this.datos['profile_title_entity_inserted'];
              this.datos.autorities = autorities;
            }




          if (this.datos.parent || this.datos.headerSection) {
            if (this.datos.parent) {
              this.itemsInfo = this.datos.parent[0].children;
              if (this.datos.children) {
                this.itemCarrouselHeader = this.datos.children.slice(0, 6);
              }
              if (!this.itemsInfo && this.datos.headerSection) {
                this.itemsInfo = this.datos.headerSection.headerSection.headerSectionItem.slice(0, 6);
              }
            } else
              if (this.datos.headerSection) {

                this.itemsInfo = this.datos.headerSection.headerSection.headerSectionItem;
                this.itemCarrouselHeader = this.datos.children;
                if (!this.itemsInfo && this.datos.parent) {
                  this.itemsInfo = this.datos.parent[0].children.slice(0, 6);

                }
              }
          } else {
            this.itemsInfo = this.datos.children;

          }

        }

        if (this.datos.type == "links") {
          this.links = this.datos.links;
          if (this.datos.parent) {
            this.itemsInfo = this.datos.parent[0].children;
            this.itemCarrouselHeader = this.datos.children;
            if (!this.itemsInfo && this.datos.headerSection) {
              this.itemsInfo = this.datos.headerSection.headerSection.headerSectionItem;
            }
          } else {
            this.itemsInfo = this.datos.children;

          }

        }
        if (this.datos.type == "news_list") {

          if (this.datos.headerSection) {
            this.datos.headerSection.headerSection.headerSectionItem.forEach(element => {
              element.route = '/es' + element.route;
              this.itemsInfo.push(element)
            });
          }

        }

        if (this.datos.attachItems) {
          this.archivo = this.datos.attachItems;
        }
      })
    });


  }

  ngOnInit(): void {
  console.log("SI")
  console.log("Parent", this.datos)
  }

  url(element: any) {
    let url = '';
    if (element.route) {
      if (element.externalLink) {
        url = element.route;

      } else {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        if (!url.startsWith('es/')) {
          url = '/' + this.language + '/' + url;
        }
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if (element.externalLink) {
        url = element.systemTitle;

      } else {
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        if (!url.startsWith('es/')) {
          url = '/' + this.language + '/' + url;
        }
      }
    }

    return url;
  }
  convertir(numero: string) {
    let mes = '';
    var numeroMes = parseInt(numero);
    if (!isNaN(numeroMes) && numeroMes >= 1 && numeroMes <= 12) {
      mes = this.meses[numeroMes - 1];
    }
    return mes;
  }
  backUrl() {
    if (this.datos.headerSection) {
      return this.language + this.datos.headerSection.venueLink;
    }
    /* if (document.referrer) {
      return document.referrer;
    } else {
     
    } */
  }
}
