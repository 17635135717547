<section class="pagsec1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="panel-group" id="accordion">
            <div class="panel panel-default card" >
              <div class="panel-heading" >
                  <h4 class="panel-title">
                      <div class="accordion-toggle " data-toggle="collapse" data-parent="#accordion" (click)='abrirPanel(1)'><h3 class="titulo sinbord ">{{title}}</h3></div>
                  </h4>
              </div> 
              <div  [id]="'collapse1'" class="panel-collapse collapse in show  ">
                <div class="panel-body">
                  <ul>
                    <li *ngFor='let item of items;index as i;'><a class='' [href]="item.systemTitle">{{item.title}}</a></li>
                   
                  </ul>
                </div>
              </div>
            </div>      
                 
          </div>
        </div>
      </div>
    </div>    
  </section> 