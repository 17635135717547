import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-items-ingreso',
  templateUrl: './items-ingreso.component.html',
  styleUrls: ['./items-ingreso.component.scss']
})
export class ItemsIngresoComponent implements OnInit {
  @Input() data: any[] = []
  @Input() ingreso: boolean = true
  dataTres: any[]=[]
  noDestacadas: any[]=[]
  destacadas: any[]=[]
  language: any
  constructor(private route:ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
   }

  ngOnInit(): void {

    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i].label
      this.dataTres.push(this.data[i])
      /* if(element ==  "UCA Internacional / EducationUSA" || element =='Escuela de Idiomas' || element == "Sistemas de Bibliotecas UCA" ){
        this.dataTres.push(this.data[i])
      } */
      
    }
    this.noDestacadas = this.dataTres.filter(d=>d.estado && !d.destacada);
    console.log("No destacadas", this.noDestacadas)
    this.destacadas = this.dataTres.filter(d=>d.estado && d.destacada);


  }
  redirect(link) {
    let linkCorto = link.replace("http://uca.edu.ar/es/", '');
    linkCorto = linkCorto.replace("https://uca.edu.ar/es/", '');
    //console.log("environment.frontUrl+'/'+this.language+'/' + link", environment.frontUrl+'/'+this.language+'/' + linkCorto)
     window.location.href = this.language+'/' + linkCorto
    //window.location.href = link
  }

}
