import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params, UrlSegment } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { GeneralGalleryComponent } from '../general-gallery/general-gallery.component';
import { GeneralLandingComponent } from '../general-landing/general-landing.component';
import { GeneralSimpleComponent } from '../general-simple/general-simple.component';

@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  styleUrls: ['./simple-page.component.scss']
})
export class SimplePageComponent implements OnInit {

  language: string;
  titleLink: string;
  breadcump: string;
  countFamily: number = 1;
  pathMob: any;
  singlepage: any;
  path: any;
  type = '';
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;

  constructor(public router: ActivatedRoute,
    public title: Title, public meta: Meta,
    public route: Router, private componentFactoryResolver: ComponentFactoryResolver,
    public services: GeneralService) {
    this.router.url.subscribe((segments: UrlSegment[]) => {
      segments.forEach((segment: UrlSegment) => {
        this.titleLink += "/" + segment.path;
        this.countFamily++;
      });
    });
    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];
      if (this.titleLink == "404") {
          route.navigate(['/404']);
      } else {
        if (params['nivel2']) {
          this.titleLink = this.titleLink + "/" + params['nivel2']
        }
        if (params['nivel3']) {
          this.titleLink = this.titleLink + "/" + params['nivel3']
        }
        if (params['nivel4']) {
          this.titleLink = this.titleLink + "/" + params['nivel4']
        }
        if (params['nivel5']) {
          this.titleLink = this.titleLink + "/" + params['nivel5']
        }
        if (params['nivel6']) {
          this.titleLink = this.titleLink + "/" + params['nivel6']
        }
        if (params['nivel7']) {
          this.titleLink = this.titleLink + "/" + params['nivel7']
        }
        this.services.getSinglePage(this.titleLink, this.language).subscribe(data => {
          if (data.data) {
            this.newComponent(data.data[0])
          } else {
            this.route.navigate([this.language])
          }
        });
      }


    });

  }

  ngOnInit() {
  }
  newComponent(datos) {

    switch (datos.type) {
      case 'links':
      case 'simple':
      case 'news_list':

        {
          let factory = this.componentFactoryResolver.resolveComponentFactory(GeneralSimpleComponent);
          let ref = this.viewContainerRef1.createComponent(factory);
          ref.instance.datos = datos;
          ref.changeDetectorRef.detectChanges();
          break;
        }

      case 'gallery':
        {
          let factory = this.componentFactoryResolver.resolveComponentFactory(GeneralGalleryComponent);
          let ref = this.viewContainerRef1.createComponent(factory);
          ref.instance.datos = datos;
          ref.changeDetectorRef.detectChanges();
          break;

        }

      case 'landing':
        {
          let factory = this.componentFactoryResolver.resolveComponentFactory(GeneralLandingComponent);
          let ref = this.viewContainerRef1.createComponent(factory);
          ref.instance.datos = datos;
          ref.changeDetectorRef.detectChanges();
          break;

        }

    }
  }

}
