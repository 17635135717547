import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { BuscadorCursosComponent } from '../../componentes/buscador-cursos/buscador-cursos.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/widgets/areas-de-conocimiento/areas-de-conocimiento.component';
import { PortadaComponent } from 'src/app/shared/widgets/portada/portada.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/widgets/formas-de-contacto/formas-de-contacto-dos.component';
@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit {
  language: any;
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  infoAreaRecomendada: any
  id: any;
  constructor(private services: GeneralService, private router: Router, private activatedRoute :ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver,  public venueService: GeneralService, public title: Title, public meta: Meta) {
  

  }


  ngOnInit(): void {
  }
  ngAfterViewInit(): void { 

    let relativeUrl = this.router.url.split('?')[0]; // Elimina los parámetros de la consulta
    this.language  = (this.activatedRoute.snapshot.params['language']) ? this.activatedRoute.snapshot.params['language'] : 'es';
    if (this.language) {
      relativeUrl = relativeUrl.replace(`/${this.language}/`, '/').replace(`/${this.language}`, '/');
    }
    this.id = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;

    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(info => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, info.data)
          });
        }
      });
    })
  }

  refBuscador;

  newComponent(index: number, type: string, info: any) {
    switch (type) {

      case 'Buscador': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorCursosComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        this.refBuscador.instance.data = info;
        this.refBuscador.instance.translateY = '52%';
        this.refBuscador.instance.padding = "30px 38.6px 30px 38.6px";
        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info[0];

      }
        break;
      case "Area": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //  ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info[0];
        console.log(info[0])
        ref.instance.fondo = "Fondo-cursos.png";
        ref.instance.fondoMob = "fondo-continua-mobile.jpg";

        ref.instance.cursos = true

      }
        break;
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        // ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info;
        ref.instance.fondo = "Fondo1.png";
        ref.instance.fondoMob = "fondo-mobile.jpg";
        ref.instance.esCurso = true
        //ref.instance.cursos = true

      }
        break;
      case "AreaRecomendada":
        if (this.refBuscador) {
          this.refBuscador.instance.infoAreaRecomendada = info;
          this.refBuscador.changeDetectorRef.detectChanges();
        }

        break


    }






  }




}