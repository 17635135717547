import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArancelesService {
  constructor(private http:HttpClient) {
  }

  getArancel(id : any) {
    return this.http.get(`${environment.apiUrl}/api/fare/search/${id}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getCarreras(region:string) {
    return this.http.get(`${environment.apiUrl}/api/fare/careers?region=${region}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  
}
