import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {
  isDesktop: boolean;
  @ViewChild('videoPlayer') videoPlayer: any;
  isPlaying: boolean = false;
  apiUrl:any;
  constructor() { 
    this.apiUrl=environment.apiUrl;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768; 
  }
  togglePlayPause() {
    if (this.videoPlayer.nativeElement.paused || this.videoPlayer.nativeElement.ended) {
      this.videoPlayer.nativeElement.play();
      this.isPlaying = true
    } else {
      this.videoPlayer.nativeElement.pause();
      this.isPlaying = false
    }
  }
  onVideoPlay() {
    this.isPlaying = true;
  }
  onVideoPause() {
    this.isPlaying = false; 
  }

  onVideoEnded() {
    this.isPlaying = false; 
  }
  redirecc(){

  }
  ngOnInit(): void {
    this.isDesktop = window.innerWidth > 768;
  }

}
