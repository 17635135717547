<app-header id='main-header'></app-header>

<mat-sidenav-container  class="sidenav-container">



    <div class="sidenav-content">
        <router-outlet></router-outlet>
    </div>


</mat-sidenav-container>


<app-footer></app-footer>