import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PosgradosModule } from "./posgrados.module";
import { Routes, RouterModule } from "@angular/router";
import { PosgradosComponent } from "./pages/posgrados/posgrados.component";
import { CursosAbiertosJornadasExtensionComponent } from "./pages/cursos-abiertos-jornadas-extension/cursos-abiertos-jornadas-extension.component";
import { GeneralDosComponent } from "src/app/shared/components/general-dos/general-dos.component";
import { GeneralUnoComponent } from "src/app/shared/components/general-uno/general-uno.component";
const routes: Routes = [
 /* {path: "es/posgrados/ciencias-economicas-y-negocios", component: GeneralDosComponent,},
  {path: "es/posgrados/ciencias-medicas", component: GeneralDosComponent,},
  {path: "es/posgrados/ciencias-sociales", component: GeneralDosComponent,},
  {path: "es/posgrados/derecho", component: GeneralDosComponent,},
  {path: "es/posgrados/filosofia-y-letras", component: GeneralDosComponent,},
  {path: "es/posgrados/ingenieria-y-ciencias-agrarias", component: GeneralDosComponent,},
  {path: "es/posgrados/musica", component: GeneralDosComponent,},
  {path: "es/posgrados/psicologia-y-psicopedagogia", component: GeneralDosComponent,},
  {path: "es/posgrados/teologia", component: GeneralDosComponent,},

  {path: "facultades/:seccion/posgrado-online/:id", component: PosgradosGeneralUnoComponent,},
  {path: "facultades/:seccion/posgrado-online/:subseccion/:id", component: PosgradosGeneralDosComponent,},

  {path: "cursos-cortos-virtuales---ccv", component: GeneralUnoComponent,},
  {path: "uca-global-1", component: GeneralUnoComponent,},
  {path: "escuela-de-idiomas", component: GeneralUnoComponent,},
  {path: "es/posgrados", component: PosgradosComponent,},
  {path: "es/posgrados/:id", component: CursosAbiertosJornadasExtensionComponent,},
  {path: "es/posgrados/:seccion/:id", component: GeneralUnoComponent,},
  {path: "es/posgrados/:seccion/:id", component: GeneralUnoComponent,},
  {path: "es/posgrados/elegi-tu-posgrado", component: PosgradosComponent,},
  */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})], // <-- debugging purposes only
  exports: [RouterModule]
})
export class PosgradosRoutingModule { }
