
<div class="posgrados-container" >
<app-portada *ngIf="dataPortada && area" [area]="area" [data]="dataPortada"  [marginTitle]="75"  [heightDesk]="'200px'"  [heightMob]="'250px'"  ></app-portada>
<app-buscador-posgrados-tematico *ngIf="area" [area]="area.name" ></app-buscador-posgrados-tematico>

<!--<section class="" id='elegi-tu-posgrado' style="padding-top: 0px !important;">
<div class="">
    <div class="row">
        <div class="col-lg-12">
          <div style="text-align: -webkit-center;">
            <div style='padding: 2%; max-width: 1518px; text-align: left; ' class="buscador">
                <form [formGroup]="buscador" class="form-group" id="busqueda"
                    style='margin-bottom:0px;justify-content: space-between;'>
            
                    <div style="display: flex;
                    flex-wrap: wrap;  width: 100%;">
            
                        <div class="input-container">
                            <input #filtroInput autofocus type="text" formControlName='palabras' 
                            (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar() : null"
                             class="form-control-c nunito-medium "  placeholder="Palabras clave"  style="border-radius: 0px !important;     padding-left: 10px;">
                            <img class="buscar" src="../../../../../assets/img/buscar-gris.png" alt="">
                        </div>
        
              
                        <div class="input-container">
                          <app-custom-select  [options]="niveles" 
                          placeholder="Nivel académico"
                          placeholderAll="Todos los niveles"
                          (selectionChange)="updateFilter('nivel', $event)"
                              [id]="'select-nivel'">
                          </app-custom-select>
                        </div>
                        
                   
                        <div class="input-container">
                            <app-custom-select [options]="regiones"
                              placeholder="Sede"
                              placeholderAll="Todas las sedes"
                              (selectionChange)="updateFilter('sede', $event)"
                              [id]="'select-sede'">
                          </app-custom-select>
                        </div>
                        
                        
                    
                        <div class="input-container">
                            <app-custom-select [options]="facultades"
                              placeholder="Facultad"
                              placeholderAll="Todas las facultades"
                              (selectionChange)="updateFilter('venueId', $event)"
                              [id]="'select-facultad'">
                          </app-custom-select>
                        </div>
                        
            
                        <div class="input-container">
                          <app-custom-select [options]="modalidades"
                            placeholder="Modalidad"
                            placeholderAll="Todas las modalidades"
                            (selectionChange)="updateFilter('modalidad', $event)"
                            [id]="'select-modalidad'">
                        </app-custom-select>
                      </div>
                      
            
                    </div>
   
            
            
                </form>
            </div>
            <div class="subtitle-p nunito-medium">
              <p  *ngIf="datos" [routerLink]="'/' + language +'/posgrados'" class="nunito-medium">Posgrado | <span class="nunito-bold">{{datos.title}}</span> </p>
            </div>
            <div class="resultados card p-2" id="resultados" >
                <ul *ngIf='!spinner' style="padding-left: 0px !important">
                  <div *ngFor="let nivel of carrerasArray">
                    <div style="display: flex;" class="title-container">
                      <div [style.text-align]="'center'" 
                      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
                      [style.width]="'100%'"
                     
                      [style.margin]="'5px'"
                      [style.margin-top]="'0px'"
                      ></div>
                      <h2 style="min-width: max-content;" class="quicksand-regular">
                        {{ nivel.nivel }}
                      </h2>
                      <div [style.text-align]="'center'" 
                      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
                      [style.width]="'100%'"
                     
                      [style.margin]="'5px'"
                      [style.margin-top]="'0px'"
                      ></div>
                    </div>
                  
                    <div class="sede-container">
                        <ng-container *ngFor="let carrera of nivel.carreras.carreras">
                          
                          <app-card-overlay [carrera]="carrera"></app-card-overlay>
                        </ng-container>
                    </div>
                    
                  </div>
                   
                </ul>
                <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
       
                <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
                    <div class="range">{{ rangoResultados }}</div>
                    <button (click)="cambiarPagina(1)" class="number arrow" [disabled]="!primerPaginaHabilitada()" style="border: none; background: none;">|<</button>
                    <button (click)="cambiarPagina(numberActive - 1)" class="number arrow" [disabled]="!primerPaginaHabilitada()" style="border: none; background: none;">&lt;</button>
                    
                    <button (click)="cambiarPagina(numberActive + 1)" class="number arrow" [disabled]="!ultimaPaginaHabilitada()" style="border: none; background: none;">&gt;</button>
                    <button (click)="cambiarPagina(pages.length)" class="number arrow" [disabled]="!ultimaPaginaHabilitada()" style="border: none; background: none;">>|</button>
                  </div>
                  
                  
            
                <div *ngIf='carrerasArray && carrerasArray.length == 0 && !spinner && filtro'  class='p-3 text-center'>
                    <div class="alert alert-primary text-center" role="alert">
                        No hay resultados
                    </div>
                </div>
            </div>
        </div>
        
        </div>
    </div>
</div>
</section>-->

<section class="">

  <app-banner></app-banner>

</section>
<section class="">
    
  <app-formas-de-contacto-dos [data]="dataContacto"></app-formas-de-contacto-dos>

</section>
