<div class="subheader  doble-m" *ngIf="data">
  <img onerror="this.src='./assets/img/default.png'" *ngIf="!isMob && data.cover.path" class=" w-100 img-desk"
    [style.height]="heightDesk" [src]="data.cover.path">
  <img [style.height]="heightMob" onerror="this.src='./assets/img/default.png'" *ngIf="isMob && data.cover.pathMobile"
    class=" w-100 img-mob" [src]="data.cover.pathMobile ">

  <img *ngIf='layerPath ' class="w-100 banda desk" [src]="layerPath" [ngClass]="{ 'mostrar': isContentLoaded }">
  <img *ngIf='layerPathMob' class="w-100 banda mob" [src]="layerPathMob">

  <img *ngIf='layerPathSrc' class="w-100 banda desk" [src]="layerPathSrc">
  <img *ngIf='layerPathMobSrc' class="w-100 banda mob" [src]="layerPathMobSrc">


  <img [style.height]="heightMob" *ngIf="isMob && !data.cover.pathMobile && !data.cover.video_path"
    class=" w-100 img-mob" src="./assets/img/default.png">
  <img [style.height]="heightDesk" *ngIf="!isMob && !data.cover.path && !data.cover.video_path" class=" w-100 img-desk"
    src="./assets/img/default.png">

  <video [style.height]="heightDesk" #videoPlayer autoplay muted playsinline loop
    *ngIf="!isMob && data.cover.video_path" class="w-100 video-desk">
    <source [src]="createLink(data.cover.video_path)" type="video/mp4">
  </video>
  <video #videoPlayerMob autoplay muted playsinline loop *ngIf="isMob && data.cover.video_pathMobile"
    class="w-100 video-mob">
    <source [src]="createLink(data.cover.video_pathMobile)" type="video/mp4">
  </video>
  <div *ngIf="data.cover.check_capa && !isMob " [style.height]="heightDesk" class="overlay"></div>
  <div *ngIf="data.cover.check_capa && isMob" class="overlay"></div>
  <div class="row">
    <div class="col-lg-12">
      <div class="container-carousel-header w-100 d-flex justify-content-center align-items-center"
        style="margin-bottom:140px ">
        <ng-container *ngFor="let boton of data.coverButton">
          <div id="boton-portada" *ngIf="boton.estado" class="carousel-cell-header  container-boton "
            style="    min-width: 15% !important">
            <a target="_blank" [href]="boton.link" [style.background]="boton.background" class="boton pointer zoomIn"
              style="right:auto !important" [style.width]="boton.width_button + 'px'">
              <span class="nunito-regular">
                {{boton.label}}
              </span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div style="position: relative; max-width: 1518px; margin: 0 auto;" class="contenedor">


    <div class="t-container-lg">
      <div class="t-container" [ngClass]="{'hayBotones': hayBotones}">
        <h1 class="titulo quicksand-bold" [style.color]="data.cover.color_title">{{data.cover.title}}</h1>
        <div class="d-flex items-center subheader">
          <!--id="magic" -->
          <img *ngIf="svgContent" [src]="svgContent" class="icon-link" />
          <img *ngIf="!svgContent && iconLink" [src]="iconLink" alt="" class="icon-link">
          <h1 *ngIf="data.cover" class="subtitulo quicksand-medium" [style.color]="data.cover.color_subtitle">
            {{data.cover?.subtitle}}</h1>
        </div>

      </div>
    </div>
  </div>
</div>