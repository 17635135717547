<section class="subheader doble-m">
    <img onerror="this.src='./assets/img/default.png'" class="img-desk img-title w-100 " src="../../../../../assets/img/img-interna.jpg">
    <img onerror="this.src='./assets/img/default.png'" class="img-mob img-title w-100 " src="../../../../../assets/img/img-interna.jpg">
    <div class="texto-s container" *ngIf='datos'>
        <h1 *ngIf='datos.display' class="azul strong">{{datos.display}}</h1>
        <h4 *ngIf='datos.title' class="azul">{{datos.title}}</h4>

    </div>
</section>
<section class="container">
    <p _ngcontent-c3="" class="fs-16 c-principal breadcump">
        <span _ngcontent-c3="" id="breadcump">
            <a [href]="venueFinal" class="c-principal">
               <strong> {{venue}} > </strong>
            </a> 
            <a [href]="carrerFinal" class="c-principal">
               <strong> {{carrer}} > </strong>
            </a> 
            {{pagina}}
        </span>
      </p>
</section>
<!-- <div id='body-p'> </div> -->
<div class="container" *ngIf='id=="plan-de-estudio"' >
    <div class="row">
        <div class='col-12 mt-1 mr-5 mf-5'>
            <div id='body-p'> </div>
        </div>
       

    </div>
    <div class="row" id="items" >
        <ng-container *ngIf="this.items && this.items.length > 0">
            <div class='col-12 mt-1 mr-5 mf-5'>
                <mat-tab-group animationDuration="20000ms">
                    <mat-tab [label]="item.title"   *ngFor='let item of items'>
                        <div  class="col-lg-12" [id]="item.title"   > 
                            <p [innerHTML]="item.body">

                            </p>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </ng-container>
    </div>
</div>
<div class="container" *ngIf='id=="perfil-del-titulo"'>
    <div class="row">
        <div class='col-12 mt-5 mb-5'>
            <div  id='body'> </div>
        </div>
       

    </div>
</div>
<!--<a [href]="link">link</a> -->